import { io } from 'socket.io-client';
import { baseUrlSocket, baseUrl } from './services/constantes';
import socketIO from 'socket.io-client';
export const socket = socketIO.connect(baseUrl, {
    cors: {
        origin: "*",
        methods: ["GET", "POST"]
    }
});
// export const socket = io(baseUrlSocket);
