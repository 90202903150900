import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Radio from "@mui/material/Radio/Radio";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/material/SvgIcon/SvgIcon";
import DataTable from "react-data-table-component";
import AjouterUsagers from "./AjouterUsagers";
import {helpers} from "../../services/api/helpers";
import LoadingOverlay from "react-loading-overlay";
import TextField from "@mui/material/TextField/TextField";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import Button from "@mui/material/Button";
import SaveIcon from '@mui/icons-material/Save';
import Snackbar from "@mui/material/Snackbar/Snackbar";
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from "@mui/material/Checkbox/Checkbox";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Dialog from "@mui/material/Dialog/Dialog";
import Delete from '@mui/icons-material/Delete';
import ls from "local-storage";
let isAdmin = ls.get('xxxx');

const columns = (( ShowConfirmDelete ) => [
    {
        name: 'Nom & Prenoms',
        selector: row => row?.nom + ' ' +row?.prenom,
        cell: d => <span>{(d.nom != null ? d?.nom : '') + ' ' + (d?.prenom != null ? d?.prenom : '') + ' ' +  (d?.societe != null ? ' (' + d?.societe + ')' : '')}</span>,
        wrap:true,
        sortable:true,
        style:{fontSize:14}
    },
    // {
    //     name: 'Site',
    //     selector: row => row?.vehicules[0]?.site?.nom || row?.vehicules[1]?.site?.nom,
    //     wrap:true,
    //     sortable:true,
    // },
    {
        name: 'Ville',
        selector: row => row?.ville,
        wrap:true,
        sortable:true,
    },
    {
        name: 'Email',
        selector: row => row?.email,
        wrap:true,
        sortable:true,
    },
    {
        name: 'Téléphone',
        selector: row => row?.telephone,
        wrap:true,
    },
    {
        name: 'nb Véhicules',
        selector: row => row?.vehicules.length,
        wrap:true,
    },
    {
        name: 'Immatriculation',
        selector: row => (row?.vehicules[0]?.immatriculation ? row?.vehicules[0]?.immatriculation : '') +  (row?.vehicules[1]?.immatriculation ? '/' + row?.vehicules[1]?.immatriculation: ''),
        wrap:true,
    },
    {
        name: 'Groupe',
        selector: row => row?.site?.groupe?.nom,
        wrap:true,
    },
    {
        name: 'Site',
        selector: row => row?.site?.nom,
        wrap:true,
    },
    {
        name: '',
        wrap:true,
        button: true,
        cell: (row) => <div style={{ }}><div  onClick={ShowConfirmDelete} id={row.id} style={{fontSize:11, color: 'red'}}>Supprimer<Delete /></div></div>,}

]);



const vehiculesColumn = [
    {
        name: 'Immatriculation',
        selector: row => row.immatriculation,
        sortable:true,
        wrap:true,
        style:{fontSize:16}
    },
    {
        name: 'Type',
        selector: row => row?.typevehicule?.type ,
        wrap:true,
        sortable:true,
    },
    {
        name: 'Marque',
        selector: row => row?.marque?.marque,
        wrap:true,
        sortable:true,
    },
    // {
    //     name: 'Groupe',
    //     selector: row => row?.site?.groupe?.nom,
    //     wrap:true,
    //     sortable:true,
    // },
    // {
    //     name: 'Site',
    //     selector: row => row?.site?.nom ,
    //     wrap:true,
    //     sortable:true,
    // },
    {
        name: 'Commentaire',
        selector: row => row.commentaire,
        wrap:true,
    }
];

export const Centre = () => {
    const divRef = React.useRef(null);

    const [switchUsagers, setSwitchUsagers] = React.useState('Afficher');
    const [searchFieldusager, setSearchFieldusager] = useState("");
    const [isLoading, setLoading] = React.useState(false);
    const [isLoadingModif, setLoadingmodif] = React.useState(false);
    const [tables, setTables] = React.useState([]);
    const [vehicules, setVehicules] = React.useState([]);
    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [societemodif, setSocietemodif] = useState("");
    const [nommodif, setNommodif] = useState("");
    const [prenommodif, setPrenommodif] = useState("");
    const [adressemodif, setAdressemodif] = useState("");
    const [villemodif, setVillemodif] = useState("");
    const [codepostalmodif, setCodepostalmodif] = useState("");
    const [telephonemodif, setTelephonemodif] = useState("");
    const [emailmodif, setEmailmodif] = useState("");
    const [isshowmodalmodif, setshowmodalmodif] = useState(false);
    const [vehicule1, setVehicule1] = React.useState(null);
    const [vehicule2, setVehicule2] = React.useState(null);
    const [idusager, setIdusager] = React.useState(null);
    const [searchFieldvehicule, setSearchFieldvehicule] = React.useState(null);
    const [isShowVehicules, setShowVehicules] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [idtosuppr, setIdToSuppr] = useState(null);
    const [groupe, setGroupe] = useState("");
    const [groupes, setGroupes] = useState([]);
    const [GroupeOnChange, setGroupeOnChange] = React.useState(null);
    const [site, setSite] = useState("");
    const [sites, setSites] = useState([]);
    const [SiteOnChange, setSiteOnChange] = React.useState(null);

    const ShowConfirmDelete = async (val) => {
        setIdToSuppr(val.target.id);
        setOpen(true);
    };

    const handleSwitchUsagers = (event) => {
        setSwitchUsagers(event.target.value);
    };
    const setByChildLoading = (loading) => {
        setLoading(loading);
    };
    const setByChildOpenToast = (toast) => {
        setOpenToast(toast);
    };

    useEffect(()=>{
        const filteredTablesusager = tables && tables.filter(
            item => {
                return (
                    // (item.societe !== null && item.societe !== undefined) && item.societe
                    //     .toLowerCase()
                    //     .includes(searchFieldusager.toLowerCase()) ||
                    (item.nom !== null && item.nom !== undefined)  && item.nom
                        .toLowerCase()
                        .includes(searchFieldusager.toLowerCase()) ||
                    (item.prenom !== null && item.prenom !== undefined) && item.prenom
                        .toLowerCase()
                        .includes(searchFieldusager.toLowerCase()) ||
                    // (item.adresse !== null && item.adresse !== undefined) && item.adresse
                    //     .toLowerCase()
                    //     .includes(searchFieldusager.toLowerCase()) ||
                    (item.ville !== null && item.ville !== undefined) && item.ville
                        .toLowerCase()
                        .includes(searchFieldusager.toLowerCase()) ||
                    // (item.codepostal !== null && item.codepostal !== undefined) && item.codepostal
                    //     .toLowerCase()
                    //     .includes(searchFieldusager.toLowerCase()) ||
                    (item.telephone !== null && item.telephone !== undefined) && item.telephone
                        .toLowerCase()
                        .includes(searchFieldusager.toLowerCase()) ||
                    (item.email !== null && item.email !== undefined) && item.email
                        .toLowerCase()
                        .includes(searchFieldusager.toLowerCase())
                    // (item.commentaire !== null && item.commentaire !== undefined) && item.commentaire
                    //     .toLowerCase()
                    //     .includes(searchFieldusager.toLowerCase())
                );
            }
        );
        if(searchFieldusager === '')
            getUsagers().catch(console.error);
        if(filteredTablesusager !== undefined)
            setTables(filteredTablesusager);
    },[searchFieldusager]);
    function rechercheindb(){
        if(searchFieldusager != ''){
            return;
        }
        return;
    }

    useEffect(()=>{
        const filteredTables = vehicules && vehicules.filter(
            item => {
                if(item !== undefined)
                    return (
                        (item.immatriculation !== null && item.immatriculation !== undefined ) && item.immatriculation
                            .toLowerCase()
                            .includes(searchFieldvehicule.toLowerCase()) ||
                        // (item?.usager?.nom !== null && item?.usager?.nom !== undefined ) && item?.usager?.nom
                        //     .toLowerCase()
                        //     .includes(searchFieldvehicule.toLowerCase()) ||
                        // (item?.usager?.prenom !== null && item?.usager?.prenom !== undefined )&& item?.usager?.prenom
                        //     .toLowerCase()
                        //     .includes(searchFieldvehicule.toLowerCase()) ||
                        // (item?.usager?.email !== null && item?.usager?.email !== undefined ) && item?.usager?.email
                        //     .toLowerCase()
                        //     .includes(searchFieldvehicule.toLowerCase()) ||
                        (item?.typevehicule?.type !== null && item?.typevehicule?.type !== undefined) && item?.typevehicule?.type
                            .toLowerCase()
                            .includes(searchFieldvehicule.toLowerCase()) ||
                        (item?.typevehicule?.marque !== null && item?.typevehicule?.marque !== undefined) && item?.typevehicule?.marque
                            .toLowerCase()
                            .includes(searchFieldvehicule.toLowerCase()) ||
                        // (item?.site?.groupe?.nom !== null && item?.site?.groupe?.nom !== undefined) && item?.site?.groupe?.nom
                        //     .toLowerCase()
                        //     .includes(searchFieldvehicule.toLowerCase()) ||
                        // (item.site?.nom !== null && item.site?.nom !== undefined ) && item.site?.nom
                        //     .toLowerCase()
                        //     .includes(searchFieldvehicule.toLowerCase()) ||
                        (item?.commentaire !== null && item?.commentaire !== undefined)&& item?.commentaire
                            .toLowerCase()
                            .includes(searchFieldvehicule.toLowerCase())
                    );
            }
        );
        if(searchFieldvehicule === '')
            getVehicules().catch(console.error);
        if(filteredTables !== undefined)
            setVehicules(filteredTables);
    },[searchFieldvehicule]);

    function recherchevehiculeindb(){
        if(searchFieldvehicule != ''){
            return;
        }
        return;
    }


    const [selectedRowss, setSelectedRows] = React.useState(false);
    const handleChange = ({ selectedRows }) => {
        if(vehicule1 != null && vehicule2 != null && vehicule1?.immatriculation == vehicule2?.immatriculation){
            setVehicule2(null);
            return;
        }
        else if(selectedRows.length===0 && vehicule1 && vehicule2)
        {
            setVehicule1(null);
            setVehicule2(null);

        }else if(selectedRows.length===0 && vehicule1 && !vehicule2)
        {
            setVehicule1(null);
            setVehicule2(null);

        }else if(selectedRows.length===1 && (vehicule2 !== null || (vehicule2 && vehicule2?.immatriculation.trim() !== "" && vehicule2?.immatriculation.trim() !== "----")) && vehicule1 )
        {
            setVehicule2(null);
        }else if((selectedRows.length===1  && (vehicule1 === null || (vehicule1 && vehicule1?.immatriculation.trim() === "" && vehicule1?.immatriculation.trim() === "----"))) || vehicule2 )
        {
            setVehicule1(selectedRows[0]);
        }else if(selectedRows.length===1 && (vehicule2 == null || (vehicule2 && vehicule2?.immatriculation.trim() === "" && vehicule2?.immatriculation.trim() === "----")) && vehicule1 )
        {
            setVehicule2(selectedRows[0]);
        }else if(selectedRows.length>1 && (vehicule2 == null || (vehicule2 && vehicule2?.immatriculation.trim() !== "" && vehicule2?.immatriculation.trim() !== "----")) && vehicule1 )
        {
            if(vehicule2 === null){
                setVehicule2(selectedRows[0]);
            }else
            setVehicule2(selectedRows[1]);
        }else  {
            setVehicule2(null);
            setVehicule1(null);
        }
        setSelectedRows(selectedRows);
    };
    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };
    const getUsagers = async () => {
        helpers
            .getAllUsagers()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setTables( r.data);
                }
            })
    };

    const getVehicules = async () => {
        helpers
            .getAllVehicules()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setVehicules( r.data);
                }
            })
    };
    const getGroupeToDropdown = async () => {
        helpers
            .getAllGroupesToDropdown()
            .then(async r => {
                if (r.status === 200) {
                    setGroupes( r.data);
                }
            })
    };

    const getSitesToDropdown = async () => {
        helpers
            .getAllSitesToDropdown()
            .then(async r => {
                if (r.status === 200) {
                    setSites( r.data);
                }
            })
    };

    useEffect(()=>{
        setLoading(true);
        getUsagers().catch(console.error);
        getVehicules().catch(console.error);
        getSitesToDropdown().catch(console.error);
        getGroupeToDropdown().catch(console.error);
    },[]);

    const handleShowVehicules = (value) => {
        divRef.current.scrollIntoView({ behavior: 'smooth' });
        setShowVehicules(value.target.checked)
    };

    const modificationusager =(e)=> {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const commentaire = data.get('commentaire');

        if(societemodif.trim() == ''){
            setOpenToast({ isOpenToast:true, txt:'Veuillez insérer la société.' });
            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
            return;
        }
        if(nommodif.trim() == ''){
            setOpenToast({ isOpenToast:true, txt:'Veuillez insérer le nom de l\'usager.' });
            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
            return;
        }
        if(prenommodif.trim() == ''){
            setOpenToast({ isOpenToast:true, txt:'Veuillez insérer le prénom de l\'usager.' });
            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
            return;
        }
        if(adressemodif.trim() == ''){
            setOpenToast({ isOpenToast:true, txt:'Veuillez insérer l\'adresse de l\'usager.' });
            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
            return;
        }
        if(villemodif.trim() == ''){
            setOpenToast({ isOpenToast:true, txt:'Veuillez insérer la ville de l\'usager.' });
            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
            return;
        }
        if(codepostalmodif.trim() == ''){
            setOpenToast({ isOpenToast:true, txt:'Veuillez insérer le code postal de l\'usager.' });
            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
            return;
        }
        if(telephonemodif.trim() == ''){
            setOpenToast({ isOpenToast:true, txt:'Veuillez insérer le téléphone de l\'usager.' });
            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
            return;
        }
        if(emailmodif.trim() == ''){
            setOpenToast({ isOpenToast:true, txt:'Veuillez insérer le téléphone de l\'usager.' });
            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
            return;
        }

        // if(groupe.trim() == ''){
        //     setOpenToast({
        //         isOpenToast: true,
        //         txt: 'Veuillez choisir ou ajouter le groupe.'
        //     });
        //     return;
        // }
        // if(site.trim() == ''){
        //     setOpenToast({
        //         isOpenToast: true,
        //         txt: 'Veuillez choisir ou ajouter le site.'
        //     });
        //     return;
        // }

        if(isShowVehicules &&( vehicule1 === null || (vehicule1?.immatriculation && ( vehicule1?.immatriculation.trim() === "" || vehicule1?.immatriculation === '----'))) ){
            setOpenToast({
                isOpenToast: true,
                txt: 'Veuillez choisir au moins une véhicule.'
            });
            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
            return;
        }
        setOpenToast({ isOpenToast: false, txt: '' });
        setLoadingmodif(true);

        let body = {
            societe: societemodif,
            nom: nommodif,
            prenom: prenommodif,
            adresse: adressemodif,
            ville: villemodif,
            codepostal: codepostalmodif,
            email: emailmodif,
            telephone: telephonemodif,
            groupe:  groupe.trim(),
            site:  site.trim(),
            id:idusager,
            commentaire: commentaire,
            vehicule1: vehicule1,
            vehicule2: vehicule2
        };

        if(GroupeOnChange != null && GroupeOnChange.label === groupe)
            body.groupeid = GroupeOnChange.id;
        if(SiteOnChange != null && SiteOnChange.label === site)
            body.siteid = SiteOnChange.id;

        if(idusager != null)
            helpers
                .usagermodification(body)
                .then(async r => {
                    setLoadingmodif(false);
                    if (r.status === 200) {
                        await getUsagers().catch(console.error);
                        await getVehicules().catch(console.error);
                        setOpenToast({
                            isOpenToast: true,
                            txt: r.message
                        });
                        setshowmodalmodif(false);
                    } else {
                        setOpenToast({
                            isOpenToast: true,
                            txt: r.message,
                        });
                    }
                })
                .catch(e => {
                    setLoadingmodif(false);
                });
    };



    const handleClose = () => {
        setOpen(false);
    };
    const suppression = async (e)=> {
        helpers
            .supprUsager({id: idtosuppr})
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    getUsagers().catch(console.error);
                    setOpen(false);
                }
            })
    };


    return (
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Chargement...'
        >
      <div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0 text-dark">Usagers</h1>
                    </div>{/* /.col */}
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item">
                                Usagers
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/dashboard"  className="link">
                                Parc access
                                </Link>
                            </li>
                        </ol>
                    </div>{/* /.col */}
                </div>{/* /.row */}
            </div>{/* /.container-fluid */}
        </div>


          <div className="col" style={{ marginBottom:20}}>

              <div style={{ justifyContent:'flex-start', marginLeft:50 }} className=" d-flex flex-row ">
                  <div className="row d-flex flex-row align-items-center justify-content-between">
                      <FormControl>
                          <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={switchUsagers}
                              onChange={handleSwitchUsagers}
                          >
                              <FormControlLabel value="Ajouter" control={<Radio />} label="Ajouter" />
                              <FormControlLabel value="Afficher" control={<Radio />} label="Afficher" />
                          </RadioGroup>
                      </FormControl>
                      {switchUsagers === 'Afficher' && <Paper
                          component="form"
                          sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}
                      >
                          <InputBase
                              sx={{ ml: 1, flex: 1 }}
                              placeholder="Rechercher"
                              inputProps={{ 'aria-label': 'Rechercher' }}
                              onChange={(e)=>{
                                  e.preventDefault();
                                  setSearchFieldusager(e.target.value);
                              }}
                              value={searchFieldusager}
                          />
                          <IconButton  onClick={() => {
                              rechercheindb()
                          }} type="button" sx={{ p: '5px' }} aria-label="search">
                              <SearchIcon />
                          </IconButton>
                      </Paper>}
                  </div>
              </div>
              {switchUsagers === 'Afficher' && <DataTable
                  pagination
                  // selectableRowsComponent={Checkbox}
                  ////////////////////////////////////selectableRowsComponentProps={selectProps}
                  // selectableRowSelected={(row) => {
                  //     console.log('row',row)
                  //      return row.selected;
                  // }}

                  // sortIcon={sortIcon}
                  dense
                  //{...props}
                  //title="Administration"
                  // theme={'dark'}
                  columns={columns( ShowConfirmDelete)}
                  data={tables}
                  //////////////////////////////////////////selectableRows
                  striped
                  highlightOnHover
                  persistTableHead
                  fixedHeader={false}
                  progressPending={false}
                  noDataComponent={"Aucune données à afficher"}
                  onSelectedRowsChange={handleChange}
                  onRowClicked={(row, event) => {console.log('row clicked', row);
                      // value={commentairemodif}
                      // onChange={(e)=>setcommentairemodif(e.target.value)}
                      setGroupe(row?.site?.groupe?.nom || ""); // misy site koa am vehicule fa jereo n ambivalenceny
                      setSite(row?.site?.nom || "");
                      setSocietemodif(row?.societe || "");
                      setNommodif(row?.nom || "");
                      setPrenommodif(row?.prenom || "");
                      setAdressemodif(row?.adresse || "");
                      setVillemodif(row?.ville || "");
                      setCodepostalmodif(row?.codepostal || "");
                      setTelephonemodif(row?.telephone || "");
                      setEmailmodif(row?.email || "");
                      setIdusager(row?.id || null);
                      if(row?.vehicules.length==1){
                          setVehicule1(row?.vehicules[0]);
                      }else if(row?.vehicules.length>1){
                          setVehicule1(row?.vehicules[0]);
                          setVehicule2(row?.vehicules[1]);
                      }
                      setshowmodalmodif(true);
                      setShowVehicules(false)
                  }}
                  // onSelectedRowsChange={handleSelectChange}
                  // clearSelectedRows={toggledClearSelectedRows}
                  // selectableRowSelected={(rowSelectCritera)=>{
                  //     console.log('rowSelectCritera',rowSelectCritera);
                  //     return rowSelectCritera.selected === true;
                  // }}

                  // onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {console.log(' onChangePage')}}
                  // onChangePage={(page, totalRows)  => {console.log(' onChangePage')}}
                  // onRowMouseEnter={(row, event) => {console.log('row clicked')}}
                  // expandableRows={false}
                  // expandableRowsComponent={() => {console.log('row clicked')}}
                  paginationComponentOptions={{ rowsPerPageText: 'Lignes par page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
              />}
              {switchUsagers === 'Ajouter' && <div className={"w-100 bg-light"} ><div className={"w-50"}><AjouterUsagers setSwitchUsagers={handleSwitchUsagers} getUsagers={getUsagers} setOpenToast={setByChildOpenToast} setParentLoading={setByChildLoading}/></div></div>}
          </div>

      </div>
            <Modal
                open={isshowmodalmodif}
                onClose={()=>setshowmodalmodif(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="modal-dialog ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Modification usagers</h4>
                            <button onClick={()=>setshowmodalmodif(false)} type="button" className="close" data-dismiss="modal" aria-label="Fermer">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <Box component="form" onSubmit={modificationusager} sx={{ mt: 1 }}
                             Validate
                             autoComplete="on"   >

                            <div ref={divRef}  className="modal-body">
                                {isAdmin === "AAAA" && <div style={{ justifyContent:'flex-end', marginTop:10}} className="row">
                                    <Autocomplete
                                        margin="normal"
                                        disablePortal
                                        id="combo-box-demo1"
                                        value={{label:groupe}}
                                        options={
                                            groupes.length > 0 ?
                                                groupes
                                                :
                                                []
                                        }
                                        onChange={(value0, value1)=>{setGroupe(value1.label); setGroupeOnChange(value1)}}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} value={groupe} onChange={(value)=>{setGroupe(value.target.value); }} label="Groupe" />}
                                    />
                                </div>}
                                {isAdmin === "AAAA" && <div style={{ justifyContent:'flex-end', marginTop:10}} className="row">
                                    <Autocomplete
                                        margin="normal"
                                        disablePortal
                                        id="combo-box-demo1"
                                        value={{label:site}}
                                        options={
                                            sites.length > 0 ?
                                                sites
                                                :
                                                []
                                        }
                                        onChange={(value0, value1)=>{setSite(value1.label); setSiteOnChange(value1)}}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} value={site} onChange={(value)=>{setSite(value.target.value); }} label="Site" />}
                                    />
                                </div>}

                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="societe"
                                    label="Société"
                                    name="societe"
                                    autoFocus
                                    //  autoComplete="nom"
                                    value={societemodif}
                                    onChange={(value)=>{setSocietemodif(value.target.value); }}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="nom"
                                    label="Nom"
                                    name="nom"
                                    autoFocus
                                    value={nommodif}
                                    onChange={(value)=>{setNommodif(value.target.value); }}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="prenom"
                                    label="Prénom"
                                    name="prenom"
                                    autoFocus
                                    value={prenommodif}
                                    onChange={(value)=>{setPrenommodif(value.target.value); }}
                                />

                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="adresse"
                                    label="Adresse"
                                    name="adresse"
                                    autoFocus
                                    value={adressemodif}
                                    onChange={(value)=>{setAdressemodif(value.target.value); }}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="ville"
                                    label="Ville"
                                    name="ville"
                                    autoFocus
                                    value={villemodif}
                                    onChange={(value)=>{setVillemodif(value.target.value); }}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="codepostal"
                                    label="Code Postal"
                                    name="codepostal"
                                    autoFocus
                                    value={codepostalmodif}
                                    onChange={(value)=>{setCodepostalmodif(value.target.value); }}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="telephone"
                                    label="Téléphone"
                                    name="telephone"
                                    autoFocus
                                    value={telephonemodif}
                                    onChange={(value)=>{setTelephonemodif(value.target.value); }}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="E-mail"
                                    name="email"
                                    autoFocus
                                    value={emailmodif}
                                    onChange={(value)=>{setEmailmodif(value.target.value); }}
                                />
                                <FormControlLabel
                                    control={<Checkbox onChange={handleShowVehicules} value="remember" color="primary" />}
                                    label="Modifier ou ajouter des véhicules."
                                />
                                {isShowVehicules && <div style={{  }} >
                                <div style={{marginTop:10,justifyContent:'flex-start' }} className="row">
                                    <div style={{marginLeft:30, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">
                                        <span style={{color:'#3bc145'}}>Véhicule 1:&nbsp;</span>{' '}{vehicule1 != null ? vehicule1?.immatriculation : '----'},
                                    </div>
                                    <div style={{marginLeft:30, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">
                                        marque: {vehicule1 != null ? vehicule1?.marque?.marque : '----'},
                                    </div>
                                    <div style={{marginLeft:30, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">
                                        type: {vehicule1 != null ? vehicule1?.typevehicule?.type : '----'},
                                    </div>
                                    <div style={{marginLeft:30, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">
                                        Usager actuel: {vehicule1 != null ? (vehicule1?.usager?.nom != undefined ? vehicule1?.usager?.nom : '' + ' ' + vehicule1?.usager?.prenom!= undefined ? vehicule1?.usager?.prenom : '' ) : '----'}
                                    </div>
                                </div>
                                <div style={{marginTop:10,justifyContent:'flex-start' }} className="row">
                                    <div style={{marginLeft:30, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">
                                        <span style={{color:'#3bc145'}}>Véhicule 2:&nbsp;</span>{' '}{vehicule2 != null ? vehicule2?.immatriculation : '----'},
                                    </div>
                                    <div style={{marginLeft:30, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">
                                        marque: {vehicule2 != null ? vehicule2?.marque?.marque : '----'},
                                    </div>
                                    <div style={{marginLeft:30, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">
                                        type: {vehicule2 != null ? vehicule2?.typevehicule?.type : '----'},
                                    </div>
                                    <div style={{marginLeft:30, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">
                                        Usager actuel: {vehicule2 != null ? (vehicule2?.usager?.nom != undefined ? vehicule2?.usager?.nom : '' + ' ' + vehicule2?.usager?.prenom!= undefined ? vehicule2?.usager?.prenom : '' ) : '----'}
                                    </div>
                                </div>
                                <div style={{ justifyContent:'flex-end', marginTop:10}} className="row">
                                    <Paper
                                        component="form"
                                        sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}
                                    >
                                        <InputBase
                                            sx={{ ml: 1, flex: 1 }}
                                            placeholder="Rechercher"
                                            inputProps={{ 'aria-label': 'Rechercher' }}
                                            onChange={(e)=>{
                                                e.preventDefault();
                                                setSearchFieldvehicule(e.target.value);
                                            }}
                                            value={searchFieldvehicule}
                                        />
                                        <IconButton  onClick={() => {
                                            recherchevehiculeindb()
                                        }} type="button" sx={{ p: '5px' }} aria-label="search">
                                            <SearchIcon />
                                        </IconButton>
                                    </Paper>
                                    <DataTable
                                        pagination
                                        selectableRowsComponentProps={selectProps}
                                        dense
                                        columns={vehiculesColumn}
                                        data={vehicules}
                                        selectableRows
                                        striped
                                        highlightOnHover
                                        persistTableHead
                                        fixedHeader={false}
                                        progressPending={false}
                                        noDataComponent={"Aucune données à afficher"}
                                        onRowClicked={(row, event) => {console.log('row clicked', row)}}
                                        onSelectedRowsChange={handleChange}
                                        paginationComponentOptions={{ rowsPerPageText: 'Lignes par page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                                    />
                                </div>
                                </div>}
                            </div>
                            <div className="modal-footer d-flex flex-row justify-content-between">
                                <button onClick={()=>setshowmodalmodif(false)} type="button" className="btn btn-default" data-dismiss="modal">Fermer</button>
                                <Button
                                    onPress = {modificationusager}
                                    type="submit"
                                    // fullWidth
                                    variant="contained"
                                    // sx={{ mt: 3, mb: 2 }}
                                    startIcon={
                                        isLoadingModif ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                            : <SaveIcon fontSize="inherit" />
                                    }
                                >
                                    Enregistrer
                                </Button>
                            </div>
                        </Box>

                    </div>
                </div>


            </Modal>
            <Snackbar
                open={openToast.isOpenToast}
                autoHideDuration={6000}
                //onClose={()=>setOpenToast({...openToast, isOpenToast:false})}
                message={openToast.txt}
                action={<React.Fragment>
                    <IconButton
                        size="small"
                        aria-label="fermer"
                        color="inherit"
                        onClick={(event, reason) => {
                            if (reason === 'clickaway') {
                                return;
                            }
                            setOpenToast(false);
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>}
            />
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Suppression usager
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Voulez-vous vraiment supprimer?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button  onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button onClick={suppression}>Ok</Button>
                </DialogActions>
            </Dialog>
        </LoadingOverlay>
  )
};
