import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Radio from "@mui/material/Radio/Radio";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/material/SvgIcon/SvgIcon";
import DataTable from "react-data-table-component";
import AjouterModifPlagehoraire from "./AjouterModifPlagehoraire";
import {helpers} from "../../services/api/helpers";
import LoadingOverlay from "react-loading-overlay";
import TextField from "@mui/material/TextField/TextField";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import Button from "@mui/material/Button";
import SaveIcon from '@mui/icons-material/Save';
import Snackbar from "@mui/material/Snackbar/Snackbar";
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from "@mui/material/Checkbox/Checkbox";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Dialog from "@mui/material/Dialog/Dialog";
import Delete from '@mui/icons-material/Delete';


const columns = (( ShowConfirmDelete) => [

   {
        name: 'Identification',
        selector: row => row?.nom,
        wrap:true,
        sortable:true,
    },
    {
        name: 'Ouverture',
        selector: row => row?.debutformated,
        wrap:true,
        sortable:true,
    },
    {
        name: 'Ferméture',
        selector: row => row?.finformated,
        wrap:true,
    },
    {
        name: 'Disponibilité',
       selector: row => row?.lundi,
        cell: d =>{return <span>{(d.lundi ? <span style={{color: '#00ff28', fontSize:12}}>{'Lundi(O) '}</span> : <span style={{color: '#ff2940', fontSize:12}}>{'Lundi(F) '}</span>)}
            {(d.mardi ? <span style={{color: '#00ff28', fontSize:12}}>{'Mardi(O) '}</span> : <span style={{color: '#ff2940', fontSize:12}}>{'Mardi(F) '}</span>)}
            {(d.mercredi ? <span style={{color: '#00ff28', fontSize:12}}>{'Mercredi(O) '}</span> : <span style={{color: '#ff2940', fontSize:12}}>{'Mercredi(F) '}</span>)}
            {(d.jeudi ? <span style={{color: '#00ff28', fontSize:12}}>{'Jeudi(O) '}</span> : <span style={{color: '#ff2940', fontSize:12}}>{'Jeudi(F) '}</span>)}
            {(d.vendredi ? <span style={{color: '#00ff28', fontSize:12}}>{'Vendredi(O) '}</span> : <span style={{color: '#ff2940', fontSize:12}}>{'Vendredi(F) '}</span>)}
            {(d.samedi ? <span style={{color: '#00ff28', fontSize:12}}>{'Samedi(O) '}</span> : <span style={{color: '#ff2940', fontSize:12}}>{'Samedi(F) '}</span>)}
            {(d.dimanche ? <span style={{color: '#00ff28', fontSize:12}}>{'Dimanche(O) '}</span> : <span style={{color: '#ff2940', fontSize:12}}>{'Dimanche(F) '}</span>)}
        </span>},
        wrap:true,
        sortable:true,
        style:{fontSize:16}
    },
    {
        name: '',
        wrap:true,
        button: true,
        cell: (row) => <div style={{ }}><div  onClick={ShowConfirmDelete} id={row.id} style={{fontSize:11, color: 'red'}}>Supprimer<Delete /></div></div>,}

]);



export const Laplagehoraire = () => {
    const divRef = React.useRef(null);

    const [switchPlagehoraire, setSwitchPlagehoraire] = React.useState('Afficher');
    const [searchField, setSearchField] = useState("");
    const [isLoading, setLoading] = React.useState(false);
    const [isLoadingModif, setLoadingmodif] = React.useState(false);
    const [tables, setTables] = React.useState([]);
    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});

    const [isshowmodif, setshowmodif] = useState(false);
    const [variabmodif, setVariabmodif] = useState(null);

    const [idtosuppr, setIdToSuppr] = useState(null);
    const [open, setOpen] = React.useState(false);
    const ShowConfirmDelete = async (val) => {
        console.log('val.target.id', val.target.id);
        setIdToSuppr(val.target.id);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const suppression = async (e)=> {
        helpers
            .supprPlageHoraire({id: idtosuppr})
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    getPlagehoraire().catch(console.error);
                    setOpen(false);
                }
            })
    };

    const handleshowmodif = (event) => {
        setshowmodif(event);
        setSwitchPlagehoraire('Ajouter')
    }
    const handleSwitchplagehoraire = (event) => {
        if(event.target.value === 'Afficher')
            setshowmodif(false);
        setSwitchPlagehoraire(event.target.value);
    };
    const setByChildLoading = (loading) => {
        setLoading(loading);
    };
    const setByChildOpenToast = (toast) => {
        setOpenToast(toast);
    };
    function rechercheindb(){
        if(searchField != ''){
            return;
        }
        return;
    }



    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };
    const getPlagehoraire = async () => {
        helpers
            .getPlagehoraire()
            .then(async r => {
                console.log('resp plagehoraire', r);
                setLoading(false);
                if (r.status === 200) {
                    setTables( r.data);
                }
            })
    };


    useEffect(()=>{
        setLoading(true);
        getPlagehoraire().catch(console.error);
    },[]);




    return (
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Chargement...'
        >
            <div className="content-wrapper">

                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">{isshowmodif && 'Modification du '}Plage horaire</h1>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        Plage horaire
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <Link to="/dashboard"  className="link">
                                        Parc access
                                        </Link>
                                    </li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>


                <div className="col" style={{ marginBottom:20}}>

                    <div style={{ justifyContent:'flex-start', marginLeft:50 }} className=" d-flex flex-row ">
                        <div className="row d-flex flex-row align-items-center justify-content-between">
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={switchPlagehoraire}
                                    onChange={handleSwitchplagehoraire}
                                >
                                    <FormControlLabel value="Ajouter" control={<Radio />} label={isshowmodif ? "Modifier" : "Ajouter"} />
                                    <FormControlLabel value="Afficher" control={<Radio />} label="Afficher" />
                                </RadioGroup>
                            </FormControl>
                            {switchPlagehoraire === "Afficher" && <Paper
                                component="form"
                                sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}
                            >
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Rechercher"
                                    inputProps={{ 'aria-label': 'Rechercher' }}
                                    onChange={(e)=>{
                                        e.preventDefault();
                                        setSearchField(e.target.value);
                                    }}
                                    value={searchField}
                                />
                                <IconButton  onClick={() => {
                                    rechercheindb()
                                }} type="button" sx={{ p: '5px' }} aria-label="search">
                                    <SearchIcon />
                                </IconButton>
                            </Paper>}
                        </div>
                    </div>
                    {switchPlagehoraire === 'Afficher' && <DataTable
                        pagination
                        dense
                        columns={columns(ShowConfirmDelete)}
                        data={tables}
                        striped
                        highlightOnHover
                        persistTableHead
                        fixedHeader={false}
                        progressPending={false}
                        noDataComponent={"Aucune données à afficher"}
                        //onSelectedRowsChange={handleChange}
                        onRowClicked={(row, event) => {console.log('row clicked', row);
                            setVariabmodif(row || null);
                            handleshowmodif(true);
                        }}
                        paginationComponentOptions={{ rowsPerPageText: 'Lignes par page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                    />}
                    {(switchPlagehoraire === 'Ajouter') && <div className={"w-100 bg-light"} ><div className={"w-50"}><AjouterModifPlagehoraire setSwitchplagehoraire={handleSwitchplagehoraire} getPlagehoraire={getPlagehoraire} setOpenToast={setByChildOpenToast} setParentLoading={setByChildLoading} isshowmodif={isshowmodif} variabmodif={variabmodif} setshowmodif={setshowmodif} switchPlagehoraire={switchPlagehoraire}/></div></div>}
                </div>
            </div>


            <Snackbar
                open={openToast.isOpenToast}
                autoHideDuration={6000}
                //onClose={()=>setOpenToast({...openToast, isOpenToast:false})}
                message={openToast.txt}
                action={<React.Fragment>
                    <IconButton
                        size="small"
                        aria-label="fermer"
                        color="inherit"
                        onClick={(event, reason) => {
                            if (reason === 'clickaway') {
                                return;
                            }
                            setOpenToast(false);
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>}
            />
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Suppression plage horaire
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Voulez-vous vraiment supprimer?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button  onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button onClick={suppression}>Ok</Button>
                </DialogActions>
            </Dialog>
        </LoadingOverlay>
    )
};
