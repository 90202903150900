import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField/TextField";
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import {Alert} from "../Authentication/index";
import SaveIcon from '@mui/icons-material/Save';
import FormControlLabel from '@mui/material/FormControlLabel';
import {helpers} from "../../services/api/helpers";
import ls from "local-storage";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import {ThemeProvider} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import LoadingOverlay from "react-loading-overlay";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { useLocation, useNavigate } from "react-router-dom"
// import Shop2 from '@mui/icons-material/Shop2';
// import Square from '@mui/icons-material/Square';
// import Stadium from '@mui/icons-material/Stadium';
// import Snooze from '@mui/icons-material/Snooze';
// import Store from '@mui/icons-material/Store';
// import Tapas from '@mui/icons-material/Tapas';
// import TenMp from '@mui/icons-material/TenMp';
import Delete from '@mui/icons-material/Delete';
import Dialog from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions/DialogActions";
// import Timelaplse from '@mui/icons-material/Timelaplse';
// import Timer10 from '@mui/icons-material/Timer10';
// import Toc from '@mui/icons-material/Toc';
// import Toll from '@mui/icons-material/Toll';
// import Tour from '@mui/icons-material/Tour';
// import Trafic from '@mui/icons-material/Trafic';
// import Tram from '@mui/icons-material/Tram';
// import Troubleshoot from '@mui/icons-material/Troubleshoot';
// import Try from '@mui/icons-material/Try';
// import Tsunami from '@mui/icons-material/Tsunami';
// import Upcoming from '@mui/icons-material/Upcoming';
// import Villa from '@mui/icons-material/Villa';
// import Widgets from '@mui/icons-material/Widgets';
// import Work from '@mui/icons-material/Work';
// import Yard from '@mui/icons-material/Yard';




const columns = ((clickHandler, ShowConfirmDelete) => [
    {
        name: 'Nom',
        selector: row => row.nom,
        sortable:true,
        wrap:true,
        style:{fontSize:16}
    },
    {
        name: 'Adresse',
        selector: row => row.adresse,
        wrap:true,
        sortable:true,
    },
    {
        name: 'Commentaire',
        selector: row => row.commentaire,
        wrap:true,
    },
    {
        name: '',
        wrap:true,
        button: true,
        cell: (row) => <div style={{ }}><span style={{paddingTop:4, paddingBottom:4, paddingLeft:6, paddingRight:6, backgroundColor:'#75ff42', borderRadius:10}}>{row?.sites.length}</span><Button className={"btn btn-success px-1"} onClick={(row)=>clickHandler(row)} id={row.id} style={{fontSize:11}}>Sites</Button></div>,
        // cell: (row) => <div style={{ }}><span style={{paddingTop:4, paddingBottom:4, paddingLeft:6, paddingRight:6, backgroundColor:'#75ff42', borderRadius:10}}>{row?.sites.length}</span><Button className={"btn btn-success px-1"} onClick={(row)=>clickHandler(row)} id={row.id} style={{fontSize:11}}>Sites<span style={{marginLeft:2}}><Terrain /></span></Button></div>,
        // cell: (row) => <div><span style={{paddingTop:4, paddingBottom:4, paddingLeft:6, paddingRight:6, backgroundColor:'#75ff42', borderRadius:10}}>{row?.sites.length}</span><Button className={"btn btn-success px-3"} onClick={(row)=>clickHandler(row)} id={row.id} style={{fontSize:11}}>Sites</Button></div>,

    },
    {
        name: '',
        wrap:true,
        button: true,
        cell: (row) => <div style={{ }}><div  onClick={ShowConfirmDelete} id={row.id} style={{fontSize:11, color: 'red'}}>Supprimer<Delete /></div></div>,}
]);


export const Groupe = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [isshowmodal, setshowmodal] = useState(false);
    const [isshowmodalmodif, setshowmodalmodif] = useState(false);
    const [isLoadingSave, setLoadingSave] = useState(false);
    const [openSnack, setOpenSnack] = useState({
        isOpenNomError:false,
        txt:''});
    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [tables, setTables] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);
    const [searchField, setSearchField] = useState("");
    const [nommodif, setnommodif] = useState("");
    const [adressemodif, setadressemodif] = useState("");
    const [commentairemodif, setcommentairemodif] = useState("");
    const [idmodif, setidmodif] = useState(null);
    const [idtosuppr, setIdToSuppr] = useState(null);

    const handleButtonColumn = (val) => {
        const from =  `/dashboard/sitespargroupe/${val.target.id}`;
        navigate(from, { replace: true })
    };

    const [open, setOpen] = React.useState(false);
    const ShowConfirmDelete = async (val) => {
        console.log('target id', await val.target.id)
        setIdToSuppr(val.target.id);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const suppression = async (e)=> {
        helpers
            .supprGroupe({id: idtosuppr})
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);

                    getGroupes().catch(console.error);
                    setOpen(false);
                }
            })
    };


    useEffect(()=>{
        const filteredTables = tables && tables.filter(
            item => {
                return (
                    item.nom !== null && item.nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.adresse !== null && item.adresse
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.commentaire !== null && item.commentaire
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                );
            }
        );
        if(searchField == '')
            getGroupes().catch(console.error);
        if(filteredTables !== undefined)
       setTables(filteredTables);
    },[searchField]);

    function rechercheindb(){
        if(searchField != ''){
            return;
        }
        return;

    }

    function newGroupes(e) {
        e.preventDefault();
        setshowmodal(true);
    }

    const getGroupes = async () => {
        helpers
            .getAllGroupes()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setTables( r.data);
                }
            })
    };

    useEffect(()=>{
        setLoading(true);
        getGroupes().catch(console.error);
    },[]);


    const savenewGroupe =(e)=> {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const nom = data.get('nom');
        const adresse = data.get('adresse');
        const commentaire = data.get('commentaire');

            if(nom == ''){
                setOpenSnack({...openSnack, isOpenNomError:true,txt:'Veuillez insérer le nom du groupe'});
                return;
            }
           setOpenSnack({...openSnack, isOpenNomError:false});

        setLoadingSave(true);
        let dataX = {
            nom: nom,
            adresse: adresse,
            commentaire: commentaire
        };

        helpers
            .groupesave(dataX)
            .then(async r => {
                setLoadingSave(false);
                if (r.status === 200) {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setshowmodal(false);
                    getGroupes().catch(console.error);
                } else {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                }
            })
            .catch(e => {
                setLoadingSave(false);
            });
    };

    const modifGroupe =(e)=> {
        e.preventDefault();

        if(nommodif == ''){
            setOpenSnack({...openSnack, isOpenNomError:true,txt:'Le nom du groupe ne pourrait être pas vide.'});
            return;
        }
        setOpenSnack({...openSnack, isOpenNomError:false});

        setLoadingSave(true);
        let dataX = {
            nom: nommodif,
            adresse: adressemodif,
            commentaire: commentairemodif,
            id: idmodif
        };

        if(idmodif != null)
        helpers
            .groupemodification(dataX)
            .then(async r => {
                setLoadingSave(false);
                if (r.status === 200) {
                    await getGroupes().catch(()=>null);
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setshowmodalmodif(false);
                } else {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                }
            })
            .catch(e => {
                setLoadingSave(false);
            });
    };
    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };


  return (
      <LoadingOverlay
          active={isLoading}
          spinner
          text='Chargement...'
      >
      <div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0 text-dark">Groupes</h1>
                    </div>{/* /.col */}
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item">
                                    Groupe
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/dashboard"  className="link">
                                Parc access
                                </Link>
                            </li>
                        </ol>
                    </div>{/* /.col */}
                </div>{/* /.row */}
                <button
                    onClick={(e)=>newGroupes(e)}
                    className={"btn btn-success px-4"}><i className="fas fa-plus-circle mr-2" />Ajout</button>
            </div>{/* /.container-fluid */}
        </div>

          {/*le datatable*/}
          <div className="card">
              <div className="card-body">
                  <Paper
                      component="form"
                      sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}
                  >
                      <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          placeholder="Rechercher"
                          inputProps={{ 'aria-label': 'Rechercher' }}
                          onChange={(e)=>{
                              e.preventDefault();
                              setSearchField(e.target.value);
                          }}
                          value={searchField}
                      />
                      <IconButton  onClick={() => {
                          rechercheindb()
                      }} type="button" sx={{ p: '5px' }} aria-label="search">
                          <SearchIcon />
                      </IconButton>
                  </Paper>


                  <DataTable
                      pagination
                     // selectableRowsComponent={Checkbox}
                      selectableRowsComponentProps={selectProps}
                     // sortIcon={sortIcon}
                      dense
                      //{...props}
                      //title="Administration"
                     // theme={'dark'}
                     //  columns={columns}
                      columns={columns(handleButtonColumn, ShowConfirmDelete)}

                      data={tables}
                      // selectableRows
                      striped
                      highlightOnHover
                      persistTableHead
                      fixedHeader={false}
                      progressPending={false}
                      noDataComponent={"Aucune données à afficher"}
                      onRowClicked={(row, event) => {console.log('row clicked', row);
                          setnommodif(row?.nom);
                          setadressemodif(row?.adresse);
                          setcommentairemodif(row?.commentaire);
                          setshowmodalmodif(true);
                          setidmodif(row?.id);
                      }}
                      // onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {console.log(' onChangePage')}}
                      // onChangePage={(page, totalRows)  => {console.log(' onChangePage')}}
                      // onRowMouseEnter={(row, event) => {console.log('row clicked')}}
                      // expandableRows={false}
                      // expandableRowsComponent={() => {console.log('row clicked')}}
                      paginationComponentOptions={{ rowsPerPageText: 'Lignes par page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                  />
              </div>
              {/*fin body*/}
          </div>

          <Modal
              open={isshowmodal}
              onClose={()=>setshowmodal(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
          >
              <div className="modal-dialog ">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h4 className="modal-title">Nouveau groupe</h4>
                          <button onClick={()=>setshowmodal(false)} type="button" className="close" data-dismiss="modal" aria-label="Fermer">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>
                      <Box component="form" onSubmit={savenewGroupe} sx={{ mt: 1 }}
                           Validate
                           autoComplete="on"   >

                      <div   className="modal-body">

                              <TextField
                                  margin="normal"
                                  required
                                  fullWidth
                                  id="nom"
                                  label="Nom"
                                  name="nom"
                                  autoComplete="nom"
                                  autoFocus
                              />
                              <Collapse in={openSnack.isOpenNomError}>
                                  <Alert severity="error" action={
                                      <IconButton
                                          aria-label="fermer"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                              setOpenSnack({...openSnack, isOpenNomError:false});
                                          }}
                                      >
                                          <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                  }>{openSnack.txt}</Alert>
                              </Collapse>


                          <TextField
                              margin="normal"
                          //    required
                              fullWidth
                              id="adresse"
                              label="Adresse"
                              name="adresse"
                              autoComplete="adresse"
                              autoFocus
                          />

                          <TextField
                              margin="normal"
                          //    required
                              fullWidth
                              id="commentaire"
                              label="Commentaire"
                              name="commentaire"
                              autoComplete="commentaire"
                              autoFocus
                          />


                      </div>
                      <div className="modal-footer d-flex flex-row justify-content-between">
                          <button onClick={()=>setshowmodal(false)} type="button" className="btn btn-default" data-dismiss="modal">Fermer</button>
                          <Button
                              onPress = {savenewGroupe}
                              type="submit"
                              // fullWidth
                              variant="contained"
                              // sx={{ mt: 3, mb: 2 }}
                              startIcon={
                                  isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                 : <SaveIcon fontSize="inherit" />
                              }
                          >
                              Enregistrer
                          </Button>
                      </div>
                      </Box>

                  </div>
              </div>
          </Modal>
          <Modal
              open={isshowmodalmodif}
              onClose={()=>setshowmodalmodif(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
          >
              <div className="modal-dialog ">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h4 className="modal-title">Modification groupe</h4>
                          <button onClick={()=>setshowmodalmodif(false)} type="button" className="close" data-dismiss="modal" aria-label="Fermer">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>

                      <Box component="form" onSubmit={modifGroupe} sx={{ mt: 1 }}
                           Validate
                           autoComplete="on"   >

                          <div   className="modal-body">

                              <TextField
                                  margin="normal"
                                  required
                                  fullWidth
                                  id="nom"
                                  label="Nom"
                                  name="nom"
                                  autoComplete="nom"
                                  autoFocus
                                  value={nommodif}
                                  onChange={(e)=>setnommodif(e.target.value)}
                              />

                              <Collapse in={openSnack.isOpenNomError}>
                                  <Alert severity="error" action={
                                      <IconButton
                                          aria-label="fermer"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                              setOpenSnack({...openSnack, isOpenNomError:false});
                                          }}
                                      >
                                          <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                  }>{openSnack.txt}</Alert>
                              </Collapse>
                              <TextField
                                  margin="normal"
                                  //    required
                                  fullWidth
                                  id="adresse"
                                  label="Adresse"
                                  name="adresse"
                                  autoComplete="adresse"
                                  value={adressemodif}
                                  onChange={(e)=>setadressemodif(e.target.value)}
                              />
                              <TextField
                                  margin="normal"
                                  //    required
                                  fullWidth
                                  id="commentaire"
                                  label="Commentaire"
                                  name="commentaire"
                                  autoComplete="commentaire"
                                  value={commentairemodif}
                                  onChange={(e)=>setcommentairemodif(e.target.value)}
                              />
                          </div>
                          <div className="modal-footer d-flex flex-row justify-content-between">
                              <button onClick={()=>setshowmodalmodif(false)} type="button" className="btn btn-default" data-dismiss="modal">Fermer</button>
                              <Button
                                  onPress = {modifGroupe}
                                  type="submit"
                                  // fullWidth
                                  variant="contained"
                                  // sx={{ mt: 3, mb: 2 }}
                                  startIcon={
                                      isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                          : <SaveIcon fontSize="inherit" />
                                  }
                              >
                                  Enregistrer
                              </Button>
                          </div>
                      </Box>

                  </div>
              </div>
          </Modal>
          <Snackbar
              open={openToast.isOpenToast}
              autoHideDuration={6000}
              //onClose={()=>setOpenToast({...openToast, isOpenToast:false})}
              message={openToast.txt}
              action={<React.Fragment>
                  <IconButton
                      size="small"
                      aria-label="fermer"
                      color="inherit"
                      onClick={(event, reason) => {
                          if (reason === 'clickaway') {
                              return;
                          }
                          setOpenToast(false);
                      }}
                  >
                      <CloseIcon fontSize="small" />
                  </IconButton>
              </React.Fragment>}
          />
      </div>
          <Dialog
              open={open}
              onClose={handleClose}
              // PaperComponent={PaperComponent}
              // aria-labelledby="draggable-dialog-title"
              //scroll={'body'}
          >
              <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                  Suppression groupe
              </DialogTitle>
              <DialogContent>
                  <DialogContentText>
                      Voulez-vous vraiment supprimer?
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button  onClick={handleClose}>
                      Annuler
                  </Button>
                  <Button onClick={suppression}>Ok</Button>
              </DialogActions>
          </Dialog>
      </LoadingOverlay>
  )
};
