import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import {helpers} from "../../services/api/helpers";
import ls from "local-storage";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import LoadingOverlay from "react-loading-overlay";
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import { useLocation, useNavigate } from "react-router-dom"
import { socket } from '../../socket';
import { io } from 'socket.io-client';


const d = [{
    horraire:"2023/01/10 10:20",
    immatriculation: "Karug961",
    entrersortie: "Entrer",
    isrefus: "Autorisé",
    groupe: "Cap Vert",
    site: "La moule"
}]

let isAdmin = ls.get('xxxx');

let columns = [];
if(isAdmin === "AAAA" ) {
    columns = (clickHandler => [
        {
            name: 'Horraire',
            selector: row => (row.horairestring).substr(0, 19),
            sortable: true,
            wrap: true,
            style: {fontSize: 16}
        },
        {
            name: 'Immatriculation',
            selector: row => row.immatriculation,
            wrap: true,
            sortable: true,
        },
        {
            name: 'Etat',
            // selector: row => row.isAutorise ? 'Autorisé' : 'Refusé',
            wrap: true,
            cell: (row) => row.isAutorise === true ? <div style={{fontSize: 11, color: 'green'}}>Autorisé</div> :
                <div style={{fontSize: 11, color: 'red'}}>Refusé</div>,
        },
        {
            name: 'Groupe(Ville)',
            selector: row => row?.site?.groupe?.nom,
            wrap: true,
        },
        {
            name: 'Site',
            selector: row => row?.site?.nom,
            wrap: true,
        },
        // {
        //     name: '',
        //     wrap:true,
        //     button: true,
        //     cell: (row) => <Button className={"btn btn-success px-4"} onClick={(row)=>clickHandler(row)} id={row.id} style={{fontSize:11}}>Sites</Button>,
        //
        // }
    ]);
}else{

    columns = (clickHandler => [
        {
            name: 'Horraire',
            selector: row => (row.horairestring).substr(0,19),
            sortable:true,
            wrap:true,
            style:{fontSize:16}
        },
        {
            name: 'Immatriculation',
            selector: row => row.immatriculation,
            wrap:true,
            sortable:true,
        },
        {
            name: 'Etat',
            // selector: row => row.isAutorise ? 'Autorisé' : 'Refusé',
            wrap:true,
            cell: (row) => row.isAutorise === true ? <div style={{fontSize:11, color: 'green'}}>Autorisé</div> : <div style={{fontSize:11, color:'red'}}>Refusé</div>,
        }
    ]);
}


export const EntrerSortie0 = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [isshowmodalmodif, setshowmodalmodif] = useState(false);
    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [tables, setTables] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);
    const [searchField, setSearchField] = useState("");

    const handleButtonColumn = (val) => {
        const from =  `/dashboard/sitespargroupe/${val.target.id}`;
        navigate(from, { replace: true })
    };

    useEffect(()=>{
        const filteredTables = tables.data && tables.data.filter(
            item => {
                return (
                    item
                        .nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item
                        .adresse
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item
                        .commentaire
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                );
            }
        );
        if(searchField == '')
         //   getEntrerSortie().catch(console.error);
        setTables(filteredTables);
    },[searchField]);

    function rechercheindb(){
        if(searchField != ''){
            return;
        }
        return;
    }


    const getEntrerSortie = async () => {
        console.log('interval');
        helpers
            .getEntrerSortie()
            .then(async r => {
                console.log('response', r);
                setLoading(false);
                if (r.status === 200) {
                    setTables(r.data);
                }
            })
    };

    // useEffect(()=>{
    //     console.log("in use efffect");
    //     setLoading(true);
    //   //  getEntrerSortie().catch(console.error);
    //     console.log('------------socket---------------');
    //     console.log('socket', socket);
    //
    //     socket.on('connection', (socket1) => {
    //         console.log(`⚡: ${socket1.id} user just connected!`);
    //         socket1.on('message-broadcast', (data) => {
    //             console.log('message-broadcast', data);
    //         });
    //     });
    //
    //     // socket.on('message-broadcast', function onConnect() {
    //     //     console.log('dadabe1')
    //     // });
    //     // socket.on('viaserver', function onConnect() {
    //     //     console.log('dadabe')
    //     // });
    //     // // socket.on('foo', onFooEvent);
    // },[]);

    useEffect(()=>{
        getEntrerSortie().catch(console.error);
        setInterval(()=>{
             getEntrerSortie().catch(console.error);

        },10000);
    },[]);


    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };


  return (
      <LoadingOverlay
          active={isLoading}
          spinner
          text='Chargement...'
      >
      <div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0 text-dark">Entrer sur site</h1>
                    </div>{/* /.col */}
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item">
                              Entrer sur site
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/dashboard"  className="link">
                                Parc access
                                </Link>
                            </li>
                        </ol>
                    </div>{/* /.col */}
                </div>{/* /.row */}
                {/*<button*/}
                {/*    onClick={(e)=>newGroupes(e)}*/}
                {/*    className={"btn btn-success px-4"}><i className="fas fa-plus-circle mr-2" />Ajout</button>*/}
            </div>{/* /.container-fluid */}
        </div>

          {/*le datatable*/}
          <div className="card">
              <div className="card-body">
                  {/*<Paper*/}
                  {/*    component="form"*/}
                  {/*    sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}*/}
                  {/*>*/}
                  {/*    <InputBase*/}
                  {/*        sx={{ ml: 1, flex: 1 }}*/}
                  {/*        placeholder="Rechercher"*/}
                  {/*        inputProps={{ 'aria-label': 'Rechercher' }}*/}
                  {/*        onChange={(e)=>{*/}
                  {/*            e.preventDefault();*/}
                  {/*            setSearchField(e.target.value);*/}
                  {/*        }}*/}
                  {/*        value={searchField}*/}
                  {/*    />*/}
                  {/*    <IconButton  onClick={() => {*/}
                  {/*        rechercheindb()*/}
                  {/*    }} type="button" sx={{ p: '5px' }} aria-label="search">*/}
                  {/*        <SearchIcon />*/}
                  {/*    </IconButton>*/}
                  {/*</Paper>*/}


                  <DataTable
                      pagination
                     // selectableRowsComponent={Checkbox}
                      selectableRowsComponentProps={selectProps}
                     // sortIcon={sortIcon}
                      dense
                      //{...props}
                      //title="Administration"
                     // theme={'dark'}
                     //  columns={columns}
                      columns={columns(handleButtonColumn)}

                      data={tables}
                      // selectableRows
                      striped
                      highlightOnHover
                      persistTableHead
                      fixedHeader={false}
                      progressPending={false}
                      noDataComponent={"Aucune données à afficher"}
                      onRowClicked={(row, event) => {console.log('row clicked', row);
                          // setnommodif(row?.nom);
                          // setadressemodif(row?.adresse);
                          // setcommentairemodif(row?.commentaire);
                          // setshowmodalmodif(true);
                          // setidmodif(row?.id);
                      }}
                      // onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {console.log(' onChangePage')}}
                      // onChangePage={(page, totalRows)  => {console.log(' onChangePage')}}
                      // onRowMouseEnter={(row, event) => {console.log('row clicked')}}
                      // expandableRows={false}
                      // expandableRowsComponent={() => {console.log('row clicked')}}
                      paginationComponentOptions={{ rowsPerPageText: 'Lignes par page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                  />
              </div>
              {/*fin body*/}
          </div>

          {/*<Modal*/}
          {/*    open={isshowmodal}*/}
          {/*    onClose={()=>setshowmodal(false)}*/}
          {/*    aria-labelledby="modal-modal-title"*/}
          {/*    aria-describedby="modal-modal-description"*/}
          {/*>*/}
          {/*    <div className="modal-dialog ">*/}
          {/*        <div className="modal-content">*/}
          {/*            <div className="modal-header">*/}
          {/*                <h4 className="modal-title">Nouveau groupe</h4>*/}
          {/*                <button onClick={()=>setshowmodal(false)} type="button" className="close" data-dismiss="modal" aria-label="Fermer">*/}
          {/*                    <span aria-hidden="true">&times;</span>*/}
          {/*                </button>*/}
          {/*            </div>*/}
          {/*            <Box component="form" onSubmit={savenewGroupe} sx={{ mt: 1 }}*/}
          {/*                 Validate*/}
          {/*                 autoComplete="on"   >*/}

          {/*            <div   className="modal-body">*/}

          {/*                    <TextField*/}
          {/*                        margin="normal"*/}
          {/*                        required*/}
          {/*                        fullWidth*/}
          {/*                        id="nom"*/}
          {/*                        label="Nom"*/}
          {/*                        name="nom"*/}
          {/*                        autoComplete="nom"*/}
          {/*                        autoFocus*/}
          {/*                    />*/}
          {/*                    <Collapse in={openSnack.isOpenNomError}>*/}
          {/*                        <Alert severity="error" action={*/}
          {/*                            <IconButton*/}
          {/*                                aria-label="fermer"*/}
          {/*                                color="inherit"*/}
          {/*                                size="small"*/}
          {/*                                onClick={() => {*/}
          {/*                                    setOpenSnack({...openSnack, isOpenNomError:false});*/}
          {/*                                }}*/}
          {/*                            >*/}
          {/*                                <CloseIcon fontSize="inherit" />*/}
          {/*                            </IconButton>*/}
          {/*                        }>{openSnack.txt}</Alert>*/}
          {/*                    </Collapse>*/}


          {/*                <TextField*/}
          {/*                    margin="normal"*/}
          {/*                //    required*/}
          {/*                    fullWidth*/}
          {/*                    id="adresse"*/}
          {/*                    label="Adresse"*/}
          {/*                    name="adresse"*/}
          {/*                    autoComplete="adresse"*/}
          {/*                    autoFocus*/}
          {/*                />*/}

          {/*                <TextField*/}
          {/*                    margin="normal"*/}
          {/*                //    required*/}
          {/*                    fullWidth*/}
          {/*                    id="commentaire"*/}
          {/*                    label="Commentaire"*/}
          {/*                    name="commentaire"*/}
          {/*                    autoComplete="commentaire"*/}
          {/*                    autoFocus*/}
          {/*                />*/}


          {/*            </div>*/}
          {/*            <div className="modal-footer d-flex flex-row justify-content-between">*/}
          {/*                <button onClick={()=>setshowmodal(false)} type="button" className="btn btn-default" data-dismiss="modal">Fermer</button>*/}
          {/*                <Button*/}
          {/*                    onPress = {savenewGroupe}*/}
          {/*                    type="submit"*/}
          {/*                    // fullWidth*/}
          {/*                    variant="contained"*/}
          {/*                    // sx={{ mt: 3, mb: 2 }}*/}
          {/*                    startIcon={*/}
          {/*                        isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />*/}
          {/*                       : <SaveIcon fontSize="inherit" />*/}
          {/*                    }*/}
          {/*                >*/}
          {/*                    Enregistrer*/}
          {/*                </Button>*/}
          {/*            </div>*/}
          {/*            </Box>*/}

          {/*        </div>*/}
          {/*    </div>*/}
          {/*</Modal>*/}
          <Modal
              open={isshowmodalmodif}
              onClose={()=>setshowmodalmodif(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
          >
              <div className="modal-dialog ">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h4 className="modal-title">Modification groupe</h4>
                          <button onClick={()=>setshowmodalmodif(false)} type="button" className="close" data-dismiss="modal" aria-label="Fermer">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>

                      {/*<Box component="form" onSubmit={modifGroupe} sx={{ mt: 1 }}*/}
                      {/*     Validate*/}
                      {/*     autoComplete="on"   >*/}

                      {/*    <div   className="modal-body">*/}

                      {/*        <TextField*/}
                      {/*            margin="normal"*/}
                      {/*            required*/}
                      {/*            fullWidth*/}
                      {/*            id="nom"*/}
                      {/*            label="Nom"*/}
                      {/*            name="nom"*/}
                      {/*            autoComplete="nom"*/}
                      {/*            autoFocus*/}
                      {/*            value={nommodif}*/}
                      {/*            onChange={(e)=>setnommodif(e.target.value)}*/}
                      {/*        />*/}

                      {/*        <Collapse in={openSnack.isOpenNomError}>*/}
                      {/*            <Alert severity="error" action={*/}
                      {/*                <IconButton*/}
                      {/*                    aria-label="fermer"*/}
                      {/*                    color="inherit"*/}
                      {/*                    size="small"*/}
                      {/*                    onClick={() => {*/}
                      {/*                        setOpenSnack({...openSnack, isOpenNomError:false});*/}
                      {/*                    }}*/}
                      {/*                >*/}
                      {/*                    <CloseIcon fontSize="inherit" />*/}
                      {/*                </IconButton>*/}
                      {/*            }>{openSnack.txt}</Alert>*/}
                      {/*        </Collapse>*/}
                      {/*        <TextField*/}
                      {/*            margin="normal"*/}
                      {/*            //    required*/}
                      {/*            fullWidth*/}
                      {/*            id="adresse"*/}
                      {/*            label="Adresse"*/}
                      {/*            name="adresse"*/}
                      {/*            autoComplete="adresse"*/}
                      {/*            value={adressemodif}*/}
                      {/*            onChange={(e)=>setadressemodif(e.target.value)}*/}
                      {/*        />*/}
                      {/*        <TextField*/}
                      {/*            margin="normal"*/}
                      {/*            //    required*/}
                      {/*            fullWidth*/}
                      {/*            id="commentaire"*/}
                      {/*            label="Commentaire"*/}
                      {/*            name="commentaire"*/}
                      {/*            autoComplete="commentaire"*/}
                      {/*            value={commentairemodif}*/}
                      {/*            onChange={(e)=>setcommentairemodif(e.target.value)}*/}
                      {/*        />*/}
                      {/*    </div>*/}
                      {/*    <div className="modal-footer d-flex flex-row justify-content-between">*/}
                      {/*        <button onClick={()=>setshowmodalmodif(false)} type="button" className="btn btn-default" data-dismiss="modal">Fermer</button>*/}
                      {/*        <Button*/}
                      {/*            onPress = {modifGroupe}*/}
                      {/*            type="submit"*/}
                      {/*            // fullWidth*/}
                      {/*            variant="contained"*/}
                      {/*            // sx={{ mt: 3, mb: 2 }}*/}
                      {/*            startIcon={*/}
                      {/*                isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />*/}
                      {/*                    : <SaveIcon fontSize="inherit" />*/}
                      {/*            }*/}
                      {/*        >*/}
                      {/*            Enregistrer*/}
                      {/*        </Button>*/}
                      {/*    </div>*/}
                      {/*</Box>*/}

                  </div>
              </div>
          </Modal>
          <Snackbar
              open={openToast.isOpenToast}
              autoHideDuration={6000}
              //onClose={()=>setOpenToast({...openToast, isOpenToast:false})}
              message={openToast.txt}
              action={<React.Fragment>
                  <IconButton
                      size="small"
                      aria-label="fermer"
                      color="inherit"
                      onClick={(event, reason) => {
                          if (reason === 'clickaway') {
                              return;
                          }
                          setOpenToast(false);
                      }}
                  >
                      <CloseIcon fontSize="small" />
                  </IconButton>
              </React.Fragment>}
          />
      </div>
      </LoadingOverlay>
  )
};
