import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField/TextField";
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import {Alert} from "../Authentication/index";
import SaveIcon from '@mui/icons-material/Save';
import FormControlLabel from '@mui/material/FormControlLabel';
import {helpers} from "../../services/api/helpers";
import ls from "local-storage";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import {ThemeProvider} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import LoadingOverlay from "react-loading-overlay";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { useLocation, useNavigate, Navigate    } from "react-router-dom"
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import Delete from '@mui/icons-material/Delete';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Dialog from "@mui/material/Dialog/Dialog";
import Checkbox from "@mui/material/Checkbox/Checkbox";
let isAdmin = ls.get('xxxx');

let columns = [];
if(isAdmin === "AAAA" ){
    columns = (( ShowConfirmDelete, handleAutorisationEntrerSiteDatatable) => [
        {
            name: 'Immatriculation',
            selector: row => row.immatriculation,
            sortable:true,
            wrap:true,
            style:{fontSize:16}
        },
        {
            name: 'Type',
            selector: row => row?.typevehicule?.type ,
            wrap:true,
            sortable:true,
        },
        {
            name: 'Marque',
            selector: row => row?.marque?.marque,
            wrap:true,
            sortable:true,
        },
        {
            name: 'Usager',
            selector: row => (row?.usager?.nom != undefined ? row?.usager?.nom : '') + " " + row?.usager?.prenom != undefined  ? row?.usager?.prenom : '' ,
            wrap:true,
            sortable:true,
        },
        {
            name: 'Email usager',
            selector: row => row?.usager?.email ,
            wrap:true,
            sortable:true,
        },
        {
            name: 'Groupe',
            selector: row =>  row?.usager?.site?.groupe?.nom,
            wrap:true,
            sortable:true,
        },
        {
            name: 'Site',
            selector: row => row?.usager?.site?.nom ,
            wrap:true,
            sortable:true,
        },
        {
            name: 'Liste blanche',
            wrap:true,
            sortable:false,
            cell: (row) => <div style={{ }}>
                {row?.isAutorisationEntrerSite?
                    <div  onClick={handleAutorisationEntrerSiteDatatable} id={row.id + "//" + row?.site?.nom + "//" + row?.immatriculation + "//" + row?.isAutorisationEntrerSite}  style={{fontSize:11, color: 'green'}}>
                        Autorisé <Check />
                    </div>
                    :
                    <div  onClick={handleAutorisationEntrerSiteDatatable} id={row.id + "//" + row?.site?.nom + "//" + row?.immatriculation + "//" + row?.isAutorisationEntrerSite}  style={{fontSize:11, color: 'red'}}>
                        Refusé <Close />
                    </div>
                }

                {/*<TextField*/}
                {/*    margin="normal"*/}
                {/*    required*/}
                {/*    fullWidth*/}
                {/*    id="listeblanche"*/}
                {/*    select*/}
                {/*    label=""*/}
                {/*    value={row?.isAutorisationEntrerSite==true ? "Autorisé" : "Refusé"}*/}
                {/*    onChange={handleAutorisationEntrerSiteDatatable}*/}
                {/*    //helperText="S'il vous plait choisissez le type de l'admin"*/}
                {/*    style={{width:100}}*/}
                {/*>*/}
                {/*    {["Autorisé", "Refusé"].map((option) => (*/}
                {/*        <MenuItem key={option} value={row?.isAutorisationEntrerSite==true  ? "Autorisé" : "Refusé"}>*/}
                {/*            {option}*/}
                {/*        </MenuItem>*/}
                {/*    ))}*/}
                {/*</TextField>*/}
                {/*<FormControlLabel*/}
                {/*    control={<Checkbox onChange={handleAutorisationEntrerSiteDatatable}  checked={row?.isAutorisationEntrerSite}  id={row.id} value="remember" color="primary" />}*/}
                {/*    label=""*/}
                {/*    // Autorisation entrer sur site.*/}
                {/*/>*/}
            </div>,
        },
        // {
        //     name: 'Commentaire',
        //     selector: row => row.commentaire,
        //     wrap:true,
        // },
        {
            name: '',
            wrap:true,
            button: true,
            cell: (row) => <div style={{ }}><div  onClick={ShowConfirmDelete} id={row.id} style={{fontSize:11, color: 'red'}}>Supprimer<Delete /></div></div>,}

    ]);
}else{
    columns = (( ShowConfirmDelete, handleAutorisationEntrerSiteDatatable) => [
        {
            name: 'Immatriculation',
            selector: row => row.immatriculation,
            sortable:true,
            wrap:true,
            style:{fontSize:16}
        },
        {
            name: 'Type',
            selector: row => row?.typevehicule?.type ,
            wrap:true,
            sortable:true,
        },
        {
            name: 'Marque',
            selector: row => row?.marque?.marque,
            wrap:true,
            sortable:true,
        },
        {
            name: 'Usager',
            selector: row => (row?.usager?.nom != undefined ? row?.usager?.nom : '') + " " + row?.usager?.prenom != undefined  ? row?.usager?.prenom : '' ,
            wrap:true,
            sortable:true,
        },
        {
            name: 'Email usager',
            selector: row => row?.usager?.email ,
            wrap:true,
            sortable:true,
        },
        {
            name: 'Liste blanche',
            wrap:true,
            sortable:false,
            cell: (row) => <div style={{ }}>
                {row?.isAutorisationEntrerSite?
                    <div  onClick={handleAutorisationEntrerSiteDatatable} id={row.id + "//" + row?.site?.nom + "//" + row?.immatriculation + "//" + row?.isAutorisationEntrerSite}  style={{fontSize:11, color: 'green'}}>
                        Autorisé <Check />
                    </div>
                    :
                    <div  onClick={handleAutorisationEntrerSiteDatatable} id={row.id + "//" + row?.site?.nom + "//" + row?.immatriculation + "//" + row?.isAutorisationEntrerSite}  style={{fontSize:11, color: 'red'}}>
                        Refusé <Close />
                    </div>
                }

                {/*<TextField*/}
                {/*    margin="normal"*/}
                {/*    required*/}
                {/*    fullWidth*/}
                {/*    id="listeblanche"*/}
                {/*    select*/}
                {/*    label=""*/}
                {/*    value={row?.isAutorisationEntrerSite==true ? "Autorisé" : "Refusé"}*/}
                {/*    onChange={handleAutorisationEntrerSiteDatatable}*/}
                {/*    //helperText="S'il vous plait choisissez le type de l'admin"*/}
                {/*    style={{width:100}}*/}
                {/*>*/}
                {/*    {["Autorisé", "Refusé"].map((option) => (*/}
                {/*        <MenuItem key={option} value={row?.isAutorisationEntrerSite==true  ? "Autorisé" : "Refusé"}>*/}
                {/*            {option}*/}
                {/*        </MenuItem>*/}
                {/*    ))}*/}
                {/*</TextField>*/}
                {/*<FormControlLabel*/}
                {/*    control={<Checkbox onChange={handleAutorisationEntrerSiteDatatable}  checked={row?.isAutorisationEntrerSite}  id={row.id} value="remember" color="primary" />}*/}
                {/*    label=""*/}
                {/*    // Autorisation entrer sur site.*/}
                {/*/>*/}
            </div>,
        },
        // {
        //     name: 'Commentaire',
        //     selector: row => row.commentaire,
        //     wrap:true,
        // },
        {
            name: '',
            wrap:true,
            button: true,
            cell: (row) => <div style={{ }}><div  onClick={ShowConfirmDelete} id={row.id} style={{fontSize:11, color: 'red'}}>Supprimer<Delete /></div></div>,}

    ]);
}





const usagersTables = [
    // {
    //     name: 'Choisir',
    //     selector: row => row.selected,
    //     cell: d => <span><FormControlLabel
    //         control={<Checkbox onChange={handleCheckItem} value={d.id} color="primary" />}
    //         label=""
    //     /></span>,
    //     sortable:true,
    //     style:{fontSize:16}
    // },
    {
        name: 'Nom & Prenoms',
        selector: row => row.nom + ' ' +row.prenom,
        cell: d => <span>{(d.nom != null ? d.nom : '') + ' ' + (d.prenom != null ? d.prenom : '')}</span>,
        wrap:true,
        sortable:true,
        style:{fontSize:16}
    },
    {
        name: 'Ville',
        selector: row => row.ville,
        wrap:true,
        sortable:true,
    },
    {
        name: 'Email',
        selector: row => row.email,
        wrap:true,
        sortable:true,
    },
    {
        name: 'Téléphone',
        selector: row => row.telephone,
        wrap:true,
    }
];


export const Vehicule = () => {
    const navigate = useNavigate();

    const [isshowmodalmodif, setshowmodalmodif] = useState(false);
    const [isLoadingSave, setLoadingSave] = useState(false);
    const [openSnack, setOpenSnack] = useState({ isOpenNomError:false, txt:''});
    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [tables, setTables] = React.useState([]);
    const [usagers, setUsagers] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);
    const [searchField, setSearchField] = useState("");
    const [searchFieldusager, setSearchFieldusager] = useState("");
    const [marque, setMarque] = useState("");
    const [marques, setMarques] = useState([]);
    const [type, setType] = useState("");
    const [types, setTypes] = useState([]);
    const [MarqueOnChange, setMarqueOnChange] = React.useState(null);
    const [TypeOnChange, setTypeOnChange] = React.useState(null);
    const [groupe, setGroupe] = useState("");
    const [groupes, setGroupes] = useState([]);
    const [GroupeOnChange, setGroupeOnChange] = React.useState(null);
    const [site, setSite] = useState("");
    const [sites, setSites] = useState([]);
    const [SiteOnChange, setSiteOnChange] = React.useState(null);
    const [selectedRowss, setSelectedRows] = React.useState(false);
    const [Immatriculation, setImmatriculation] = React.useState(false);
    const [idvehicule, setIdvehicule] = React.useState(null);
    const [isAutorisationEntrerSite, setIsAutorisationEntrerSite] = React.useState(true);

    const [idtosuppr, setIdToSuppr] = useState(null);
    const [idtolisteblanche, setIdToListeBlanche] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [opendropdownListeBlanche, setOpendropdownListeBlanche] = React.useState(false);
    const [valueDropdownListeBlanche, setValueDropdownListeBlanche] = React.useState("Autorisé");
    const [siteToListeBlanche, setSiteToListeBlanche] = React.useState("----");
    const [ImmatriculationToListeBlanche, setImmatriculationToListeBlanche] = React.useState("----");

    const ShowConfirmDelete = async (val) => {
        setIdToSuppr(val.target.id);
        setOpen(true);
    };
    const handleAutorisationEntrerSiteDatatable =  async (val) => {
        const arr = await (val.target.id).split("//");

        setIdToListeBlanche( arr[0]);
        setSiteToListeBlanche(arr[1]);
        setImmatriculationToListeBlanche(arr[2]);
        if(arr[3] == 'true'){
            setValueDropdownListeBlanche("Autorisé")
        }else{
            setValueDropdownListeBlanche("Refusé")
        }
        setOpendropdownListeBlanche(true);
    };

    function saveListeBlanche(e) {
        e.preventDefault();
        helpers
            .modifyVehicleListeBlanche({id: idtolisteblanche, valueDropdownListeBlanche:valueDropdownListeBlanche})
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    getListeVehicules().catch(console.error);
                    setOpendropdownListeBlanche(false);
                }
            })
    }

    const handleClose = () => {
        setOpen(false);
        setOpendropdownListeBlanche(false)
    };
    const suppression = async (e)=> {
        helpers
            .supprVehicule({id: idtosuppr})
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    getListeVehicules().catch(console.error);
                    setOpen(false);
                }
            })
    };

    const [Usager, setUsager] = React.useState(null);
    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
        setUsager(selectedRows[0])
    };

    useEffect(()=>{
        const filteredTables = tables && tables.filter(
            item => {
                if(item !== undefined)
                return (
                    (item.immatriculation !== null && item.immatriculation !== undefined ) && item.immatriculation
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    (item?.usager?.nom !== null && item?.usager?.nom !== undefined ) && item?.usager?.nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    (item?.usager?.prenom !== null && item?.usager?.prenom !== undefined )&& item?.usager?.prenom
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    (item?.usager?.email !== null && item?.usager?.email !== undefined ) && item?.usager?.email
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    (item?.typevehicule?.type !== null && item?.typevehicule?.type !== undefined) && item?.typevehicule?.type
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    (item?.typevehicule?.marque !== null && item?.typevehicule?.marque !== undefined) && item?.typevehicule?.marque
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    (item?.site?.groupe?.nom !== null && item?.site?.groupe?.nom !== undefined) && item?.site?.groupe?.nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    (item.site?.nom !== null && item.site?.nom !== undefined ) && item.site?.nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    (item?.commentaire !== null && item?.commentaire !== undefined)&& item?.commentaire
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                );
            }
        );
        if(searchField == '')
            getListeVehicules().catch(console.error);
        if(filteredTables !== undefined)
        setTables(filteredTables);
    },[searchField]);

    useEffect(()=>{
        const filteredTablesusager = usagers && usagers.filter(
            item => {
                return (
                    // (item.societe !== null && item.societe !== undefined) && item.societe
                    //     .toLowerCase()
                    //     .includes(searchFieldusager.toLowerCase()) ||
                    (item.nom !== null && item.nom !== undefined)  && item.nom
                        .toLowerCase()
                        .includes(searchFieldusager.toLowerCase()) ||
                    (item.prenom !== null && item.prenom !== undefined) && item.prenom
                        .toLowerCase()
                        .includes(searchFieldusager.toLowerCase()) ||
                    // (item.adresse !== null && item.adresse !== undefined) && item.adresse
                    //     .toLowerCase()
                    //     .includes(searchFieldusager.toLowerCase()) ||
                    (item.ville !== null && item.ville !== undefined) && item.ville
                        .toLowerCase()
                        .includes(searchFieldusager.toLowerCase()) ||
                    // (item.codepostal !== null && item.codepostal !== undefined) && item.codepostal
                    //     .toLowerCase()
                    //     .includes(searchFieldusager.toLowerCase()) ||
                    (item.telephone !== null && item.telephone !== undefined) && item.telephone
                        .toLowerCase()
                        .includes(searchFieldusager.toLowerCase()) ||
                    (item.email !== null && item.email !== undefined) && item.email
                        .toLowerCase()
                        .includes(searchFieldusager.toLowerCase())
                    // (item.commentaire !== null && item.commentaire !== undefined) && item.commentaire
                    //     .toLowerCase()
                    //     .includes(searchFieldusager.toLowerCase())
                );
            }
        );
        if(searchFieldusager === '')
            getUsagers().catch(console.error);
        if(filteredTablesusager !== undefined)
        setUsagers(filteredTablesusager);
    },[searchFieldusager]);

    function rechercheindb(){
        if(searchField != ''){
            return;
        }
        return;
    }

    function rechercheusagerindb(){
        if(searchFieldusager != ''){
            return;
        }
        return;
    }

    const getMarque = async () => {
        helpers
            .getMarques()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setMarques( r.data);
                }
            })
    };

    const getType = async () => {
        helpers
            .getTypes()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setTypes( r.data);
                }
            })
    };

    const getListeVehicules = async () => {
        helpers
            .getAllVehicules()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setTables( r.data);
                }
            })
    };

    const getGroupeToDropdown = async () => {
        helpers
            .getAllGroupesToDropdown()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setGroupes( r.data);
                }
            })
    };

    const getSitesToDropdown = async () => {
        helpers
            .getAllSitesToDropdown()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setSites( r.data);
                }
            })
    };


    useEffect( ()=>{
        async function init(){
            setLoading(true);
            await getListeVehicules().catch(console.error);
            await getUsagers().catch(console.error);
            await getGroupeToDropdown().catch(console.error);
            await getSitesToDropdown().catch(console.error);
            await getMarque().catch(console.error);
            await getType().catch(console.error);
        }
        init().catch(console.error);
    },[]);


    const modificationvehicule =(e)=> {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const commentaire = data.get('commentaire');

        if(Immatriculation.trim() == ''){
            setOpenSnack({...openSnack, isOpenNomError:true, txt:'Veuillez insérer l\'immatriculation' });
            return;
        }
        if(marque.trim() == ''){
            setOpenToast({
                isOpenToast: true,
                txt: 'Veuillez choisir ou ajouter la marque du véhicule.'
            });
            return;
        }
        if(type.trim() == ''){
            setOpenToast({
                isOpenToast: true,
                txt: 'Veuillez choisir ou ajouter le type de véhicule.'
            });
            return;
        }
        if(isAdmin === "AAAA" && groupe.trim() == ''){
            setOpenToast({
                isOpenToast: true,
                txt: 'Veuillez choisir ou ajouter le groupe.'
            });
            return;
        }
        if(isAdmin === "AAAA" && site.trim() == ''){
            setOpenToast({
                isOpenToast: true,
                txt: 'Veuillez choisir ou ajouter le site.'
            });
            return;
        }
        if(!Usager?.email){
            setOpenToast({
                isOpenToast: true,
                txt: 'Veuillez choisir un usager.'
            });
            return;
        }
        if(Usager?.email && Usager?.email.trim() === "" || Usager?.email === '----' ){
            setOpenToast({
                isOpenToast: true,
                txt: 'Veuillez choisir un usager.'
            });
            return;
        }
        setOpenToast({...openSnack, setOpenToast:false});

        setLoadingSave(true);
        let body = {
            immatriculation: Immatriculation.trim(),
            marque:  marque.trim(),
            type:  type.trim(),
            groupe:  groupe.trim(),
            site:  site.trim(),
            isAutorisationEntrerSite:isAutorisationEntrerSite,
            usagerId: Usager.id,
            id:idvehicule
            //commentaire: commentaire
        };

        if(MarqueOnChange != null && MarqueOnChange.label === marque)
            body.marqueid = MarqueOnChange.id;
        if(TypeOnChange != null && TypeOnChange.label === type)
            body.typeid = TypeOnChange.id;
        if(GroupeOnChange != null && GroupeOnChange.label === groupe)
            body.groupeid = GroupeOnChange.id;
        if(SiteOnChange != null && SiteOnChange.label === site)
            body.siteid = SiteOnChange.id;

        if(idvehicule != null)
        helpers
            .vehiculemodification(body)
            .then(async r => {
                setLoadingSave(false);
                if (r.status === 200) {
                    await getListeVehicules().catch(console.error);
                    await getUsagers().catch(console.error);
                    await getGroupeToDropdown().catch(console.error);
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    setshowmodalmodif(false);

                } else {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                }
            })
            .catch(e => {
                setLoadingSave(false);
            });
    };
    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

    const getUsagers = async () => {
        helpers
            .getAllUsagers()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setUsagers( r.data);
                }
            })
    };


    function handleAutorisationEntrerSite(value){
        setIsAutorisationEntrerSite(value.target.checked)
    }


    return (
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Chargement...'
        >
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Liste véhicule</h1>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        Véhicules
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <Link to="/dashboard"  className="link">
                                        Parc access
                                        </Link>
                                    </li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                        {/*<Link to="/dashboard/vehicule&usagers" style={{}} className="nav-link">*/}
                        <Link to="/dashboard/AjoutVehicule" style={{}} className="nav-link">
                            <button
                               // onClick={(e)=>newGroupes(e)}
                                className={"btn btn-success px-4"}><i className="fas fa-plus-circle mr-2" />Ajout</button>
                        </Link>
                    </div>{/* /.container-fluid */}
                </div>

                {/*le datatable*/}
                <div className="card">
                    <div className="card-body">
                        <Paper
                            component="form"
                            sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Rechercher"
                                inputProps={{ 'aria-label': 'Rechercher' }}
                                onChange={(e)=>{
                                    e.preventDefault();
                                    setSearchField(e.target.value);
                                }}
                                value={searchField}
                            />
                            <IconButton  onClick={() => {
                                rechercheindb()
                            }} type="button" sx={{ p: '5px' }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                        </Paper>


                        <DataTable
                            pagination
                            // selectableRowsComponent={Checkbox}
                            selectableRowsComponentProps={selectProps}
                            // sortIcon={sortIcon}
                            dense
                            //{...props}
                            //title="Administration"
                            // theme={'dark'}
                            columns={columns( ShowConfirmDelete, handleAutorisationEntrerSiteDatatable)}
                            data={tables}
                            // selectableRows
                            striped
                            highlightOnHover
                            persistTableHead
                            fixedHeader={false}
                            progressPending={false}
                            noDataComponent={"Aucune données à afficher"}
                            onRowClicked={(row, event) => {console.log('row clicked', row);
                            setImmatriculation(row?.immatriculation);
                            if(row?.marque?.marque)
                            setMarque(row?.marque?.marque);
                            if(row?.typevehicule?.type)
                            setType(row?.typevehicule?.type);
                            setGroupe(row?.site?.groupe?.nom || "");
                            setSite(row?.site?.nom || "");
                            setUsager(row?.usager || null);
                            setIdvehicule(row?.id);
                            setshowmodalmodif(true);
                            }}
                            // onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {console.log(' onChangePage')}}
                            // onChangePage={(page, totalRows)  => {console.log(' onChangePage')}}
                            // onRowMouseEnter={(row, event) => {console.log('row clicked')}}
                            // expandableRows={false}
                            // expandableRowsComponent={() => {console.log('row clicked')}}
                            paginationComponentOptions={{ rowsPerPageText: 'Lignes par page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                        />
                    </div>
                    {/*fin body*/}
                </div>

                <Modal
                    open={isshowmodalmodif}
                    onClose={()=>setshowmodalmodif(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className="modal-dialog ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Modification véhicule</h4>
                                <button onClick={()=>setshowmodalmodif(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <Box component="form" onSubmit={modificationvehicule} sx={{ mt: 1 }}
                                 Validate
                                 autoComplete="on"   >

                                <div   className="modal-body">
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="immatriculation"
                                        label="Immatriculation"
                                        name="immatriculation"
                                        autoFocus
                                        value={Immatriculation}
                                        onChange={(value)=>{setImmatriculation(value.target.value); }}
                                    />
                                    <div style={{ justifyContent:'flex-end', marginTop:10}} className="row">
                                    <Autocomplete
                                        margin="normal"
                                        id="combo-box-demoa"
                                        value={{label:marque}}
                                        options={
                                            marques.length > 0 ?
                                                marques
                                                :
                                                [
                                                    { label: 'vide', id: 1994 },
                                                ]
                                        }
                                        onChange={(value0, value1)=>{setMarque(value1.label); setMarqueOnChange(value1)}}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} key={params?.id}  value={marque} onChange={(value)=>{setMarque(value.target.value); }} label="Marque" />}
                                    />
                                    </div>
                                    <div style={{ justifyContent:'flex-end', marginTop:10}} className="row">
                                    <Autocomplete
                                        margin="normal"
                                        disablePortal
                                        id="combo-box-demo1"
                                        value={{label:type}}
                                        options={
                                            types.length > 0 ?
                                                types
                                                :
                                                [
                                                ]
                                        }
                                        onChange={(value0, value1)=>{setType(value1.label); setTypeOnChange(value1)}}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} value={type} onChange={(value)=>{setType(value.target.value); }} label="Type" />}
                                    />
                                    </div>
                                    {isAdmin === "AAAA" && <div style={{ justifyContent:'flex-end', marginTop:10}} className="row">
                                    <Autocomplete
                                        margin="normal"
                                        disablePortal
                                        id="combo-box-demo1"
                                        value={{label:groupe}}
                                        options={
                                            groupes.length > 0 ?
                                                groupes
                                                :
                                                []
                                        }
                                        onChange={(value0, value1)=>{setGroupe(value1.label); setGroupeOnChange(value1)}}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} value={groupe} onChange={(value)=>{setGroupe(value.target.value); }} label="Groupe" />}
                                    />
                                    </div>}
                                    {isAdmin === "AAAA" && <div style={{ justifyContent:'flex-end', marginTop:10}} className="row">
                                    <Autocomplete
                                        margin="normal"
                                        disablePortal
                                        id="combo-box-demo1"
                                        value={{label:site}}
                                        options={
                                            sites.length > 0 ?
                                                sites
                                                :
                                                []
                                        }
                                        onChange={(value0, value1)=>{setSite(value1.label); setSiteOnChange(value1)}}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} value={site} onChange={(value)=>{setSite(value.target.value); }} label="Site" />}
                                    />
                                    </div>}

                                    <div style={{ justifyContent:'flex-end', marginTop:10}} className="row">
                                    <FormControlLabel
                                        control={<Checkbox onChange={handleAutorisationEntrerSite}  checked={isAutorisationEntrerSite} value="remember" color="primary" />}
                                        label="Autorisation entrer sur site."
                                    />
                                    </div>

                                    <div style={{marginTop:10,justifyContent:'flex-start' }} className="row">
                                        <div style={{marginLeft:30, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">
                                            Usager: {Usager != null ?( Usager?.nom != undefined ? Usager?.nom: '') + ' ' + Usager?.prenom != undefined ? Usager?.prenom : '' : '----'}
                                        </div>
                                        <div style={{marginLeft:30, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">
                                            email: {Usager != null ? Usager?.email  : '----'}
                                        </div>
                                        <div style={{marginLeft:30, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">
                                            telephone: {Usager != null ? Usager?.telephone  : '----'}
                                        </div>
                                    </div>
                                    <div style={{ justifyContent:'flex-end', marginTop:10}} className="row">
                                        <Paper
                                            component="form"
                                            sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}
                                        >
                                            <InputBase
                                                sx={{ ml: 1, flex: 1 }}
                                                placeholder="Rechercher"
                                                inputProps={{ 'aria-label': 'Rechercher' }}
                                                onChange={(e)=>{
                                                    e.preventDefault();
                                                    setSearchFieldusager(e.target.value);
                                                }}
                                                value={searchFieldusager}
                                            />
                                            <IconButton  onClick={() => {
                                                rechercheusagerindb()
                                            }} type="button" sx={{ p: '5px' }} aria-label="search">
                                                <SearchIcon />
                                            </IconButton>
                                        </Paper>
                                        <DataTable
                                        pagination
                                        selectableRowsComponentProps={selectProps}
                                        dense
                                        columns={usagersTables}
                                        data={usagers}
                                        selectableRows
                                        striped
                                        highlightOnHover
                                        persistTableHead
                                        fixedHeader={false}
                                        progressPending={false}
                                        noDataComponent={"Aucune données à afficher"}
                                        onRowClicked={(row, event) => {console.log('row clicked', row)}}
                                        onSelectedRowsChange={handleChange}
                                        paginationComponentOptions={{ rowsPerPageText: 'Lignes par page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                                    />
                                    </div>
                                </div>
                                <div className="modal-footer d-flex flex-row justify-content-between">
                                    <button onClick={()=>setshowmodalmodif(false)} type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    <Button
                                        onPress = {modificationvehicule}
                                        type="submit"
                                        // fullWidth
                                        variant="contained"
                                        // sx={{ mt: 3, mb: 2 }}
                                        startIcon={
                                            isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                                : <SaveIcon fontSize="inherit" />
                                        }
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </Box>

                        </div>
                    </div>


                </Modal>
                <Snackbar
                    open={openToast.isOpenToast}
                    autoHideDuration={6000}
                    //onClose={()=>setOpenToast({...openToast, isOpenToast:false})}
                    message={openToast.txt}
                    action={<React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={(event, reason) => {
                                if (reason === 'clickaway') {
                                    return;
                                }
                                setOpenToast(false);
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>}
                />
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Suppression vehicule
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Voulez-vous vraiment supprimer?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button  onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button onClick={suppression}>Ok</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={opendropdownListeBlanche}
                onClose={handleClose}
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                   Liste blanche
                </DialogTitle>
                <div style={{marginTop:10, marginLeft:25, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">
                    Immatriculation: { ImmatriculationToListeBlanche}
                </div>
                <div style={{marginTop:10, marginLeft:25, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">
                    Site: { siteToListeBlanche}
                </div>
                <DialogContent>
                    <DialogContentText>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="listeblanche"
                            select
                            label=""
                            value={valueDropdownListeBlanche}
                            onChange={(event)=>{
                                setValueDropdownListeBlanche(event.target.value);
                            }}
                            //helperText="S'il vous plait choisissez le type de l'admin"
                            style={{width:200}}
                        >
                            {["Autorisé", "Refusé"].map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </DialogContentText>
                    <div style={{ justifyContent:'flex-end', marginTop:10}} className="row">
                        <button
                            onClick={(e)=>saveListeBlanche(e)}
                            className={"btn btn-info px-4"}><i className="fas fa-plus-circle mr-2" />Enregistrer</button>
                    </div>
                </DialogContent>
            </Dialog>
        </LoadingOverlay>
    )
};
