import React, {createContext, useEffect, useState} from 'react'
import TextField from "@mui/material/TextField/TextField";
import Box from "@mui/material/Box";
import {helpers} from "../../services/api/helpers";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/material/SvgIcon/SvgIcon";
import DataTable from "react-data-table-component";
import Collapse from "@mui/material/Collapse/Collapse";
import {Alert} from "../Authentication";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import Chip from "@mui/material/Chip";
import Modal from "@mui/material/Modal";
import Papa from "papaparse";
import ls from "local-storage";
let isAdmin = ls.get('xxxx');




const vehiculesColumn = [
    {
        name: 'Immatriculation',
        selector: row => row.immatriculation,
        sortable:true,
        wrap:true,
        style:{fontSize:16}
    },
    {
        name: 'Type',
        selector: row => row?.typevehicule?.type ,
        wrap:true,
        sortable:true,
    },
    {
        name: 'Marque',
        selector: row => row?.marque?.marque,
        wrap:true,
        sortable:true,
    },
    {
        name: 'Commentaire',
        selector: row => row.commentaire,
        wrap:true,
    }
];

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

const allowedExtensions = ["csv"];

const AjouterUsagers = (props) => {

    const [isShowVehicules, setShowVehicules] = React.useState(false);
    const [vehicule1, setVehicule1] = React.useState(null);
    const [vehicule2, setVehicule2] = React.useState(null);
    const [searchFieldvehicule, setSearchFieldvehicule] = React.useState(null);
    const [vehicules, setVehicules] = React.useState([]);
    const [groupe, setGroupe] = useState("");
    const [groupes, setGroupes] = useState([]);
    const [GroupeOnChange, setGroupeOnChange] = React.useState(null);
    const [site, setSite] = useState("");
    const [sites, setSites] = useState([]);
    const [SiteOnChange, setSiteOnChange] = React.useState(null);

    const handleShowVehicules = (value) => {
        setShowVehicules(value.target.checked)
    };
    const getGroupeToDropdown = async () => {
        helpers
            .getAllGroupesToDropdown()
            .then(async r => {
                if (r.status === 200) {
                    setGroupes( r.data);
                }
            })
    };

    const getSitesToDropdown = async () => {
        helpers
            .getAllSitesToDropdown()
            .then(async r => {
                if (r.status === 200) {
                    setSites( r.data);
                }
            })
    };
    useEffect(()=>{
        const filteredTables = vehicules && vehicules.filter(
            item => {
                if(item !== undefined)
                    return (
                        (item.immatriculation !== null && item.immatriculation !== undefined ) && item.immatriculation
                            .toLowerCase()
                            .includes(searchFieldvehicule.toLowerCase()) ||
                        // (item?.usager?.nom !== null && item?.usager?.nom !== undefined ) && item?.usager?.nom
                        //     .toLowerCase()
                        //     .includes(searchFieldvehicule.toLowerCase()) ||
                        // (item?.usager?.prenom !== null && item?.usager?.prenom !== undefined )&& item?.usager?.prenom
                        //     .toLowerCase()
                        //     .includes(searchFieldvehicule.toLowerCase()) ||
                        // (item?.usager?.email !== null && item?.usager?.email !== undefined ) && item?.usager?.email
                        //     .toLowerCase()
                        //     .includes(searchFieldvehicule.toLowerCase()) ||
                        (item?.typevehicule?.type !== null && item?.typevehicule?.type !== undefined) && item?.typevehicule?.type
                            .toLowerCase()
                            .includes(searchFieldvehicule.toLowerCase()) ||
                        (item?.typevehicule?.marque !== null && item?.typevehicule?.marque !== undefined) && item?.typevehicule?.marque
                            .toLowerCase()
                            .includes(searchFieldvehicule.toLowerCase()) ||
                        // (item?.site?.groupe?.nom !== null && item?.site?.groupe?.nom !== undefined) && item?.site?.groupe?.nom
                        //     .toLowerCase()
                        //     .includes(searchFieldvehicule.toLowerCase()) ||
                        // (item.site?.nom !== null && item.site?.nom !== undefined ) && item.site?.nom
                        //     .toLowerCase()
                        //     .includes(searchFieldvehicule.toLowerCase()) ||
                        (item?.commentaire !== null && item?.commentaire !== undefined)&& item?.commentaire
                            .toLowerCase()
                            .includes(searchFieldvehicule.toLowerCase())
                    );
            }
        );
        if(searchFieldvehicule === '')
            getVehicules().catch(console.error);
        if(filteredTables !== undefined)
            setVehicules(filteredTables);
    },[searchFieldvehicule]);

    function recherchevehiculeindb(){
        if(searchFieldvehicule != ''){
            return;
        }
        return;
    }

    const newUsager =(e)=> {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const societe = data.get('societe');
        const nom = data.get('nom');
        const prenom = data.get('prenom');
        const adresse = data.get('adresse');
        const ville = data.get('ville');
        const codepostal = data.get('codepostal');
        const telephone = data.get('telephone');
        const email = data.get('email');
        const isemail = validateEmail(email);
        if(!isemail){
            props.setOpenToast({
                isOpenToast: true,
                txt: 'Veuillez ajouter un e-mail valide.'
            });
            return;
        }

        if(isShowVehicules &&( vehicule1 === null || (vehicule1?.immatriculation && ( vehicule1?.immatriculation.trim() === "" || vehicule1?.immatriculation === '----'))) ){
            props.setOpenToast({
                isOpenToast: true,
                txt: 'Veuillez choisir au moins une véhicule.'
            });
            return;
        }
        props.setOpenToast({ isOpenToast: false, txt: '' });

        let dataX = {
            societe: societe,
            nom: nom,
            prenom: prenom,
            adresse: adresse,
            ville: ville,
            codepostal: codepostal,
            telephone: telephone,
            email: email,
            groupe:  groupe.trim(),
            site:  site.trim(),
        };
        if(vehicule1 != null)
            dataX.vehicule1 =  vehicule1;
        if(vehicule2 != null)
            dataX.vehicule2 =  vehicule2;
        if(GroupeOnChange != null && GroupeOnChange.label === groupe)
            dataX.groupeid = GroupeOnChange.id;
        if(SiteOnChange != null && SiteOnChange.label === site)
            dataX.siteid = SiteOnChange.id;

        props.setParentLoading(true);
        helpers
            .ajouterusager(dataX)
            .then(async r => {
                props.setParentLoading(false);
                if (r.status === 200) {
                    props.getUsagers().catch(console.error);
                    props.setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    props.setSwitchUsagers({target:{value:'Afficher'}})
                } else {
                    props.setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    props.setSwitchUsagers({target:{value:'Afficher'}})
                }
            })
            .catch(e => {
                props.setParentLoading(false);
                props.setSwitchUsagers({target:{value:'Afficher'}})
            });
    };

    const [selectedRowss, setSelectedRows] = React.useState(false);
    const [showModalUpload, setShowModalUpload] = React.useState(false);
    const [data, setData] = useState([]);
    // It will store the file uploaded by the user
    const [files, setFiles] = useState(null);

    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };
    const handleChange = ({ selectedRows }) => {
        if(vehicule1 != null && vehicule2 != null && vehicule1?.immatriculation == vehicule2?.immatriculation){
            setVehicule2(null);
            return;
        }
        else if(selectedRows.length===0 && vehicule1 && vehicule2)
        {
            setVehicule1(null);
            setVehicule2(null);

        }else if(selectedRows.length===0 && vehicule1 && !vehicule2)
        {
            setVehicule1(null);
            setVehicule2(null);

        }else if(selectedRows.length===1 && (vehicule2 !== null || (vehicule2 && vehicule2?.immatriculation.trim() !== "" && vehicule2?.immatriculation.trim() !== "----")) && vehicule1 )
        {
            setVehicule2(null);
        }else if((selectedRows.length===1  && (vehicule1 === null || (vehicule1 && vehicule1?.immatriculation.trim() === "" && vehicule1?.immatriculation.trim() === "----"))) || vehicule2 )
        {
            setVehicule1(selectedRows[0]);
        }else if(selectedRows.length===1 && (vehicule2 == null || (vehicule2 && vehicule2?.immatriculation.trim() === "" && vehicule2?.immatriculation.trim() === "----")) && vehicule1 )
        {
            setVehicule2(selectedRows[0]);
        }else if(selectedRows.length>1 && (vehicule2 == null || (vehicule2 && vehicule2?.immatriculation.trim() !== "" && vehicule2?.immatriculation.trim() !== "----")) && vehicule1 )
        {
            if(vehicule2 === null){
                setVehicule2(selectedRows[0]);
            }else
                setVehicule2(selectedRows[1]);
        }else  {
            setVehicule2(null);
            setVehicule1(null);
        }
        setSelectedRows(selectedRows);
    };

    const getVehicules = async () => {
        helpers
            .getAllVehicules()
            .then(async r => {
                props.setParentLoading(false);
                if (r.status === 200) {
                    setVehicules( r.data);
                }
            })
    };


    useEffect(async ()=>{
        props.setParentLoading(true);
        getVehicules().catch(console.error);
        await getGroupeToDropdown().catch(console.error);
        await getSitesToDropdown().catch(console.error);
    },[]);

    const uploadfiles = (e) => {
            e.preventDefault();
            setShowModalUpload(true)
    };


    const handleFileChange = async (e) => {
        if (e.target.files.length) {
            // const inputFile = e.target.files[0];
            const inputFiles = e.target.files;
            let arrayfiles = [];
            for await (const file_ of inputFiles){
                const fileExtension = file_?.type.split("/")[1];
                if (!allowedExtensions.includes(fileExtension)) {
                    props.setOpenToast({
                        isOpenToast: true,
                        txt: "Veuillez n'ajouter que des fichiers csv s'il vous plaît."
                    });
                    return;
                }
                arrayfiles.push(file_);
            }
            setFiles(inputFiles);
        }
    };

    const handleParse = async () => {
        var arrayfiles0 = [];
        var arrayfiles1 = [];
        for await (let file_0 of files) {
            if (!file_0) {
                props.setOpenToast({
                    isOpenToast: true,
                    txt: "Veuillez n'ajouter que des fichiers valides."
                });
                return;
            }
        }

        props.setParentLoading(true);

        // for (var i=0, file; file=files[i]; i++) {
         for await (let file_1 of files) {
       ////     await arrayfiles.push(file_1);
        var reader = new FileReader();
        await reader.readAsText(file_1, "text/plain");
        // //reader.onload = localLoaded;
         reader.onload = async ({target}) => {
            const csv = await Papa.parse(target.result, { header: true });
            // for(let j = 0; j < csv.data.length; j++){
                 for await (let csvdata of csv.data) {
                     if(csvdata.nom !== "" && csvdata.prenom){
                         await arrayfiles0.push(csvdata);
                         helpers
                             .sendUsagerByFiles(csvdata)
                             .then(async r => {
                                 props.setParentLoading(false);
                                 if (r.status === 200) {
                                     setShowModalUpload(false);
                                     props.setOpenToast({
                                         isOpenToast: true,
                                         txt: r.message
                                     });
                                 }
                             })
                     }
                 }
        };
        // //reader.onerror = localError;
         }

    };

    return (
        <div>
            <div style={{marginTop:15, marginLeft:25}} className="row justify-content-start">
                    <button
                    onClick = {uploadfiles}
                    className={"btn btn-success px-4"}><i className="fas fa-cloud mr-2" />Télécharger les listes des usagers en format csv</button>
            </div>
        <Box component="form" onSubmit={newUsager}  sx={{ mt: 1 }}
             Validate
             autoComplete="on"   >
        <div className="column" >
            <div style={{marginLeft:20}} className="row d-flex justify-content-end">
                {isAdmin === "AAAA" && <div style={{ justifyContent:'flex-end', marginTop:10}} className="row">
                    <Autocomplete
                        margin="normal"
                        disablePortal
                        id="combo-box-demo1"
                        value={{label:groupe}}
                        options={
                            groupes.length > 0 ?
                                groupes
                                :
                                []
                        }
                        onChange={(value0, value1)=>{setGroupe(value1.label); setGroupeOnChange(value1)}}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} value={groupe} onChange={(value)=>{setGroupe(value.target.value); }} label="Groupe" />}
                    />
                </div>}
                {isAdmin === "AAAA" && <div style={{ justifyContent:'flex-end', marginTop:10}} className="row">
                    <Autocomplete
                        margin="normal"
                        disablePortal
                        id="combo-box-demo1"
                        value={{label:site}}
                        options={
                            sites.length > 0 ?
                                sites
                                :
                                []
                        }
                        onChange={(value0, value1)=>{setSite(value1.label); setSiteOnChange(value1)}}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} value={site} onChange={(value)=>{setSite(value.target.value); }} label="Site" />}
                    />
                </div>}
            </div>
            <div style={{marginLeft:20}} className="row">
                <TextField
                    margin="normal"
                    required
                   fullWidth
                    id="societe"
                    label="Société"
                    name="societe"
                    //  autoComplete="nom"
                />
            </div>
            <div style={{marginLeft:20}} className="row">
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="nom"
                    label="Nom"
                    name="nom"
                    //  autoComplete="nom"
                />
            </div>
            <div style={{marginLeft:20}} className="row">
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="prenom"
                    label="Prénom"
                    name="prenom"
                    //  autoComplete="nom"
                />
            </div>
            <div style={{marginLeft:20}} className="row">
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="adresse"
                    label="Adresse"
                    name="adresse"
                    //  autoComplete="nom"
                />
            </div>
            <div style={{marginLeft:20}} className="row">
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="ville"
                    label="Ville"
                    name="ville"
                    //  autoComplete="nom"
                />
            </div>
            <div style={{marginLeft:20}} className="row">
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="codepostal"
                    label="Code postal"
                    name="codepostal"
                    //  autoComplete="nom"
                />
            </div>
            <div style={{marginLeft:20}} className="row">
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="telephone"
                    label="Téléphone"
                    name="telephone"
                    //  autoComplete="nom"
                />
            </div>
            <div style={{marginLeft:20}} className="row">
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="E-mail"
                    name="email"
                    type={'email'}
                    //  autoComplete="nom"
                />
            </div>

            <div style={{marginLeft:25}}><FormControlLabel
                control={<Checkbox onChange={handleShowVehicules} value="remember" color="primary" />}
                label="Ajouter des véhicules."
            /></div>

            {isShowVehicules && <div style={{  }} >
                <div style={{marginTop:10,justifyContent:'flex-start' }} className="row">
                    <div style={{marginLeft:30, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">
                        <span style={{color:'#3bc145'}}>Véhicule 1:&nbsp;</span>{' '}{vehicule1 != null ? vehicule1?.immatriculation : '----'},
                    </div>
                    <div style={{marginLeft:30, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">
                        marque: {vehicule1 != null ? vehicule1?.marque?.marque : '----'},
                    </div>
                    <div style={{marginLeft:30, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">
                        type: {vehicule1 != null ? vehicule1?.typevehicule?.type : '----'},
                    </div>
                    <div style={{marginLeft:30, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">
                        Usager actuel: {vehicule1 != null ? (vehicule1?.usager?.nom != undefined ? vehicule1?.usager?.nom : '' + ' ' + vehicule1?.usager?.prenom!= undefined ? vehicule1?.usager?.prenom : '' ) : '----'}
                    </div>
                </div>
                <div style={{marginTop:10,justifyContent:'flex-start' }} className="row">
                    <div style={{marginLeft:30, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">
                        <span style={{color:'#3bc145'}}>Véhicule 2:&nbsp;</span>{' '}{vehicule2 != null ? vehicule2?.immatriculation : '----'},
                    </div>
                    <div style={{marginLeft:30, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">
                        marque: {vehicule2 != null ? vehicule2?.marque?.marque : '----'},
                    </div>
                    <div style={{marginLeft:30, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">
                        type: {vehicule2 != null ? vehicule2?.typevehicule?.type : '----'},
                    </div>
                    <div style={{marginLeft:30, fontSize:16, fontWeight:'bold', color: '#363636', }} className="row">
                        Usager actuel: {vehicule2 != null ? (vehicule2?.usager?.nom != undefined ? vehicule2?.usager?.nom : '' + ' ' + vehicule2?.usager?.prenom!= undefined ? vehicule2?.usager?.prenom : '' ) : '----'}
                    </div>
                </div>
                <div style={{ justifyContent:'flex-end', marginTop:10}} className="row">
                    <Paper
                        component="form"
                        sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Rechercher"
                            inputProps={{ 'aria-label': 'Rechercher' }}
                            onChange={(e)=>{
                                e.preventDefault();
                                setSearchFieldvehicule(e.target.value);
                            }}
                            value={searchFieldvehicule}
                        />
                        <IconButton  onClick={() => {
                            recherchevehiculeindb()
                        }} type="button" sx={{ p: '5px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                    <DataTable
                        pagination
                        selectableRowsComponentProps={selectProps}
                        dense
                        columns={vehiculesColumn}
                        data={vehicules}
                        selectableRows
                        striped
                        highlightOnHover
                        persistTableHead
                        fixedHeader={false}
                        progressPending={false}
                        noDataComponent={"Aucune données à afficher"}
                        onRowClicked={(row, event) => {console.log('row clicked', row)}}
                        onSelectedRowsChange={handleChange}
                        paginationComponentOptions={{ rowsPerPageText: 'Lignes par page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                    />
                </div>
            </div>}

            <div style={{marginTop:15}} className="row justify-content-end">
                <button
                    onPress = {newUsager}

                   // onClick={newUsager}
                    className={"btn btn-success px-4"}><i className="fas fa-plus-circle mr-2" />Enregistrer Usager</button>
            </div>
        </div>
        </Box>
            <Modal
                open={showModalUpload}
                onClose={()=>setShowModalUpload(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description" >
                <div className="modal-dialog ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Ajouter des fichiers CSV</h4>
                            <button onClick={()=>setShowModalUpload(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div style={{marginLeft:30}}>
                            {/*<label htmlFor="csvInput" style={{ display: "block" }}>*/}
                            {/*    Ajouter des fichiers CSV*/}
                            {/*</label>*/}
                            <div style={{  marginTop: "3rem" }}>
                                <input
                                    onChange={handleFileChange}
                                    id="csvInput"
                                    name="file"
                                    type="File"
                                    multiple
                                />
                            </div>
                            <div style={{marginTop: "3rem"}}>
                                <button
                                    onClick = {handleParse}
                                    className={"btn btn-success px-4"}><i className="fas fa-plus-circle mr-2" />Envoyer</button>
                            </div>
                            <div style={{ marginTop: "3rem" }}>
                                {/*{data.length > 0 && data.map((col,*/}
                                {/*                           idx) => <div key={idx}>{col}</div>)}*/}
                                {/*{JSON.stringify(data.data[0])}*/}
                                {/*{data?.data.length > 0 && data?.data.map((item, idx) => <div key={idx}>{JSON.stringify(item)}</div>)}*/}
                            </div>
                        </div>

                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default AjouterUsagers;
