import React, { Component } from 'react'
import {Link} from "react-router-dom";
import ls from "local-storage";
let isAdmin = ls.get('xxxx');

export default class Menu extends Component {

  render() {
    isAdmin = ls.get('xxxx');
    const user_ =  JSON.parse( ls.get('user'));

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
          {/* Brand Logo */}
          <a href="/dashboard" className="brand-link">
            <img src={require("../assets/logo-1002x1024.png").default} alt="Mi Parc access Logo" className="brand-image img-circle elevation-3" style={{opacity: '.8'}} />
            <span className="brand-text font-weight-light">Mi Parc Access</span>
          </a>
          {/* Sidebar */}
          <div className="sidebar">
            {/* Sidebar user panel (optional) */}
            <div className="user-panel mt-3 pb-3 mb-3 d-flex">
              <div className="info">
                <div  style={{ color:'#c2c7d0',cursor:"pointer"}} className="link">
                  {user_ != null && user_.nom}
                </div>
              </div>
            </div>
      {/* Sidebar Menu */}
      <nav className="mt-2">
        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          {/* Add icons to the links using the .nav-icon class
           with font-awesome or any other icon font library */}
          <li className="nav-header">CONFIGURATION</li>
          {isAdmin === "AAAA" && <li className="nav-item">
            <a href="/dashboard/groupe" style={{}} className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Groupes(Villes)</p>
            </a>
          </li>}
          {isAdmin === "AAAA" && <li className="nav-item">
            <a href="/dashboard/sites" style={{}} className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Sites</p>
            </a>
          </li>}

          {/*<li className="nav-item">*/}
          {/*  <Link to="/dashboard/ajoutVehicule" style={{}} className="nav-link">*/}
          {/*    <i className="far fa-circle nav-icon" />*/}
          {/*    <p>Ajout véhicule</p>*/}
          {/*  </Link>*/}
          {/*</li>*/}

          {/*<li className="nav-item">*/}
          {/*  <Link to="/dashboard/vehicule&usagers" style={{}} className="nav-link">*/}
          {/*    <i className="far fa-circle nav-icon" />*/}
          {/*    <p>Ajout véhicule & usagers</p>*/}
          {/*  </Link>*/}
          {/*</li>*/}
          <li className="nav-item">
            <a href="/dashboard/vehicules" style={{}} className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Liste véhicule</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/dashboard/usagers" style={{}} className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Usagers</p>
            </a>
          </li>
          <li className="nav-item">
            <a href="/dashboard/entrersortie" style={{}} className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Entrer sur site</p>
            </a>

          </li>
          <li className="nav-item">
            <a href="/dashboard/plagehoraire" style={{}} className="nav-link">
              <i className="far fa-circle nav-icon" />
              <p>Ajouter plage horaire</p>
            </a>
          </li>
          <li className="nav-item has-treeview menu-open">
            <div className="nav-link ">
              {/*active*/}
              <i className="nav-icon fas fa-tachometer-alt"></i>
              <p>
                Rapport
                <i className="right fas fa-angle-left"></i>
              </p>
            </div>
            <li className="nav-item">
              <a href="/dashboard/rapport" style={{}} className="nav-link">
                <i className="far fa-circle nav-icon" />
                <p>Liste des véhicules entrant</p>
              </a>
            </li>
            {isAdmin === "AAAA" && <li className="nav-item">
              <a href="/dashboard/rapportpargroupe" style={{}} className="nav-link">
                <i className="far fa-circle nav-icon" />
                <p>Liste des groupes véhicules entrant</p>
              </a>
            </li>}
          </li>
        </ul>
      </nav>
      {/* /.sidebar-menu */}
    </div>
    {/* /.sidebar */}
  </aside>
        )
    }
}
