import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField/TextField";
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import {Alert} from "../Authentication/index";
import SaveIcon from '@mui/icons-material/Save';
import FormControlLabel from '@mui/material/FormControlLabel';
import {helpers} from "../../services/api/helpers";
import ls from "local-storage";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import {ThemeProvider} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import LoadingOverlay from "react-loading-overlay";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import { useLocation, useNavigate } from "react-router-dom"




const columns = ((clickHandler,clickSite) => [
    {
        name: 'Nom site',
        selector: row => row.nom,
        sortable:true,
        wrap:true,
        style:{fontSize:16},
        cell: (row) => <div style={{flexDirection:'row', alignItems:'center'}}><Button className={"btn btn-success px-1"} onClick={(row)=>clickSite(row)} id={row.id} style={{fontSize:11}}>{row.nom}</Button><span style={{paddingTop:3, paddingBottom:3, paddingLeft:5, paddingRight:5, backgroundColor:'#75ff42', borderRadius:10}}>{row.soussites.length}</span></div>,
    },
    {
        name: 'Nom du groupe',
        selector: row => row?.groupe?.nom,
        wrap:true,
        sortable:true,
    },
    {
        name: 'Adresse groupe',
        selector: row => row?.groupe?.adresse,
        wrap:true,
        sortable:true,
    },
    {
        name: 'Commentaire',
        selector: row => row.commentaire,
        wrap:true,
    },
    {
        name: 'Véhicule',
        //selector: row => "Bonjour",
        wrap:true,
        button: true,
        // cell: (row) => <Button className={"btn btn-success px-4"} onClick={(row)=>clickHandler(row)} id={row.id} ><i className="fas fa-car mr-2 fa-2x" /></Button>,
        // cell: (row) => <Button className={"btn btn-success px-4"} onClick={(row)=>clickHandler(row)} id={row.id} >zeze</Button>,
        cell: (row) => <div><span style={{paddingTop:4, paddingBottom:4, paddingLeft:6, paddingRight:6, backgroundColor:'#75ff42', borderRadius:10}}>{row?.vehicules.length}</span><Button className={"btn btn-success px-2"} onClick={(row)=>clickHandler(row)} id={row.id} style={{fontSize:11}}>Véhicules</Button></div>,


    }
]);


export const SiteParGroupe = ({idgroupe}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [isshowmodal, setshowmodal] = useState(false);
    const [isshowmodalmodif, setshowmodalmodif] = useState(false);
    const [isLoadingSave, setLoadingSave] = useState(false);
    const [openSnack, setOpenSnack] = useState({
        isOpenNomError:false,
        isOpenGroupeError:false,
        txt:''});
    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [tables, setTables] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);
    const [searchField, setSearchField] = useState("");
    const [groupes, setGroupes] = useState([]);
    const [groupe, setGroupe] = useState("");
    const [GroupeOnChange, setGroupeOnChange] = useState(null);
    const [nommodif, setnommodif] = useState("");
    const [commentairemodif, setcommentairemodif] = useState("");
    const [idsite, setidsite] = useState("");


    const handleButtonColumn = (val) => {
        const from =  `/dashboard/vehiculeparsite/${val.target.id}`;
        navigate(from, { replace: true })
    };
    const handleClickSite = (val) => {
        const from =  `/dashboard/soussite/${val.target.id}`;
        navigate(from, { replace: true })
    };
    useEffect(()=>{
        const filteredTables = tables && tables.filter(
            item => {
                return (
                    item.nom !== null && item.nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.groupe?.nom !== null && item.groupe?.nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.groupe?.adresse !== null && item.groupe?.adresse
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.commentaire !== null && item.commentaire
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                );
            }
        );
        if(searchField == '')
            getSites().catch(console.error);
        if(filteredTables !== undefined)
            setTables(filteredTables);
    },[searchField]);

    function rechercheindb(){
        if(searchField != ''){
            return;
        }
        return;
    }

    function newGroupes(e) {
        e.preventDefault();
        setshowmodal(true);
    }

    const getSites = async () => {
        helpers
            .getAllSitesParGroupe(idgroupe)
            .then(async r => {
                console.log('res',r);
                setLoading(false);
                if (r.status === 200) {
                    setTables( r.data);
                }
            })
    };
    const getGroupeToDropdown = async () => {
        helpers
            .getAllGroupesToDropdown()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setGroupes( r.data);
                }
            })
    };

    useEffect(()=>{
        setLoading(true);
        getSites().catch(console.error);
        getGroupeToDropdown().catch(console.error);
    },[]);



    const savenewSite =(e)=> {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const nom = data.get('nom');
        const commentaire = data.get('commentaire');

        if(nom == ''){
            setOpenSnack({...openSnack, isOpenNomError:true,txt:'Veuillez insérer le nom du site'});
            return;
        }
        setOpenSnack({...openSnack, isOpenNomError:false});
        if(groupe == ''){
            setOpenSnack({...openSnack, isOpenGroupeError:true,txt:'Veuillez choisir ou ajouter un groupe.'});
            return;
        }
        setOpenSnack({...openSnack, isOpenGroupeError:false});
        setLoadingSave(true);
        let dataX = {
            nom: nom,
            groupe: groupe,
            commentaire: commentaire
        };

        if(GroupeOnChange != null && GroupeOnChange.label === groupe)
            dataX.groupeid = GroupeOnChange.id;

        helpers
            .sitesave(dataX)
            .then(async r => {
                setLoadingSave(false);
                if (r.status === 200) {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setshowmodal(false);
                } else {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                }
            })
            .catch(e => {
                setLoadingSave(false);
            });
    };
    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

    const modificationSite =(e)=> {
        e.preventDefault();

        if(nommodif.trim() == ''){
            setOpenSnack({...openSnack, isOpenNomError:true,txt:'Veuillez insérer le nom du site'});
            return;
        }
        setOpenSnack({...openSnack, isOpenNomError:false});
        if(groupe == ''){
            setOpenSnack({...openSnack, isOpenGroupeError:true,txt:'Veuillez choisir ou ajouter un groupe.'});
            return;
        }
        setOpenSnack({...openSnack, isOpenGroupeError:false});
        setLoadingSave(true);

        let dataX = {
            nom: nommodif.trim(),
            groupe: groupe,
            commentaire: commentairemodif,
            id: idsite
        };

        if(GroupeOnChange != null && GroupeOnChange.label === groupe)
            dataX.groupeid = GroupeOnChange.id;

        helpers
            .modificationsite(dataX)
            .then(async r => {
                setLoadingSave(false);
                if (r.status === 200) {
                    await getSites().catch(()=>null);
                    await getGroupeToDropdown().catch(()=>null);
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setshowmodalmodif(false);

                } else {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                }
            })
            .catch(e => {
                setLoadingSave(false);
            });


    };


    return (
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Chargement...'
        >
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Sites par groupes {tables && tables.length>0 ? ' (' + tables[0]?.groupe?.nom + ')' : ''}</h1>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        Sites par groupes
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <Link to="/dashboard"  className="link">
                                        Parc access
                                        </Link>
                                    </li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                        {/*<button*/}
                        {/*    onClick={(e)=>newGroupes(e)}*/}
                        {/*    className={"btn btn-success px-4"}><i className="fas fa-plus-circle mr-2" />Ajout</button>*/}
                    </div>{/* /.container-fluid */}
                </div>

                {/*le datatable*/}
                <div className="card">
                    <div className="card-body">
                        <Paper
                            component="form"
                            sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Rechercher"
                                inputProps={{ 'aria-label': 'Rechercher' }}
                                onChange={(e)=>{
                                    e.preventDefault();
                                    setSearchField(e.target.value);
                                }}
                                value={searchField}
                            />
                            <IconButton  onClick={() => {
                                rechercheindb()
                            }} type="button" sx={{ p: '5px' }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                        </Paper>


                        <DataTable
                            pagination
                            // selectableRowsComponent={Checkbox}
                            selectableRowsComponentProps={selectProps}
                            // sortIcon={sortIcon}
                            dense
                            //{...props}
                            //title="Administration"
                            // theme={'dark'}
                            //columns={columns}
                            columns={columns(handleButtonColumn, handleClickSite)}

                            data={tables}
                            // selectableRows
                            striped
                            highlightOnHover
                            persistTableHead
                            fixedHeader={false}
                            progressPending={false}
                            noDataComponent={"Aucune données à afficher"}
                            onRowClicked={(row, event) => {console.log('row clicked', row);
                                setnommodif(row?.nom);
                                setGroupe(row?.groupe?.nom || "");
                                setcommentairemodif(row?.commentaire);
                                setshowmodalmodif(true);
                                setidsite(row?.id);
                            }}
                            // onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {console.log(' onChangePage')}}
                            // onChangePage={(page, totalRows)  => {console.log(' onChangePage')}}
                            // onRowMouseEnter={(row, event) => {console.log('row clicked')}}
                            // expandableRows={false}
                            // expandableRowsComponent={() => {console.log('row clicked')}}
                            paginationComponentOptions={{ rowsPerPageText: 'Lignes par page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                        />
                    </div>
                    {/*fin body*/}
                </div>
                <Modal
                    open={isshowmodal}
                    onClose={()=>setshowmodal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description" >
                    <div className="modal-dialog ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Nouveau site</h4>
                                <button onClick={()=>setshowmodal(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <Box component="form" onSubmit={savenewSite} sx={{ mt: 1 }}
                                 Validate
                                 autoComplete="on"   >
                                <div   className="modal-body">
                                    <TextField
                                        margin="normal"
                                        //  required
                                        fullWidth
                                        id="nom"
                                        label="Nom"
                                        name="nom"
                                        autoComplete="nom"
                                        autoFocus
                                    />
                                    <Collapse in={openSnack.isOpenNomError}>
                                        <Alert severity="error" action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setOpenSnack({...openSnack, isOpenNomError:false});
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }>{openSnack.txt}</Alert>
                                    </Collapse>
                                    <div style={{ justifyContent:'flex-end', marginTop:10, marginRight:5}} className="row">
                                        <Autocomplete
                                            margin="normal"
                                            disablePortal
                                            id="combo-box-demo1"
                                            value={{label:groupe}}
                                            options={
                                                groupes.length > 0 ?
                                                    groupes
                                                    :
                                                    [
                                                        //  { label: 'vide', id: 1994 },
                                                    ]
                                            }
                                            onChange={(value0, value1)=>{setGroupe(value1.label); setGroupeOnChange(value1)}}
                                            sx={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params} value={groupe} onChange={(value)=>{setGroupe(value.target.value); }} label="Groupe" />}
                                        />
                                    </div>
                                    <Collapse in={openSnack.isOpenGroupeError}>
                                        <Alert severity="error" action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setOpenSnack({...openSnack, isOpenGroupeError:false});
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }>{openSnack.txt}</Alert>
                                    </Collapse>
                                    <TextField
                                        margin="normal"
                                        //    required
                                        fullWidth
                                        id="commentaire"
                                        label="Commentaire"
                                        name="commentaire"
                                        autoComplete="commentaire"
                                        autoFocus
                                    />


                                </div>
                                <div className="modal-footer d-flex flex-row justify-content-between">
                                    <button onClick={()=>setshowmodal(false)} type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    <Button
                                        onPress = {savenewSite}
                                        type="submit"
                                        // fullWidth
                                        variant="contained"
                                        // sx={{ mt: 3, mb: 2 }}
                                        startIcon={
                                            isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                                : <SaveIcon fontSize="inherit" />
                                        }
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </Box>

                        </div>
                    </div>
                </Modal>
                <Modal
                    open={isshowmodalmodif}
                    onClose={()=>setshowmodalmodif(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description" >
                    <div className="modal-dialog ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Modification site</h4>
                                <button onClick={()=>setshowmodalmodif(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <Box component="form" onSubmit={modificationSite} sx={{ mt: 1 }}
                                 Validate
                                 autoComplete="on"   >
                                <div   className="modal-body">
                                    <TextField
                                        margin="normal"
                                        //  required
                                        fullWidth
                                        id="nom"
                                        label="Nom"
                                        name="nom"
                                        autoComplete="nom"
                                        autoFocus
                                        value={nommodif}
                                        onChange={(e)=>setnommodif(e.target.value)}
                                    />
                                    <Collapse in={openSnack.isOpenNomError}>
                                        <Alert severity="error" action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setOpenSnack({...openSnack, isOpenNomError:false});
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }>{openSnack.txt}</Alert>
                                    </Collapse>
                                    <div style={{ justifyContent:'flex-end', marginTop:10, marginRight:5}} className="row">
                                        <Autocomplete
                                            margin="normal"
                                            disablePortal
                                            id="combo-box-demo1"
                                            value={{label:groupe}}
                                            options={
                                                groupes.length > 0 ?
                                                    groupes
                                                    :
                                                    []
                                            }
                                            onChange={(value0, value1)=>{setGroupe(value1.label); setGroupeOnChange(value1)}}
                                            sx={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params} value={groupe} onChange={(value)=>{setGroupe(value.target.value); }} label="Groupe" />}
                                        />
                                    </div>
                                    <Collapse in={openSnack.isOpenGroupeError}>
                                        <Alert severity="error" action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setOpenSnack({...openSnack, isOpenGroupeError:false});
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }>{openSnack.txt}</Alert>
                                    </Collapse>
                                    <TextField
                                        margin="normal"
                                        //    required
                                        fullWidth
                                        id="commentaire"
                                        label="Commentaire"
                                        name="commentaire"
                                        autoComplete="commentaire"
                                        value={commentairemodif}
                                        onChange={(e)=>setcommentairemodif(e.target.value)}
                                    />
                                </div>
                                <div className="modal-footer d-flex flex-row justify-content-between">
                                    <button onClick={()=>setshowmodalmodif(false)} type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    <Button
                                        onPress = {modificationSite}
                                        type="submit"
                                        // fullWidth
                                        variant="contained"
                                        // sx={{ mt: 3, mb: 2 }}
                                        startIcon={
                                            isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                                : <SaveIcon fontSize="inherit" />
                                        }
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </Box>

                        </div>
                    </div>
                </Modal>
                <Snackbar
                    open={openToast.isOpenToast}
                    autoHideDuration={6000}
                    //onClose={()=>setOpenToast({...openToast, isOpenToast:false})}
                    message={openToast.txt}
                    action={<React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={(event, reason) => {
                                if (reason === 'clickaway') {
                                    return;
                                }
                                setOpenToast(false);
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>}
                />
            </div>
        </LoadingOverlay>
    )
};
