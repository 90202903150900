import React, { createContext, useState} from 'react'
import TextField from "@mui/material/TextField/TextField";
import Box from "@mui/material/Box";
import {helpers} from "../../services/api/helpers";
import Button from "@mui/material/Button";



const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

const AjouterUsagers = (props) => {


    const newUsager =(e)=> {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const societe = data.get('societe');
        const nom = data.get('nom');
        const prenom = data.get('prenom');
        const adresse = data.get('adresse');
        const ville = data.get('ville');
        const codepostal = data.get('codepostal');
        const telephone = data.get('telephone');
        const email = data.get('email');
        const isemail = validateEmail(email);
        console.log('is eeemmil', isemail)
        let dataX = {
            societe: societe,
            nom: nom,
            prenom: prenom,
            adresse: adresse,
            ville: ville,
            codepostal: codepostal,
            telephone: telephone,
            email: email
        };
        console.log('data x', dataX);
        props.setParentLoading(true);
        helpers
            .ajouterusager(dataX)
            .then(async r => {
                props.setParentLoading(false);
                if (r.status === 200) {
                    props.getUsagers().catch(console.error);
                    props.setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ props.setOpenToast({ isOpenToast: false  }); },6000);

                    props.setSwitchUsagers({target:{value:'Afficher'}})
                } else {
                    props.setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ props.setOpenToast({ isOpenToast: false  }); },6000);
                    props.setSwitchUsagers({target:{value:'Afficher'}})
                }
            })
            .catch(e => {
                props.setParentLoading(false);
                props.setSwitchUsagers({target:{value:'Afficher'}})
            });
    };

    return (
        <Box style={{backgroundColor:'white',}} component="form" onSubmit={newUsager}  sx={{ mt: 1 }}
             Validate
             autoComplete="on"   >
        <div className="column">
            <div style={{marginLeft:20}} className="row">
                <TextField
                    margin="normal"
                    //required
                   fullWidth
                    id="societe"
                    label="Société"
                    name="societe"
                    //  autoComplete="nom"
                />
            </div>
            <div style={{marginLeft:20}} className="row">
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="nom"
                    label="Nom"
                    name="nom"
                    //  autoComplete="nom"
                />
            </div>
            <div style={{marginLeft:20}} className="row">
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="prenom"
                    label="Prénom"
                    name="prenom"
                    //  autoComplete="nom"
                />
            </div>
            <div style={{marginLeft:20}} className="row">
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="adresse"
                    label="Adresse"
                    name="adresse"
                    //  autoComplete="nom"
                />
            </div>
            <div style={{marginLeft:20}} className="row">
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="ville"
                    label="Ville"
                    name="ville"
                    //  autoComplete="nom"
                />
            </div>
            <div style={{marginLeft:20}} className="row">
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="codepostal"
                    label="Code postal"
                    name="codepostal"
                    //  autoComplete="nom"
                />
            </div>
            <div style={{marginLeft:20}} className="row">
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="telephone"
                    label="Téléphone"
                    name="telephone"
                    //  autoComplete="nom"
                />
            </div>
            <div style={{marginLeft:20}} className="row">
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="E-mail"
                    name="email"
                    //  autoComplete="nom"
                />
            </div>
            <div style={{marginTop:15}} className="row justify-content-end">
                <button
                    onPress = {newUsager}

                   // onClick={newUsager}
                    className={"btn btn-success px-4"}><i className="fas fa-plus-circle mr-2" />Enregistrer Usager</button>
            </div>
        </div>
        </Box>
    );
}

export default AjouterUsagers;
