import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import App from './App';
import reportWebVitals from './reportWebVitals';
import { StyleRoot } from "radium"
import StoreProvider from "./contexts/application/Provider";
import {Authentication} from "./newScreen/Authentication";
import Dashboard from "./newScreen/centre/Dashboard";
import Usagers from "./newScreen/usagers/Usagers";
import { BrowserRouter as Router, Routes, Route, Link, HashRouter  } from "react-router-dom";
import Admin from "./newScreen/admin/Admin";
import Groupe from "./newScreen/groupes/Groupes";
import VehiculeUsagers from "./newScreen/VehiculeUsagers/VehiculeUsagers";
import ListerVehicule from "./newScreen/listerVehicule/ListerVehicule";
import Site from "./newScreen/sites/Site";
import Soussite from "./newScreen/soussite/Soussite";
import ListerVehiculeParSite from "./newScreen/listerVehiculeParSite/ListerVehiculeParSite";
import ListerSiteParGroupe from "./newScreen/ListerSiteParGroupe/ListerSiteParGroupe";
import EntrerSortie from "./newScreen/entrersortie/EntrerSortie";
import AjoutVehicule from "./newScreen/AjoutVehicule/AjoutVehicule";
import Plagehoraire from "./newScreen/plagehoraire/plagehoraire";
import Rapport from "./newScreen/rapport/Rapport";
import Rapportpargroupe from "./newScreen/rapportpargroupe/Rapport";


const PrivateRouters = ()  => {
    return (
        <Routes>
            <Route index element={<Dashboard />} />
            <Route path="/usagers" element={<Usagers />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/sites" element={<Site />} />
            <Route path="/vehicule&usagers" element={<VehiculeUsagers />} />
            <Route path="/vehicules" element={<ListerVehicule />} />
            <Route path="/groupe" element={<Groupe />} />
            <Route path="/vehiculeparsite/:id" element={<ListerVehiculeParSite />} />
            <Route path="/soussite/:id" element={<Soussite />} />
            <Route path="/sitespargroupe/:id" element={<ListerSiteParGroupe />} />
            <Route path="/entrersortie" element={<EntrerSortie />} />
            <Route path="/ajoutVehicule" element={<AjoutVehicule />} />
            <Route path="/plagehoraire" element={<Plagehoraire />} />
            <Route path="/rapport" element={<Rapport />} />
            <Route path="/rapportpargroupe" element={<Rapportpargroupe />} />
            {/*/!*<Route path="/account" element={<NotFound />} />*!/*/}
        </Routes>
    )
};

ReactDOM.render(
    <React.StrictMode>
        <Router >
            <Routes >
                <Route path="/*" element={<Authentication />} />
                 <Route
                    path="/dashboard/*"
                    element={
                        <PrivateRouters />
                    }
                /> 
            </Routes>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
  );
  
  reportWebVitals();
  