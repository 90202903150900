import React, {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
// import LoadingButton from '@mui/lab/LoadingButton';
import LoginIcon from '@mui/icons-material/Login';
import LoadingOverlay from 'react-loading-overlay';
// import ReactLoading from 'react-loading';
import {helpers} from '../../services/api/helpers'
import Modal from '@mui/material/Modal';
import { useLocation, useNavigate, Navigate    } from "react-router-dom"
import ls from 'local-storage'

const theme = createTheme();

export const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Authentication =()=> {
    const navigate = useNavigate();
    const [openSnack, setOpenSnack] = useState({isOpenEmailError:false,isOpenPasswordError:false, txt:''});
    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBtnRecupPass, setIsLoadingBtnRecupPass] = useState(false);
    const [isSeSouvenir, setIsSeSouvenir] = useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [passwordd, setPassword] = useState('');
    const [emaill, setEmail] = useState('');

    useEffect(() => {
        const localstorage = async () => {
            const user_ = await JSON.parse(await ls.get('user'));
            if(user_ && user_.isSeSouvenir){
                setPassword(user_.password);
                setEmail(user_.email);
            }
            // , JSON.stringify({email, password, isSeSouvenir}
        };
        localstorage().catch(console.error);
    },[]);
    const handleSeSouvenir = (value) => {
        setIsSeSouvenir(value.target.checked)
    };
    const handleSubmit = (event) => {

        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get('email');
        const password = data.get('password');
        //navigate("dashboard", { replace: true });////////////////////////////////

        setIsLoading(true);

        if(email == ''){
            setOpenSnack({isOpenEmailError:true,txt:'Veuillez insérer votre e-mail'});
            setIsLoading(false);
            return;
        }
        setOpenSnack({...openSnack, isOpenEmailError:false});
        if( password == ''){
            setOpenSnack({isOpenPasswordError:true,txt:'Veuillez insérer votre mot de passe'});
            setIsLoading(false);
            return;
        }
        setOpenSnack({...openSnack, isOpenPasswordError:false});

        helpers
            .login({
                userlogin: email,
                password: password
            })
            .then(async r => {
                setIsLoading(false);
                if (r.status === 200) {
                    const nom = r.data[0].nom;
                    await ls.set('userToken', r.userToken);
                    if(r.data[0]?.site)
                    await ls.set('user', JSON.stringify({email, password, isSeSouvenir, nom:nom, site: r.data[0]?.site.nom,  groupe: r.data[0]?.site?.groupe.nom}));
                    else if(r.data[0]?.site === null)
                        await ls.set('user', JSON.stringify({email, password, isSeSouvenir, nom:nom}));
                    await ls.set('xxxx', r.data[0].type == 'SUPERADMIN'? 'AAAA':'BBBB');
                    navigate("dashboard", { replace: true });

                    // navigate("/dashboard", { replace: true })
                    // window.location.replace(
                    //     `https://memdom/${vare}`
                    // )
                    // await AsyncStorage.setItem("SESSION", JSON.stringify(dataUser));
                    //dispatch(initSession(dataUser));
                } else {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                }
            })
            .catch(e => {
                setIsLoading(false);
            });

    };



    const handleSubmitMotdepasseOublie = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get('email');
        if(email == ''){
            setOpenSnack({isOpenEmail1Error:true,txt:'Veuillez insérer votre e-mail'});
            return;
        }
        setOpenSnack({...openSnack, isOpenEmail1Error:false});

        setIsLoadingBtnRecupPass(true);
        setTimeout(()=>{
            setIsLoadingBtnRecupPass(false);
            setOpenToast({  isOpenToast: true, txt: "Une erreur s'est produite, peut être que vous n'êtes pas connecté sur internet." });
            setOpenModal(false)
        },10000);

        helpers
            .recuperermdp({
                email: email
            })
            .then(async r => {
                setIsLoadingBtnRecupPass(false);
                if (r.status === 200) {
                    setOpenToast({  isOpenToast: true, txt: r.message, });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setOpenModal(false)
                } else {
                    setOpenToast({ isOpenToast: true, txt: r.message, });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                }
            })
            .catch(e => {
                setOpenModal(false);
                setIsLoadingBtnRecupPass(false);
            });


    };






    return (
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Chargement...'
        >
            <ThemeProvider theme={theme}>
                <Grid container component="main" sx={{ height: '100vh' }}>
                    <CssBaseline />
                    <Grid
                        item
                        xs={false}
                        sm={4}
                        md={7}
                        sx={{
                            backgroundImage: 'url(http://test.memdom.fr/wp-content/uploads/2022/05/MAE-Dechetterie-1350x480-1.png)',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: (t) =>
                                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    />
                     <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                      <Box
                          sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                               flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            {/* <Avatar sx={{ m: 1, bgcolor: '#ff3959' }}> */}
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                               <LockOutlinedIcon />
                            </Avatar>

                            <Typography component="h1" variant="h5">
                                Se connecter
                            </Typography>
                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                   required
                                    fullWidth
                                    type="email"
                                    id="email"
                                    label="E-mail"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    value={emaill}
                                    onChange={(v)=>{v.preventDefault(); setEmail(v.target.value)}}
                                />
                               <Collapse in={openSnack.isOpenEmailError}>
                                   <Alert severity="error" action={
                                       <IconButton
                                           aria-label="close"
                                           color="inherit"
                                            size="small"
                                            onClick={() => {
                                               setOpenSnack({...openSnack, isOpenEmailError:false});
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                   }>{openSnack.txt}</Alert>
                                </Collapse>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Mot de passe"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    value={passwordd}
                                    onChange={function (v){v.preventDefault(); setPassword(v.target.value)}}
                                />
                               <Collapse in={openSnack.isOpenPasswordError}>
                                    <Alert severity="error" action={
                                       <IconButton
                                           aria-label="close"
                                           color="inherit"
                                            size="small"
                                           onClick={() => {
                                                setOpenSnack({...openSnack, isOpenPasswordError:false});
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }>{openSnack.txt}</Alert>
                                </Collapse>
                                <FormControlLabel
                                    control={<Checkbox onChange={handleSeSouvenir} value="remember" color="primary" />}
                                    label="Se souvenir de moi"
                                />

                                <Button
                                    onPress = {handleSubmit}
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    startIcon={<LoginIcon fontSize="inherit" />}
                                >
                                    Se connecter
                                </Button>
                    {/*            /!*<LoadingButton*!/*/}
                    {/*            /!*    sx={{ mt: 3, mb: 2 }}*!/*/}
                    {/*            /!*    fullWidth*!/*/}
                    {/*            /!*  //  size="small"*!/*/}
                    {/*            /!*    onClick={handleSubmit}*!/*/}
                    {/*            /!*    startIcon={<LoginIcon fontSize="inherit" />}*!/*/}
                    {/*            /!*    loading={isLoading}*!/*/}
                    {/*            /!*    loadingPosition="end"*!/*/}
                    {/*            /!*    variant="contained"*!/*/}
                    {/*            /!*>*!/*/}
                    {/*            /!*    Se connecter*!/*/}
                    {/*            /!*</LoadingButton>*!/*/}
                               <Grid container>
                                    <Grid item xs>
                                        <div style={{cursor:"pointer"}}   onClick={()=>setOpenModal(true)}>
                                            <Link  variant="body2">
                                                Mot de passe oublié?
                                            </Link>
                                        </div>
                                    </Grid>

                         {/*            /!*<Grid item>*!/*/}
                    {/*                /!*    <Link href="/#" variant="body2">*!/*/}
                     {/*                /!*        {"Don't have an account? Sign Up"}*!/*/}
                      {/*               /!*    </Link>*!/*/}
                     {/*               /!*</Grid>*!/*/}
                                </Grid>
                               <Copyright sx={{ mt: 5 }} />
                           </Box>
                       </Box>
                    </Grid> 
                </Grid>
                <Snackbar
                    open={openToast.isOpenToast}
                    autoHideDuration={6000}
                    //onClose={()=>setOpenToast({...openToast, isOpenToast:false})}
                    message={openToast.txt}
                    action={<React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={(event, reason) => {
                                if (reason === 'clickaway') {
                                    return;
                                }
                                setOpenToast(false);
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>}
                />
                <Modal
                    open={openModal}
                    onClose={()=>setOpenModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        //borerRadius:'15px',
                        borderRadius: '5px!important',
                        boxShadow: 24,
                        p: 4,
                    }}>
                        <Typography id="modal-modal-title" variant="h6" component="h3">
                            Récupération de mot de passe
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmitMotdepasseOublie} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="E-mail"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <Collapse in={openSnack.isOpenEmail1Error}>
                                <Alert severity="error" action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setOpenSnack({...openSnack, isOpenEmail1Error:false});
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }>{openSnack.txt}</Alert>
                            </Collapse>
                            <Button
                                onPress = {handleSubmitMotdepasseOublie}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                startIcon={
                                    isLoadingBtnRecupPass && <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                    // <LoginIcon fontSize="inherit" />
                                }
                            >
                               Récupérer
                            </Button>
                        </Box>
                    </Box>
                </Modal>
            </ThemeProvider>
        </LoadingOverlay>
    );
};


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://test.miparkaccess.fr/" >
            Mi parc access
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
