import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField/TextField";
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import {Alert} from "../Authentication/index";
import SaveIcon from '@mui/icons-material/Save';
import FormControlLabel from '@mui/material/FormControlLabel';
import {helpers} from "../../services/api/helpers";
import ls from "local-storage";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import {ThemeProvider} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import LoadingOverlay from "react-loading-overlay";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import { useLocation, useNavigate } from "react-router-dom"
import TaxiAlert from '@mui/icons-material/TaxiAlert';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Delete from '@mui/icons-material/Delete';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Dialog from "@mui/material/Dialog/Dialog";



const columns = ((clickVehicle, ShowConfirmDelete, clickSite) => [
    {
        name: 'Nom site',
        selector: row => row.nom,
        sortable:true,
        wrap:true,
        style:{fontSize:16},
        cell: (row) => <div style={{flexDirection:'row', alignItems:'center'}}><Button className={"btn btn-success px-1"} onClick={(row)=>clickSite(row)} id={row.id} style={{fontSize:11}}>{row.nom}</Button><span style={{paddingTop:3, paddingBottom:3, paddingLeft:5, paddingRight:5, backgroundColor:'#75ff42', borderRadius:10}}>{row.soussites.length}</span></div>,
    },
    {
        name: 'Nom du groupe',
        selector: row => row?.groupe?.nom,
        wrap:true,
        sortable:true,
    },
    {
        name: 'Adresse groupe',
        selector: row => row?.groupe?.adresse,
        wrap:true,
        sortable:true,
    },
    {
        name: 'Plage horaire',
        cell: (row) => <div style={{marginTop:5, marginBottom:5,flexDirection:'row', alignItems:'center'}}>
            {(row.plagehoraire_0  && row.plagehoraire_0 != null) ? <span className="m-2"><Chip
            label={row.plagehoraire_0.nom + '(' + row.plagehoraire_0.debutformated + '->' + row.plagehoraire_0.finformated + ') '}
            //onClick={handleClickChip}
            //onDelete={(a)=>handleDeleteChip(a,item.id)}
        /></span> : ''}
         {(row.plagehoraire_1 && row.plagehoraire_1 != null) ? <span  className="m-2"><Chip
            label={row.plagehoraire_1.nom + '(' + row.plagehoraire_1.debutformated + '->' + row.plagehoraire_1.finformated + ') '}
        /></span> : ''}
            {(row.plagehoraire_2  && row.plagehoraire_2 != null) ? <span  className="m-2"><Chip
                label={row.plagehoraire_2.nom + '(' + row.plagehoraire_2.debutformated + '->' + row.plagehoraire_2.finformated + ') '}
            /></span> : ''}
            {(row.plagehoraire_3 && row.plagehoraire_3 != null) ? <span  className="m-2"><Chip
                label={row.plagehoraire_3.nom + '(' + row.plagehoraire_3.debutformated + '->' + row.plagehoraire_3.finformated + ') '}
            /></span> : ''}
            {(row.plagehoraire_4 && row.plagehoraire_4 != null) ? <span  className="m-2"><Chip
                label={row.plagehoraire_4.nom + '(' + row.plagehoraire_4.debutformated + '->' + row.plagehoraire_4.finformated + ') '}
            /></span> : ''}
            {(row.plagehoraire_5 && row.plagehoraire_5 != null) ? <span  className="m-2"><Chip
                label={row.plagehoraire_5.nom + '(' + row.plagehoraire_5.debutformated + '->' + row.plagehoraire_5.finformated + ') '}
            /></span> : ''}
            {(row.plagehoraire_6 && row.plagehoraire_6 != null) ? <span  className="m-2"><Chip
                label={row.plagehoraire_6.nom + '(' + row.plagehoraire_6.debutformated + '->' + row.plagehoraire_6.finformated + ') '}
            /></span> : ''}
            {(row.plagehoraire_7 && row.plagehoraire_7 != null) ? <span  className="m-2"><Chip
                label={row.plagehoraire_7.nom + '(' + row.plagehoraire_7.debutformated + '->' + row.plagehoraire_7.finformated + ') '}
            /></span> : ''}
            {(row.plagehoraire_8 && row.plagehoraire_8 != null) ? <span  className="m-2"><Chip
                label={row.plagehoraire_8.nom + '(' + row.plagehoraire_8.debutformated + '->' + row.plagehoraire_8.finformated + ') '}
            /></span> : ''}
            {(row.plagehoraire_9 && row.plagehoraire_9 != null) ? <span  className="m-2"><Chip
                label={row.plagehoraire_9.nom + '(' + row.plagehoraire_9.debutformated + '->' + row.plagehoraire_9.finformated + ') '}
            /></span> : ''}
        </div>,
        wrap:true,
    },
    {
        name: 'Commentaire',
        selector: row => row.commentaire,
        wrap:true,
    },
    {
        name: '',
        //selector: row => "Bonjour",
        wrap:true,
        button: true,
        // cell: (row) => <Button className={"btn btn-success px-4"} onClick={(row)=>clickHandler(row)} id={row.id} ><i className="fas fa-car mr-2 fa-2x" /></Button>,
        // cell: (row) => <Button className={"btn btn-success px-4"} onClick={(row)=>clickHandler(row)} id={row.id} >zeze</Button>,
        cell: (row) => <div style={{flexDirection:'row', alignItems:'center'}}><span style={{paddingTop:3, paddingBottom:3, paddingLeft:5, paddingRight:5, backgroundColor:'#75ff42', borderRadius:10}}>{row?.vehicules.length}</span><Button className={"btn btn-success px-1"} onClick={(row)=>clickHandler(row)} id={row.id} style={{fontSize:11}}>Véhicules</Button></div>,

    },
    {
        name: '',
        wrap:true,
        button: true,
        cell: (row) => <div style={{ }}><div  onClick={ShowConfirmDelete} id={row.id} style={{fontSize:11, color: 'red'}}>Supprimer<Delete /></div></div>,}

]);


export const Sitex = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [isshowmodal, setshowmodal] = useState(false);
    const [isLoadingSave, setLoadingSave] = useState(false);
    const [openSnack, setOpenSnack] = useState({
        isOpenNomError:false,
        isOpenGroupeError:false,
        isOpenPlagehoraireError:false,
        txt:''});
    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [tables, setTables] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);
    const [searchField, setSearchField] = useState("");
    const [groupes, setGroupes] = useState([]);
    const [groupe, setGroupe] = useState("");
    const [GroupeOnChange, setGroupeOnChange] = useState(null);
    const [nommodif, setnommodif] = useState("");
    const [commentairemodif, setcommentairemodif] = useState("");
    const [idmodif, setidmodif] = useState(null);
    const [isshowmodalmodif, setshowmodalmodif] = useState(false);

    const [plagehoraires, setPlagehoraires] = useState([]);
    const [plagehoraire, setPlagehoraire] = useState("");
    const [plagehoraireonchange, setPlagehoraireOnChange] = useState(null);
    const [arrayplagehoraire, setArrayPlagehoraire] = useState([]);

    const [idtosuppr, setIdToSuppr] = useState(null);
    const [open, setOpen] = React.useState(false);
    const ShowConfirmDelete = async (val) => {
        console.log('target id', await val.target.id)
        setIdToSuppr(val.target.id);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const suppression = async (e)=> {
        helpers
            .supprSite({id: idtosuppr})
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    getSites().catch(console.error);
                    setOpen(false);
                }
            })
    };

    const handleClickVehicle = (val) => {
        const from =  `/dashboard/vehiculeparsite/${val.target.id}`;
        navigate(from, { replace: true })
    };
    const handleClickSite = (val) => {
        const from =  `/dashboard/soussite/${val.target.id}`;
        navigate(from, { replace: true })
    };
    useEffect(()=>{
        const filteredTables = tables && tables.filter(
            item => {
                return (
                    item.nom !== null && item.nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.groupe?.nom !== null && item.groupe?.nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.groupe?.adresse !== null && item.groupe?.adresse
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item.commentaire !== null && item.commentaire
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                );
            }
        );
        if(searchField == '')
            getSites().catch(console.error);
        if(filteredTables !== undefined)
        setTables(filteredTables);
    },[searchField]);

    function rechercheindb(){
        if(searchField != ''){
            return;
        }
        return;
    }

    function newGroupes(e) {
        e.preventDefault();
        setshowmodal(true);
    }

    const getSites = async () => {
        helpers
            .getAllSites()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setTables( r.data);
                }
            })
    };
    const getGroupeToDropdown = async () => {
        helpers
            .getAllGroupesToDropdown()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setGroupes( r.data);
                }
            })
    };

    const getPlagehoraireToDropdown = async () => {
        helpers
            .getAllPlagehorairesToDropdown()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setPlagehoraires( r.data);
                }
            })
    };


    useEffect(()=>{
        setLoading(true);
        getSites().catch(console.error);
        getGroupeToDropdown().catch(console.error);
        getPlagehoraireToDropdown().catch(console.error);
    },[]);


    const savenewSite =async (e)=> {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const nom = data.get('nom');
        const commentaire = data.get('commentaire');
            console.log('arrayplagehoraire',arrayplagehoraire)
        if(nom == ''){
            setOpenSnack({...openSnack, isOpenNomError:true,txt:'Veuillez insérer le nom du site'});
            return;
        }
        setOpenSnack({...openSnack, isOpenNomError:false});
        if(groupe == ''){
            setOpenSnack({...openSnack, isOpenGroupeError:true,txt:'Veuillez choisir ou ajouter un groupe.'});
            return;
        }
        setOpenSnack({...openSnack, isOpenGroupeError:false});

        if(arrayplagehoraire.length == 0){
            setOpenSnack({...openSnack, isOpenPlagehoraireError:true,txt:'Veuillez ajouter au moins une plage horaire.'});
            return;
        }
        setOpenSnack({...openSnack, isOpenPlagehoraireError:false});
        if(arrayplagehoraire.length > 10){
            setOpenSnack({...openSnack, isOpenPlagehoraireError:true,txt:'Le nombre du plage horaire maximum autorisé est de 10.'});
            return;
        }
        setOpenSnack({...openSnack, isOpenPlagehoraireError:false});

        setLoadingSave(true);
        let dataX = {
            nom: nom,
            groupe: groupe,
            commentaire: commentaire,
            plagehoraire_0: arrayplagehoraire[0].id
        };
        if(arrayplagehoraire.length === 2){
            dataX.plagehoraire_1 = await arrayplagehoraire[1].id;
        }else if(arrayplagehoraire.length === 3){
            dataX.plagehoraire_1 = await arrayplagehoraire[1].id;
            dataX.plagehoraire_2 = await  arrayplagehoraire[2].id;
        }else if(arrayplagehoraire.length === 4){
            dataX.plagehoraire_1 = await arrayplagehoraire[1].id;
            dataX.plagehoraire_2 = await  arrayplagehoraire[2].id
            dataX.plagehoraire_3 = await  arrayplagehoraire[3].id;
        }else if(arrayplagehoraire.length === 5){
            dataX.plagehoraire_1 = await arrayplagehoraire[1].id;
            dataX.plagehoraire_2 = await  arrayplagehoraire[2].id
            dataX.plagehoraire_3 = await  arrayplagehoraire[3].id;
            dataX.plagehoraire_4 = await  arrayplagehoraire[4].id;
        }else  if(arrayplagehoraire.length === 6){
            dataX.plagehoraire_1 = await arrayplagehoraire[1].id;
            dataX.plagehoraire_2 = await  arrayplagehoraire[2].id
            dataX.plagehoraire_3 = await  arrayplagehoraire[3].id;
            dataX.plagehoraire_4 = await  arrayplagehoraire[4].id;
            dataX.plagehoraire_5 = await  arrayplagehoraire[5].id;
        }else  if(arrayplagehoraire.length === 7){
            dataX.plagehoraire_1 = await arrayplagehoraire[1].id;
            dataX.plagehoraire_2 = await  arrayplagehoraire[2].id
            dataX.plagehoraire_3 = await  arrayplagehoraire[3].id;
            dataX.plagehoraire_4 = await  arrayplagehoraire[4].id;
            dataX.plagehoraire_5 = await  arrayplagehoraire[5].id;
            dataX.plagehoraire_6 =  await arrayplagehoraire[6].id;
        }else if(arrayplagehoraire.length === 8){
            dataX.plagehoraire_1 = await arrayplagehoraire[1].id;
            dataX.plagehoraire_2 = await  arrayplagehoraire[2].id
            dataX.plagehoraire_3 = await  arrayplagehoraire[3].id;
            dataX.plagehoraire_4 = await  arrayplagehoraire[4].id;
            dataX.plagehoraire_5 = await  arrayplagehoraire[5].id;
            dataX.plagehoraire_6 =  await arrayplagehoraire[6].id;
            dataX.plagehoraire_7 = await  arrayplagehoraire[7].id;
        }else if(arrayplagehoraire.length === 9){
            dataX.plagehoraire_1 = await arrayplagehoraire[1].id;
            dataX.plagehoraire_2 = await  arrayplagehoraire[2].id
            dataX.plagehoraire_3 = await  arrayplagehoraire[3].id;
            dataX.plagehoraire_4 = await  arrayplagehoraire[4].id;
            dataX.plagehoraire_5 = await  arrayplagehoraire[5].id;
            dataX.plagehoraire_6 =  await arrayplagehoraire[6].id;
            dataX.plagehoraire_7 = await  arrayplagehoraire[7].id
            dataX.plagehoraire_8 =  await arrayplagehoraire[8].id;
        }else if(arrayplagehoraire.length > 9){
            dataX.plagehoraire_1 = await arrayplagehoraire[1].id;
            dataX.plagehoraire_2 = await  arrayplagehoraire[2].id
            dataX.plagehoraire_3 = await  arrayplagehoraire[3].id;
            dataX.plagehoraire_4 = await  arrayplagehoraire[4].id;
            dataX.plagehoraire_5 = await  arrayplagehoraire[5].id;
            dataX.plagehoraire_6 =  await arrayplagehoraire[6].id;
            dataX.plagehoraire_7 = await  arrayplagehoraire[7].id
            dataX.plagehoraire_8 =  await arrayplagehoraire[8].id;
            dataX.plagehoraire_9 =  await arrayplagehoraire[9].id;

        }

        console.log('dataX',dataX);
        if(GroupeOnChange != null && GroupeOnChange.label === groupe)
            dataX.groupeid = GroupeOnChange.id;

        helpers
            .sitesave(dataX)
            .then(async r => {
                setLoadingSave(false);
                getSites().catch(console.error);
                getGroupeToDropdown().catch(console.error);
                getPlagehoraireToDropdown().catch(console.error);
                if (r.status === 200) {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setshowmodal(false);
                } else {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                }
            })
            .catch(e => {
                setLoadingSave(false);
            });
    };

    const modification =async (e)=> {
        e.preventDefault();
        const data = new FormData(e.currentTarget);

        if(nommodif == ''){
            setOpenSnack({...openSnack, isOpenNomError:true,txt:'Le site ne pourrait être pas vide.'});
            return;
        }
        setOpenSnack({...openSnack, isOpenNomError:false});

        if(groupe.trim() == ''){
            setOpenToast({
                isOpenToast: true,
                txt: 'Veuillez choisir ou ajouter le groupe.'
            });
            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
            return;
        }

        if(arrayplagehoraire.length == 0){
            setOpenSnack({...openSnack, isOpenPlagehoraireError:true,txt:'Veuillez ajouter au moins une plage horaire.'});
            return;
        }
        setOpenSnack({...openSnack, isOpenPlagehoraireError:false});
        if(arrayplagehoraire.length > 10){
            setOpenSnack({...openSnack, isOpenPlagehoraireError:true,txt:'Le nombre du plage horaire maximum autorisé est de 10.'});
            return;
        }
        setOpenSnack({...openSnack, isOpenPlagehoraireError:false});

        setLoadingSave(true);

        let dataX = {
            nom: nommodif,
            groupe:  groupe.trim(),
            commentaire: commentairemodif,
            id: idmodif,
            plagehoraire_0: arrayplagehoraire[0].id
        };
        if(arrayplagehoraire.length === 2){
            dataX.plagehoraire_1 = await arrayplagehoraire[1].id;
        }else if(arrayplagehoraire.length === 3){
            dataX.plagehoraire_1 = await arrayplagehoraire[1].id;
            dataX.plagehoraire_2 = await  arrayplagehoraire[2].id;
        }else if(arrayplagehoraire.length === 4){
            dataX.plagehoraire_1 = await arrayplagehoraire[1].id;
            dataX.plagehoraire_2 = await  arrayplagehoraire[2].id
            dataX.plagehoraire_3 = await  arrayplagehoraire[3].id;
        }else if(arrayplagehoraire.length === 5){
            dataX.plagehoraire_1 = await arrayplagehoraire[1].id;
            dataX.plagehoraire_2 = await  arrayplagehoraire[2].id
            dataX.plagehoraire_3 = await  arrayplagehoraire[3].id;
            dataX.plagehoraire_4 = await  arrayplagehoraire[4].id;
        }else  if(arrayplagehoraire.length === 6){
            dataX.plagehoraire_1 = await arrayplagehoraire[1].id;
            dataX.plagehoraire_2 = await  arrayplagehoraire[2].id
            dataX.plagehoraire_3 = await  arrayplagehoraire[3].id;
            dataX.plagehoraire_4 = await  arrayplagehoraire[4].id;
            dataX.plagehoraire_5 = await  arrayplagehoraire[5].id;
        }else  if(arrayplagehoraire.length === 7){
            dataX.plagehoraire_1 = await arrayplagehoraire[1].id;
            dataX.plagehoraire_2 = await  arrayplagehoraire[2].id
            dataX.plagehoraire_3 = await  arrayplagehoraire[3].id;
            dataX.plagehoraire_4 = await  arrayplagehoraire[4].id;
            dataX.plagehoraire_5 = await  arrayplagehoraire[5].id;
            dataX.plagehoraire_6 =  await arrayplagehoraire[6].id;
        }else if(arrayplagehoraire.length === 8){
            dataX.plagehoraire_1 = await arrayplagehoraire[1].id;
            dataX.plagehoraire_2 = await  arrayplagehoraire[2].id
            dataX.plagehoraire_3 = await  arrayplagehoraire[3].id;
            dataX.plagehoraire_4 = await  arrayplagehoraire[4].id;
            dataX.plagehoraire_5 = await  arrayplagehoraire[5].id;
            dataX.plagehoraire_6 =  await arrayplagehoraire[6].id;
            dataX.plagehoraire_7 = await  arrayplagehoraire[7].id;
        }else if(arrayplagehoraire.length === 9){
            dataX.plagehoraire_1 = await arrayplagehoraire[1].id;
            dataX.plagehoraire_2 = await  arrayplagehoraire[2].id
            dataX.plagehoraire_3 = await  arrayplagehoraire[3].id;
            dataX.plagehoraire_4 = await  arrayplagehoraire[4].id;
            dataX.plagehoraire_5 = await  arrayplagehoraire[5].id;
            dataX.plagehoraire_6 =  await arrayplagehoraire[6].id;
            dataX.plagehoraire_7 = await  arrayplagehoraire[7].id
            dataX.plagehoraire_8 =  await arrayplagehoraire[8].id;
        }else if(arrayplagehoraire.length > 9){
            dataX.plagehoraire_1 = await arrayplagehoraire[1].id;
            dataX.plagehoraire_2 = await  arrayplagehoraire[2].id
            dataX.plagehoraire_3 = await  arrayplagehoraire[3].id;
            dataX.plagehoraire_4 = await  arrayplagehoraire[4].id;
            dataX.plagehoraire_5 = await  arrayplagehoraire[5].id;
            dataX.plagehoraire_6 =  await arrayplagehoraire[6].id;
            dataX.plagehoraire_7 = await  arrayplagehoraire[7].id
            dataX.plagehoraire_8 =  await arrayplagehoraire[8].id;
            dataX.plagehoraire_9 =  await arrayplagehoraire[9].id;

        }

        if(GroupeOnChange != null && GroupeOnChange.label === groupe)
            dataX.groupeid = GroupeOnChange.id;


        helpers
            .modificationsite(dataX)
            .then(async r => {
                setLoadingSave(false);
                if (r.status === 200) {
                    getGroupeToDropdown().catch(()=>null);
                    getSites().catch(()=>null);
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setshowmodalmodif(false);

                } else {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                }
            })
            .catch(e => {
                setLoadingSave(false);
            });
    };
    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

    const handleClickChip = () => {
        console.info('You clicked the Chip.');
    };

    function handleDeleteChip (a,id) {
        const arrmp = [...arrayplagehoraire];
        const newarrmp = arrmp.filter(
            item => {
                return (
                    item.id != id
                )
            })
        setArrayPlagehoraire(newarrmp)
    };
    
    return (
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Chargement...'
        >
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Sites</h1>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        Sites
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <Link to="/dashboard"  className="link">
                                        Parc access
                                        </Link>
                                    </li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                        <button
                            onClick={(e)=>newGroupes(e)}
                            className={"btn btn-success px-4"}><i className="fas fa-plus-circle mr-2" />Ajout</button>
                    </div>{/* /.container-fluid */}
                </div>

                {/*le datatable*/}
                <div className="card">
                    <div className="card-body">
                        <Paper
                            component="form"
                            sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}   >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Rechercher"
                                inputProps={{ 'aria-label': 'Rechercher' }}
                                onChange={(e)=>{
                                    e.preventDefault();
                                    setSearchField(e.target.value);
                                }}
                                value={searchField}
                            />
                            <IconButton  onClick={() => {
                                rechercheindb()
                            }} type="button" sx={{ p: '5px' }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                        </Paper>
                        <DataTable
                            pagination
                            // selectableRowsComponent={Checkbox}
                            selectableRowsComponentProps={selectProps}
                            // sortIcon={sortIcon}
                            dense
                            //{...props}
                            //title="Administration"
                            // theme={'dark'}
                            //columns={columns}
                            columns={columns(handleClickVehicle, ShowConfirmDelete, handleClickSite)}
                            data={tables}
                            // selectableRows
                            striped
                            highlightOnHover
                            persistTableHead
                            fixedHeader={false}
                            progressPending={false}
                            noDataComponent={"Aucune données à afficher"}
                            onRowClicked={async (row, event) => {
                                console.log('row clicked', row);
                                setnommodif(row?.nom);
                                setGroupe(row?.groupe?.nom || "");
                                setcommentairemodif(row?.commentaire || "");
                                setidmodif(row?.id);
                                let newarrplagehoraire = [];
                                if(row?.plagehoraire_0 != null){
                                  await newarrplagehoraire.push({id: row?.plagehoraire_0.id, label: row?.plagehoraire_0.nom + '(' + row?.plagehoraire_0.debutformated + '->' + row?.plagehoraire_0.finformated + ')'})
                                }
                                if(row?.plagehoraire_1 != null){
                                  await newarrplagehoraire.push({id: row?.plagehoraire_1.id, label: row?.plagehoraire_1.nom + '(' + row?.plagehoraire_1.debutformated + '->' + row?.plagehoraire_1.finformated + ')'})
                                }
                                if(row?.plagehoraire_2 != null){
                                  await newarrplagehoraire.push({id: row?.plagehoraire_2.id, label: row?.plagehoraire_2.nom + '(' + row?.plagehoraire_2.debutformated + '->' + row?.plagehoraire_2.finformated + ')'})
                                }
                                if(row?.plagehoraire_3 != null){
                                  await newarrplagehoraire.push({id: row?.plagehoraire_3.id, label: row?.plagehoraire_3.nom + '(' + row?.plagehoraire_3.debutformated + '->' + row?.plagehoraire_3.finformated + ')'})
                                }
                                if(row?.plagehoraire_4 != null){
                                  await newarrplagehoraire.push({id: row?.plagehoraire_4.id, label: row?.plagehoraire_4.nom + '(' + row?.plagehoraire_4.debutformated + '->' + row?.plagehoraire_4.finformated + ')'})
                                }
                                if(row?.plagehoraire_5 != null){
                                  await newarrplagehoraire.push({id: row?.plagehoraire_5.id, label: row?.plagehoraire_5.nom + '(' + row?.plagehoraire_5.debutformated + '->' + row?.plagehoraire_5.finformated + ')'})
                                }
                                if(row?.plagehoraire_6 != null){
                                  await newarrplagehoraire.push({id: row?.plagehoraire_6.id, label: row?.plagehoraire_6.nom + '(' + row?.plagehoraire_6.debutformated + '->' + row?.plagehoraire_6.finformated + ')'})
                                }
                                if(row?.plagehoraire_7 != null){
                                  await newarrplagehoraire.push({id: row?.plagehoraire_7.id, label: row?.plagehoraire_7.nom + '(' + row?.plagehoraire_7.debutformated + '->' + row?.plagehoraire_7.finformated + ')'})
                                }
                                if(row?.plagehoraire_8 != null){
                                  await newarrplagehoraire.push({id: row?.plagehoraire_8.id, label: row?.plagehoraire_8.nom + '(' + row?.plagehoraire_8.debutformated + '->' + row?.plagehoraire_8.finformated + ')'})
                                }
                                if(row?.plagehoraire_9 != null){
                                  await newarrplagehoraire.push({id: row?.plagehoraire_9.id, label: row?.plagehoraire_9.nom + '(' + row?.plagehoraire_9.debutformated + '->' + row?.plagehoraire_9.finformated + ')'})
                                }
                                if(newarrplagehoraire.length>0)
                                setArrayPlagehoraire(newarrplagehoraire);
                                setPlagehoraire("");
                                setshowmodalmodif(true);
                            }}

                            // onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {console.log(' onChangePage')}}
                            // onChangePage={(page, totalRows)  => {console.log(' onChangePage')}}
                            // onRowMouseEnter={(row, event) => {console.log('row clicked')}}
                            // expandableRows={false}
                            // expandableRowsComponent={() => {console.log('row clicked')}}
                            paginationComponentOptions={{ rowsPerPageText: 'Lignes par page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                        />
                    </div>
                    {/*fin body*/}
                </div>
                <Modal
                    open={isshowmodal}
                    onClose={()=>setshowmodal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description" >
                    <div className="modal-dialog ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Nouveau site</h4>
                                <button onClick={()=>setshowmodal(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <Box component="form" onSubmit={savenewSite} sx={{ mt: 1 }}
                                 Validate
                                 autoComplete="on"   >
                                <div   className="modal-body">
                                    <TextField
                                        margin="normal"
                                        //  required
                                        fullWidth
                                        id="nom"
                                        label="Nom"
                                        name="nom"
                                        autoComplete="nom"
                                        autoFocus
                                    />
                                    <Collapse in={openSnack.isOpenNomError}>
                                        <Alert severity="error" action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setOpenSnack({...openSnack, isOpenNomError:false});
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }>{openSnack.txt}</Alert>
                                    </Collapse>
                                    <div style={{ justifyContent:'flex-end', marginTop:10, marginRight:5}} className="row">
                                        <Autocomplete
                                            margin="normal"
                                            disablePortal
                                            id="combo-box-demo1"
                                            value={{label:groupe}}
                                            options={
                                                groupes.length > 0 ?
                                                    groupes
                                                    :
                                                    [
                                                        //  { label: 'vide', id: 1994 },
                                                    ]
                                            }
                                            onChange={(value0, value1)=>{setGroupe(value1.label); setGroupeOnChange(value1)}}
                                            sx={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params} value={groupe} onChange={(value)=>{setGroupe(value.target.value); }} label="Groupe" />}
                                        />
                                    </div>
                                    <Collapse in={openSnack.isOpenGroupeError}>
                                        <Alert severity="error" action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setOpenSnack({...openSnack, isOpenGroupeError:false});
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }>{openSnack.txt}</Alert>
                                    </Collapse>
                                    <div style={{ justifyContent:'flex-end', marginTop:10, marginRight:5}} className="row">
                                        {arrayplagehoraire.length>0 && arrayplagehoraire.map((item, i)=>{
                                            if(i<=10)
                                          return <Chip
                                                label={item.label}
                                                onClick={handleClickChip}
                                                onDelete={(a)=>handleDeleteChip(a,item.id)}
                                            />
                                        })}
                                    </div>
                                    <div style={{ justifyContent:'flex-end', marginTop:10, marginRight:5}} className="row">
                                        <Autocomplete
                                            margin="normal"
                                            disablePortal
                                            id="combo-box-demo1"
                                            value={{label:plagehoraire}}
                                            options={
                                                plagehoraires.length > 0 ?
                                                    plagehoraires
                                                    :
                                                    [
                                                        //  { label: 'vide', id: 1994 },
                                                    ]
                                            }
                                            onChange={async (value0, value1)=>{
                                                if(value1 != null && value1 != ''){
                                                    setPlagehoraire(value1.label);
                                                    //setPlagehoraireOnChange(value1.label);
                                                    let arrayp = [...arrayplagehoraire];
                                                    await arrayp.push(value1);
                                                    setArrayPlagehoraire(arrayp);
                                                }
                                            }}
                                            sx={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params} value={plagehoraire} onChange={(value)=>{setPlagehoraire(value.target.value); }} label="Plage horaire" />}
                                        />
                                    </div>
                                    <Collapse in={openSnack.isOpenPlagehoraireError}>
                                        <Alert severity="error" action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setOpenSnack({...openSnack, isOpenPlagehoraireError:false});
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }>{openSnack.txt}</Alert>
                                    </Collapse>
                                    <TextField
                                        margin="normal"
                                        //    required
                                        fullWidth
                                        id="commentaire"
                                        label="Commentaire"
                                        name="commentaire"
                                        autoComplete="commentaire"
                                        autoFocus
                                    />


                                </div>
                                <div className="modal-footer d-flex flex-row justify-content-between">
                                    <button onClick={()=>setshowmodal(false)} type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    <Button
                                        onPress = {savenewSite}
                                        type="submit"
                                        // fullWidth
                                        variant="contained"
                                        // sx={{ mt: 3, mb: 2 }}
                                        startIcon={
                                            isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                                : <SaveIcon fontSize="inherit" />
                                        }
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </Box>

                        </div>
                    </div>
                </Modal>
                <Modal
                    open={isshowmodalmodif}
                    onClose={()=>setshowmodalmodif(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className="modal-dialog ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Modification site</h4>
                                <button onClick={()=>setshowmodalmodif(false)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <Box component="form" onSubmit={modification} sx={{ mt: 1 }}
                                 Validate
                                 autoComplete="on"   >
                                <div   className="modal-body">
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="nom"
                                        label="Nom"
                                        name="nom"
                                        autoComplete="nom"
                                        autoFocus
                                        value={nommodif}
                                        onChange={(e)=>setnommodif(e.target.value)}
                                    />

                                    <Collapse in={openSnack.isOpenNomError}>
                                        <Alert severity="error" action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setOpenSnack({...openSnack, isOpenNomError:false});
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }>{openSnack.txt}</Alert>
                                    </Collapse>
                                    <div style={{ justifyContent:'flex-end', marginTop:10}} className="row">
                                        <Autocomplete
                                            margin="normal"
                                            disablePortal
                                            id="combo-box-demo1"
                                            value={{label:groupe}}
                                            options={
                                                groupes.length > 0 ?
                                                    groupes
                                                    :
                                                    []
                                            }
                                            onChange={(value0, value1)=>{setGroupe(value1.label); setGroupeOnChange(value1)}}
                                            sx={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params} value={groupe} onChange={(value)=>{setGroupe(value.target.value); }} label="Groupe" />}
                                        />
                                    </div>
                                    <div style={{ justifyContent:'flex-end', marginTop:10, marginRight:5}} className="row">
                                        {arrayplagehoraire.length>0 && arrayplagehoraire.map((item, i)=>{
                                            if(i<=10)
                                                return <Chip
                                                    label={item.label}
                                                    onClick={handleClickChip}
                                                    onDelete={(a)=>handleDeleteChip(a,item.id)}   />
                                        })}
                                    </div>
                                    <div style={{ justifyContent:'flex-end', marginTop:10, marginRight:5}} className="row">
                                        <Autocomplete
                                            margin="normal"
                                            disablePortal
                                            id="combo-box-demo1"
                                            value={{label:plagehoraire}}
                                            options={
                                                plagehoraires.length > 0 ?
                                                    plagehoraires
                                                    :
                                                    []
                                            }
                                            onChange={async (value0, value1)=>{
                                                if(value1 != null && value1 != ''){
                                                    setPlagehoraire(value1.label);
                                                    //setPlagehoraireOnChange(value1.label);
                                                    let arrayp = [...arrayplagehoraire];
                                                    await arrayp.push(value1);
                                                    setArrayPlagehoraire(arrayp);
                                                }
                                            }}
                                            sx={{ width: 300 }}
                                            renderInput={(params) => <TextField {...params} value={plagehoraire} onChange={(value)=>{setPlagehoraire(value.target.value); }} label="Plage horaire" />}
                                        />
                                    </div>
                                    <Collapse in={openSnack.isOpenPlagehoraireError}>
                                        <Alert severity="error" action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setOpenSnack({...openSnack, isOpenPlagehoraireError:false});
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }>{openSnack.txt}</Alert>
                                    </Collapse>
                                    <TextField
                                        margin="normal"
                                        //    required
                                        fullWidth
                                        id="commentaire"
                                        label="Commentaire"
                                        name="commentaire"
                                        autoComplete="commentaire"
                                        value={commentairemodif}
                                        onChange={(e)=>setcommentairemodif(e.target.value)}
                                    />
                                </div>
                                <div className="modal-footer d-flex flex-row justify-content-between">
                                    <button onClick={()=>setshowmodalmodif(false)} type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    <Button
                                        onPress = {modification}
                                        type="submit"
                                        // fullWidth
                                        variant="contained"
                                        // sx={{ mt: 3, mb: 2 }}
                                        startIcon={
                                            isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                                : <SaveIcon fontSize="inherit" />
                                        }
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </Box>

                        </div>
                    </div>
                </Modal>
                <Snackbar
                    open={openToast.isOpenToast}
                    autoHideDuration={6000}
                    //onClose={()=>setOpenToast({...openToast, isOpenToast:false})}
                    message={openToast.txt}
                    action={<React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={(event, reason) => {
                                if (reason === 'clickaway') {
                                    return;
                                }
                                setOpenToast(false);
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>}
                />
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Suppression site
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Voulez-vous vraiment supprimer?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button  onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button onClick={suppression}>Ok</Button>
                </DialogActions>
            </Dialog>
        </LoadingOverlay>
    )
};
