import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField/TextField";
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import {Alert} from "../Authentication/index";
import SaveIcon from '@mui/icons-material/Save';
import FormControlLabel from '@mui/material/FormControlLabel';
import {helpers} from "../../services/api/helpers";
import ls from "local-storage";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import {ThemeProvider} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import LoadingOverlay from "react-loading-overlay";
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { useLocation, useNavigate } from "react-router-dom"
import { socket } from '../../socket';
import { io } from 'socket.io-client';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";

import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

import fr from 'date-fns/locale/fr';
registerLocale('fr', fr)


const d = [{
    horraire:"2023/01/10 10:20",
    immatriculation: "Karug961",
    entrersortie: "Entrer",
    isrefus: "Autorisé",
    groupe: "Cap Vert",
    site: "La moule"
}]
let isAdmin = ls.get('xxxx');

let columns = [];
if(isAdmin === "AAAA" ) {
    columns = (clickHandler => [
        {
            name: 'Horraire',
            selector: row => (row.horairestring).substr(0, 19),
            sortable: true,
            wrap: true,
            style: {fontSize: 16}
        },
        {
            name: 'Immatriculation',
            selector: row => row.immatriculation,
            wrap: true,
            sortable: true,
        },
        {
            name: 'Etat',
            // selector: row => row.isAutorise ? 'Autorisé' : 'Refusé',
            wrap: true,
            cell: (row) => row.isAutorise === true ? <div style={{fontSize: 11, color: 'green'}}>Autorisé</div> :
                <div style={{fontSize: 11, color: 'red'}}>Refusé</div>,
        },
        {
            name: 'Groupe(Ville)',
            selector: row => row?.site?.groupe?.nom,
            wrap: true,
        },
        {
            name: 'Site',
            selector: row => row?.site?.nom,
            wrap: true,
        },
        // {
        //     name: '',
        //     wrap:true,
        //     button: true,
        //     cell: (row) => <Button className={"btn btn-success px-4"} onClick={(row)=>clickHandler(row)} id={row.id} style={{fontSize:11}}>Sites</Button>,
        //
        // }
    ]);
}else{
    columns = (clickHandler => [
        {
            name: 'Horraire',
            selector: row => (row.horairestring).substr(0, 19),
            sortable: true,
            wrap: true,
            style: {fontSize: 16}
        },
        {
            name: 'Immatriculation',
            selector: row => row.immatriculation,
            wrap: true,
            sortable: true,
        },
        {
            name: 'Etat',
            // selector: row => row.isAutorise ? 'Autorisé' : 'Refusé',
            wrap: true,
            cell: (row) => row.isAutorise === true ? <div style={{fontSize: 11, color: 'green'}}>Autorisé</div> :
                <div style={{fontSize: 11, color: 'red'}}>Refusé</div>,
        }

    ]);
}




    function downloadCSV(array) {
    	const link = document.createElement('a');
        const csv = "server";
    	if (csv == null) return;

    	const filename = 'rapport.csv';

    	link.setAttribute('href', encodeURI(csv));
    	link.setAttribute('download', filename);
    	link.click();
    }


const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)}>Export</Button>;


export const EntrerSortie0 = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [isshowmodal, setshowmodal] = useState(false);
    const [isshowmodalmodif, setshowmodalmodif] = useState(false);
    const [isLoadingSave, setLoadingSave] = useState(false);
    const [openSnack, setOpenSnack] = useState({
        isOpenNomError:false,
        txt:''});
    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [tables, setTables] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);
    const [searchField, setSearchField] = useState("");
    const [nommodif, setnommodif] = useState("");
    const [adressemodif, setadressemodif] = useState("");
    const [commentairemodif, setcommentairemodif] = useState("");
    const [idmodif, setidmodif] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const handleButtonColumn = (val) => {
        const from =  `/dashboard/sitespargroupe/${val.target.id}`;
        navigate(from, { replace: true })
    };

    useEffect(()=>{
        const filteredTables = tables  && tables.filter(
            item => {
                return (
                    (item.horairestring !== null && item.horairestring !== undefined) && item.horairestring
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    (item.immatriculation !== null && item.immatriculation !== undefined) && item.immatriculation
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    (item.site?.groupe?.nom !== null && item.site?.groupe?.nom !== undefined) && item.site?.groupe?.nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                        (item.site?.nom !== null && item.site?.nom !== undefined) && item.site?.nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                );
            }
        );
        if(searchField == '')
            helpers
                .rechercherpardate({startDate:startDate, endDate:endDate})
                .then(async r => {
                    setLoading(false);
                    if (r.status === 200) {
                        setTables(r.data);
                    }
                })

       if(filteredTables !== undefined)
       setTables(filteredTables);
    },[searchField]);

    function rechercheindb(){
        if(searchField != ''){
            return;
        }
        return;

    }



    useEffect(()=>{
        // getEntrersortiedb().catch(console.error);

    },[]);

    const getEntrersortiedb = async () => {
        helpers
            .getEntrersortiedb()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setTables(r.data);
                }
            })
    };

    function rechercherpardate(e) {
        setLoading(true);
        e.preventDefault();
        console.log(startDate);
        console.log(endDate);
        helpers
            .rechercherpardate({startDate:startDate, endDate:endDate})
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setTables(r.data);
                   // getEntrersortiedb().catch(console.error);
                }
            })
    }

    function excel(e) {
        e.preventDefault();

        helpers
            .excel({excel:tables})
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    const link = document.createElement('a');
                    // const csv = "server";
                    // if (csv == null) return;
                    link.setAttribute('href', encodeURI(r.url));
                    // link.setAttribute('download', 'rapport.csv');
                    await link.click();
                }
            })

    }


    function pdf(e) {
        e.preventDefault();

    }

    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };
    const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(tables)} />, []);


  return (
      <LoadingOverlay
          active={isLoading}
          spinner
          text='Chargement...'
      >
      <div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0 text-dark">Rapport</h1>
                    </div>{/* /.col */}
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item">
                              Rapport
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/dashboard"  className="link">
                                Parc access
                                </Link>
                            </li>
                        </ol>
                    </div>{/* /.col */}
                </div>{/* /.row */}
                {/*<button*/}
                {/*    onClick={(e)=>newGroupes(e)}*/}
                {/*    className={"btn btn-success px-4"}><i className="fas fa-plus-circle mr-2" />Ajout</button>*/}
            </div>{/* /.container-fluid */}
        </div>

          {/*le datatable*/}
          <div className="card">
              <div className="card-body">
              <div>
                  <Paper
                      component="form"
                      sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 150 }}  >
                      <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          placeholder="Rechercher"
                          inputProps={{ 'aria-label': 'Rechercher' }}
                          onChange={(e)=>{
                              e.preventDefault();
                              setSearchField(e.target.value);
                          }}
                          value={searchField}
                      />
                      <IconButton  onClick={() => {
                          rechercheindb()
                      }} type="button" sx={{ p: '5px' }} aria-label="search">
                          <SearchIcon />
                      </IconButton>
                  </Paper>
                  <div  style={{top:20, zIndex:2, display:'flex', flexDirection:'row', alignItems:'center'}}>
                  <div  style={{position:'absolute', marginLeft:160, top:20, zIndex:2, display:'flex', flexDirection:'row', alignItems:'center'}}>
                      <div style={{marginRight:12}}>Début</div>
                      <div style={{}}>
                          <DatePicker locale="fr" showTimeSelect dateFormat="Pp" selected={startDate} onChange={(date) => setStartDate(date)} />
                      </div>
                  </div>
                  <div  style={{position:'absolute', marginLeft:425, top:20, zIndex:2, display:'flex', flexDirection:'row', alignItems:'center'}}>
                      <div style={{marginRight:12}}>Fin</div>
                      <div style={{}}>
                          <DatePicker locale="fr" showTimeSelect dateFormat="Pp"   selected={endDate} onChange={(date) => setEndDate(date)} />
                      </div>
                  </div>
                  <div  style={{position:'absolute', marginLeft:670, top:17, zIndex:2, display:'flex', flexDirection:'row', alignItems:'center'}}>
                  <button
                      onClick={(e)=>rechercherpardate(e)}
                      className={"btn btn-info px-4"}><i className="fas fa-search mr-2" />Rechercher</button>
                  </div>
                      <div  style={{position:'absolute', marginLeft:840, top:17, zIndex:2, display:'flex', flexDirection:'row', alignItems:'center'}}>
                          <button
                              onClick={(e)=>excel(e)}
                              className={"btn btn-info px-4"}>Excel</button>
                      </div>
                      {/*<div  style={{position:'absolute', marginLeft:1015, top:17, zIndex:2, display:'flex', flexDirection:'row', alignItems:'center'}}>*/}
                      {/*    <button*/}
                      {/*        onClick={(e)=>pdf(e)}*/}
                      {/*        className={"btn btn-info px-4"}>Pdf</button>*/}
                      {/*</div>*/}
                  </div>

              </div>
                  <DataTable
                      pagination
                     // selectableRowsComponent={Checkbox}
                      selectableRowsComponentProps={selectProps}
                     // sortIcon={sortIcon}
                      dense
                      //{...props}
                      //title="Administration"
                     // theme={'dark'}
                     //  columns={columns}
                      columns={columns(handleButtonColumn)}

                      data={tables}
                      // selectableRows
                      striped
                      highlightOnHover
                      persistTableHead
                      fixedHeader={false}
                      progressPending={false}
                      noDataComponent={"Aucune données à afficher"}
                      onRowClicked={(row, event) => {console.log('row clicked', row);
                          // setnommodif(row?.nom);
                          // setadressemodif(row?.adresse);
                          // setcommentairemodif(row?.commentaire);
                          // setshowmodalmodif(true);
                          // setidmodif(row?.id);
                      }}
                      // onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {console.log(' onChangePage')}}
                      // onChangePage={(page, totalRows)  => {console.log(' onChangePage')}}
                      // onRowMouseEnter={(row, event) => {console.log('row clicked')}}
                      // expandableRows={false}
                      // expandableRowsComponent={() => {console.log('row clicked')}}
                      paginationComponentOptions={{ rowsPerPageText: 'Lignes par page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                    //  actions={actionsMemo}
                  />
              </div>
              {/*fin body*/}
          </div>

          {/*<Modal*/}
          {/*    open={isshowmodal}*/}
          {/*    onClose={()=>setshowmodal(false)}*/}
          {/*    aria-labelledby="modal-modal-title"*/}
          {/*    aria-describedby="modal-modal-description"*/}
          {/*>*/}
          {/*    <div className="modal-dialog ">*/}
          {/*        <div className="modal-content">*/}
          {/*            <div className="modal-header">*/}
          {/*                <h4 className="modal-title">Nouveau groupe</h4>*/}
          {/*                <button onClick={()=>setshowmodal(false)} type="button" className="close" data-dismiss="modal" aria-label="Fermer">*/}
          {/*                    <span aria-hidden="true">&times;</span>*/}
          {/*                </button>*/}
          {/*            </div>*/}
          {/*            <Box component="form" onSubmit={savenewGroupe} sx={{ mt: 1 }}*/}
          {/*                 Validate*/}
          {/*                 autoComplete="on"   >*/}

          {/*            <div   className="modal-body">*/}

          {/*                    <TextField*/}
          {/*                        margin="normal"*/}
          {/*                        required*/}
          {/*                        fullWidth*/}
          {/*                        id="nom"*/}
          {/*                        label="Nom"*/}
          {/*                        name="nom"*/}
          {/*                        autoComplete="nom"*/}
          {/*                        autoFocus*/}
          {/*                    />*/}
          {/*                    <Collapse in={openSnack.isOpenNomError}>*/}
          {/*                        <Alert severity="error" action={*/}
          {/*                            <IconButton*/}
          {/*                                aria-label="fermer"*/}
          {/*                                color="inherit"*/}
          {/*                                size="small"*/}
          {/*                                onClick={() => {*/}
          {/*                                    setOpenSnack({...openSnack, isOpenNomError:false});*/}
          {/*                                }}*/}
          {/*                            >*/}
          {/*                                <CloseIcon fontSize="inherit" />*/}
          {/*                            </IconButton>*/}
          {/*                        }>{openSnack.txt}</Alert>*/}
          {/*                    </Collapse>*/}


          {/*                <TextField*/}
          {/*                    margin="normal"*/}
          {/*                //    required*/}
          {/*                    fullWidth*/}
          {/*                    id="adresse"*/}
          {/*                    label="Adresse"*/}
          {/*                    name="adresse"*/}
          {/*                    autoComplete="adresse"*/}
          {/*                    autoFocus*/}
          {/*                />*/}

          {/*                <TextField*/}
          {/*                    margin="normal"*/}
          {/*                //    required*/}
          {/*                    fullWidth*/}
          {/*                    id="commentaire"*/}
          {/*                    label="Commentaire"*/}
          {/*                    name="commentaire"*/}
          {/*                    autoComplete="commentaire"*/}
          {/*                    autoFocus*/}
          {/*                />*/}


          {/*            </div>*/}
          {/*            <div className="modal-footer d-flex flex-row justify-content-between">*/}
          {/*                <button onClick={()=>setshowmodal(false)} type="button" className="btn btn-default" data-dismiss="modal">Fermer</button>*/}
          {/*                <Button*/}
          {/*                    onPress = {savenewGroupe}*/}
          {/*                    type="submit"*/}
          {/*                    // fullWidth*/}
          {/*                    variant="contained"*/}
          {/*                    // sx={{ mt: 3, mb: 2 }}*/}
          {/*                    startIcon={*/}
          {/*                        isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />*/}
          {/*                       : <SaveIcon fontSize="inherit" />*/}
          {/*                    }*/}
          {/*                >*/}
          {/*                    Enregistrer*/}
          {/*                </Button>*/}
          {/*            </div>*/}
          {/*            </Box>*/}

          {/*        </div>*/}
          {/*    </div>*/}
          {/*</Modal>*/}
          <Modal
              open={isshowmodalmodif}
              onClose={()=>setshowmodalmodif(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
          >
              <div className="modal-dialog ">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h4 className="modal-title">Modification groupe</h4>
                          <button onClick={()=>setshowmodalmodif(false)} type="button" className="close" data-dismiss="modal" aria-label="Fermer">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>

                      {/*<Box component="form" onSubmit={modifGroupe} sx={{ mt: 1 }}*/}
                      {/*     Validate*/}
                      {/*     autoComplete="on"   >*/}

                      {/*    <div   className="modal-body">*/}

                      {/*        <TextField*/}
                      {/*            margin="normal"*/}
                      {/*            required*/}
                      {/*            fullWidth*/}
                      {/*            id="nom"*/}
                      {/*            label="Nom"*/}
                      {/*            name="nom"*/}
                      {/*            autoComplete="nom"*/}
                      {/*            autoFocus*/}
                      {/*            value={nommodif}*/}
                      {/*            onChange={(e)=>setnommodif(e.target.value)}*/}
                      {/*        />*/}

                      {/*        <Collapse in={openSnack.isOpenNomError}>*/}
                      {/*            <Alert severity="error" action={*/}
                      {/*                <IconButton*/}
                      {/*                    aria-label="fermer"*/}
                      {/*                    color="inherit"*/}
                      {/*                    size="small"*/}
                      {/*                    onClick={() => {*/}
                      {/*                        setOpenSnack({...openSnack, isOpenNomError:false});*/}
                      {/*                    }}*/}
                      {/*                >*/}
                      {/*                    <CloseIcon fontSize="inherit" />*/}
                      {/*                </IconButton>*/}
                      {/*            }>{openSnack.txt}</Alert>*/}
                      {/*        </Collapse>*/}
                      {/*        <TextField*/}
                      {/*            margin="normal"*/}
                      {/*            //    required*/}
                      {/*            fullWidth*/}
                      {/*            id="adresse"*/}
                      {/*            label="Adresse"*/}
                      {/*            name="adresse"*/}
                      {/*            autoComplete="adresse"*/}
                      {/*            value={adressemodif}*/}
                      {/*            onChange={(e)=>setadressemodif(e.target.value)}*/}
                      {/*        />*/}
                      {/*        <TextField*/}
                      {/*            margin="normal"*/}
                      {/*            //    required*/}
                      {/*            fullWidth*/}
                      {/*            id="commentaire"*/}
                      {/*            label="Commentaire"*/}
                      {/*            name="commentaire"*/}
                      {/*            autoComplete="commentaire"*/}
                      {/*            value={commentairemodif}*/}
                      {/*            onChange={(e)=>setcommentairemodif(e.target.value)}*/}
                      {/*        />*/}
                      {/*    </div>*/}
                      {/*    <div className="modal-footer d-flex flex-row justify-content-between">*/}
                      {/*        <button onClick={()=>setshowmodalmodif(false)} type="button" className="btn btn-default" data-dismiss="modal">Fermer</button>*/}
                      {/*        <Button*/}
                      {/*            onPress = {modifGroupe}*/}
                      {/*            type="submit"*/}
                      {/*            // fullWidth*/}
                      {/*            variant="contained"*/}
                      {/*            // sx={{ mt: 3, mb: 2 }}*/}
                      {/*            startIcon={*/}
                      {/*                isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />*/}
                      {/*                    : <SaveIcon fontSize="inherit" />*/}
                      {/*            }*/}
                      {/*        >*/}
                      {/*            Enregistrer*/}
                      {/*        </Button>*/}
                      {/*    </div>*/}
                      {/*</Box>*/}

                  </div>
              </div>
          </Modal>
          <Snackbar
              open={openToast.isOpenToast}
              autoHideDuration={6000}
              //onClose={()=>setOpenToast({...openToast, isOpenToast:false})}
              message={openToast.txt}
              action={<React.Fragment>
                  <IconButton
                      size="small"
                      aria-label="fermer"
                      color="inherit"
                      onClick={(event, reason) => {
                          if (reason === 'clickaway') {
                              return;
                          }
                          setOpenToast(false);
                      }}
                  >
                      <CloseIcon fontSize="small" />
                  </IconButton>
              </React.Fragment>}
          />
      </div>
      </LoadingOverlay>
  )
};
