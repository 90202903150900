import React, { createContext, useState} from 'react'
import Header from "../Header";
import Menu from "../Menu";
import {Vehicule} from "./Centre";
import Footer from "../Footer";
import { useParams } from "react-router-dom"



function ListerVehiculeParSite() {
    const { id } = useParams();

    return (
        <div className="wrapper">
            <Header/>
            <Menu/>
            <Vehicule idsite={id} />
            <Footer/>
        </div>
    );
}

export default ListerVehiculeParSite;
