import React, {createContext, useEffect, useState} from 'react'
import TextField from "@mui/material/TextField/TextField";
import Box from "@mui/material/Box";
import {helpers} from "../../services/api/helpers";
import dayjs from 'dayjs';
import Button from "@mui/material/Button";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import moment from "moment";
import InputBase from "@mui/material/InputBase/InputBase";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const AjouterModifPlagehoraire = (props) => {
    // const [value1, setValue1] = React.useState(dayjs('2022-04-07'));
    const [nom, setNom] = React.useState("");
    const [value1, setValue1] = React.useState(new Date());
    var todayplus1 = new Date();
    todayplus1.setHours(todayplus1.getHours() + 1);
    const [value2, setValue2] = React.useState(todayplus1);
    const [islundi, setLundi] = React.useState(true);
    const [ismardi, setMardi] = React.useState(true);
    const [ismercredi, setMercredi] = React.useState(true);
    const [isjeudi, setJeudi] = React.useState(true);
    const [isvendredi, setVendredi] = React.useState(true);
    const [issamedi, setSamedi] = React.useState(true);
    const [isdimanche, setDimanche] = React.useState(true);

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    useEffect(()=>{
        console.log('use effect 1');
        if(props?.variabmodif && props.isshowmodif === true){
            const variabmodif =  props?.variabmodif;
            setNom(variabmodif.nom);
            setValue1(new Date(variabmodif.debut));
            setValue2(new Date(variabmodif.fin));
            setLundi(variabmodif.lundi);
            setMardi(variabmodif.mardi);
            setMercredi(variabmodif.mercredi);
            setJeudi(variabmodif.jeudi);
            setVendredi(variabmodif.vendredi);
            setSamedi(variabmodif.samedi);
            setDimanche(variabmodif.dimanche);
        }else{
            setNom("");
            setValue1(new Date());
            setValue2(todayplus1);
            setLundi(true);
            setMardi(true);
            setMercredi(true);
            setJeudi(true);
            setVendredi(true);
            setSamedi(true);
            setDimanche(true);
        }

    },[]);
    // useEffect(()=>{
    //     console.log('props.switchPlagehoraire',props?.variabmodif,props.switchPlagehoraire)
    //     if(props?.iss === false && props.switchPlagehoraire == "Ajouter"){
    //         setNom("");
    //         setValue1(new Date());
    //         setValue2(todayplus1);
    //         setLundi(true);
    //         setMardi(true);
    //         setMercredi(true);
    //         setJeudi(true);
    //         setVendredi(true);
    //         setSamedi(true);
    //         setDimanche(true);
    //     }
    // },[props.switchPlagehoraire]);



    const modifierPlagehoraire = async (e)=> {
        e.preventDefault();
        if(nom.trim() === "")
        {
            props.setOpenToast({
                isOpenToast: true,
                txt: "Veuillez ajouter un nom à votre plage horaire.",
            });
            setTimeout(()=>{ props.setOpenToast({ isOpenToast: false  }); },6000);
            return;
        }
        handleClickOpen()
    };


    const modifierplagehoraire = async (e)=> {
        e.preventDefault();
        const d0 = moment(new Date(value1)).format('HH:mm');
        const d1 = moment(new Date(value2)).format('HH:mm');
        let dataX = {
            nom: nom,
            debutformated: d0,
            debut: await new Date(value1),
            finformated: d1,
            fin: await new Date(value2),
            lundi: islundi,
            mardi: ismardi,
            mercredi: ismercredi,
            jeudi: isjeudi,
            vendredi: isvendredi,
            samedi: issamedi,
            dimanche: isdimanche,
            id: props.variabmodif.id
        };
        console.log('data x', dataX);

        props.setParentLoading(true);

        helpers
            .modifierplagehoraire(dataX)
            .then(async r => {
                props.setParentLoading(false);
                if (r.status === 200) {
                    props.getPlagehoraire().catch(console.error);
                    props.setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ props.setOpenToast({ isOpenToast: false  }); },6000);
                    props.setSwitchplagehoraire({target:{value:'Afficher'}});
                    props.setshowmodif(false);
                } else {
                    props.setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ props.setOpenToast({ isOpenToast: false  }); },6000);
                    props.setSwitchplagehoraire({target:{value:'Afficher'}});
                    props.setshowmodif(false);
                }
            })
            .catch(e => {
                props.setParentLoading(false);
                props.setSwitchplagehoraire({target:{value:'Afficher'}});
                props.setshowmodif(false);
            });

    };

    const newPlagehoraire = async (e)=> {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        //const nom = data.get('nom');
        if(nom.trim() === "")
        {
            props.setOpenToast({
                isOpenToast: true,
                txt: "Veuillez ajouter un nom à votre plage horaire.",
            });
            setTimeout(()=>{ props.setOpenToast({ isOpenToast: false  }); },6000);

            return;
        }
        // const d = new Date(value1).toLocaleDateString('fr-FR');

        const d0 = moment(new Date(value1)).format('HH:mm');
        const d1 = moment(new Date(value2)).format('HH:mm');
        let dataX = {
            nom: nom,
            debutformated: d0,
            debut: await new Date(value1),
            finformated: d1,
            fin: await new Date(value2),
            lundi: islundi,
            mardi: ismardi,
            mercredi: ismercredi,
            jeudi: isjeudi,
            vendredi: isvendredi,
            samedi: issamedi,
            dimanche: isdimanche
        };
        console.log('data x', dataX);

        props.setParentLoading(true);

        helpers
            .ajouterplagehoraire(dataX)
            .then(async r => {
                props.setParentLoading(false);
                if (r.status === 200) {
                    props.getPlagehoraire().catch(console.error);
                    props.setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ props.setOpenToast({ isOpenToast: false  }); },6000);
                    props.setSwitchplagehoraire({target:{value:'Afficher'}})
                } else {
                    props.setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ props.setOpenToast({ isOpenToast: false  }); },6000);
                    props.setSwitchplagehoraire({target:{value:'Afficher'}})
                }
            })
            .catch(e => {
                props.setParentLoading(false);
                props.setSwitchplagehoraire({target:{value:'Afficher'}})
            });
    };
    const handleLundi =(e)=> {
        setLundi(e.target.checked);
    };
    const handleMardi =(e)=> {
        setMardi(e.target.checked);
    };
    const handleMercredi =(e)=> {
        setMercredi(e.target.checked);
    };
    const handleJeudi =(e)=> {
        setJeudi(e.target.checked);
    };
    const handleVendredi =(e)=> {
        setVendredi(e.target.checked);
    };
    const handleSamedi =(e)=> {
        setSamedi(e.target.checked);
    };
    const handleDimanche =(e)=> {
        setDimanche(e.target.checked);
    };

    return (
        <Box component="form" onSubmit={props.isshowmodif ? modifierPlagehoraire : newPlagehoraire}  sx={{ mt: 1 }}
             Validate
             autoComplete="on"   >
        <div className="column" >
            <div style={{marginLeft:20}} className="row">
                <TextField
                    margin="normal"
                    required
                   fullWidth
                    id="nom"
                    label="Identification du plage horaire"
                    name="nom"
                    onChange={(e)=>{
                        e.preventDefault();
                        setNom(e.target.value);
                    }}
                    value={nom}
                    //  autoComplete="nom"
                />
            </div>
            <div style={{marginLeft:20, fontSize:23, fontWeight:'bold', color:'#169cb1'}} className="row">
               Début
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticTimePicker
                  //  orientation="landscape"
                    displayStaticWrapperAs="mobile"
                    value={value1}
                    onChange={(newValue) => {
                        setValue1(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
            <div style={{marginLeft:20, fontSize:23, fontWeight:'bold', color:'#169cb1'}} className="row">
                Fin
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticTimePicker
                    //  orientation="landscape"
                    displayStaticWrapperAs="mobile"
                    value={value2}
                    onChange={(newValue) => {
                        setValue2(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>

            <div style={{flexDirection:'row', alignItems:'center'}} className="row">
            <div style={{marginLeft:12, fontSize:12, fontWeight:'bold', color:'#3e79b1'}} >
                <FormControlLabel
                    control={<Checkbox onChange={handleLundi}  checked={islundi}  defaultChecked={islundi} color="success" />}
                    label="Lundi"
                />
            </div>
                <div style={{marginLeft:12, fontSize:12, fontWeight:'bold', color:'#3e79b1'}} >
                    <FormControlLabel
                        control={<Checkbox onChange={handleMardi}  checked={ismardi}  defaultChecked={ismardi} color="success" />}
                        label="Mardi"
                    />
                </div>
                <div style={{marginLeft:12, fontSize:12, fontWeight:'bold', color:'#3e79b1'}} >
                    <FormControlLabel
                        control={<Checkbox onChange={handleMercredi}  checked={ismercredi}  defaultChecked={ismercredi} color="success" />}
                        label="Mercredi"
                    />
                </div>
                <div style={{marginLeft:12, fontSize:12, fontWeight:'bold', color:'#3e79b1'}} >
                    <FormControlLabel
                        control={<Checkbox onChange={handleJeudi}  checked={isjeudi} defaultChecked={isjeudi} color="success" />}
                        label="Jeudi"
                    />
                </div>
                <div style={{marginLeft:12, fontSize:12, fontWeight:'bold', color:'#3e79b1'}} >
                    <FormControlLabel
                        control={<Checkbox onChange={handleVendredi} checked={isvendredi}  defaultChecked={isvendredi} color="success" />}
                        label="Vendredi"
                    />
                </div>
                <div style={{marginLeft:12, fontSize:12, fontWeight:'bold', color:'#3e79b1'}} >
                    <FormControlLabel
                        control={<Checkbox onChange={handleSamedi} checked={issamedi} defaultChecked={issamedi} color="success" />}
                        label="Samedi"
                    />
                </div>
                <div style={{marginLeft:12, fontSize:12, fontWeight:'bold', color:'#3e79b1'}} >
                    <FormControlLabel
                        control={<Checkbox onChange={handleDimanche} checked={isdimanche} defaultChecked={isdimanche} value={'Dimanche'} color="success" />}
                        label="Dimanche"
                    />
                </div>
            </div>
            <div style={{marginTop:15}} className="row justify-content-end">
                <button
                    onPress = {props.isshowmodif ? modifierPlagehoraire : newPlagehoraire}
                   // onClick={newUsager}
                    className={"btn btn-success px-4"}><i className="fas fa-plus-circle mr-2" />Enregistrer</button>
            </div>
        </div>

            <Dialog
                open={open}
                onClose={handleClose}
               // PaperComponent={PaperComponent}
               // aria-labelledby="draggable-dialog-title"
                //scroll={'body'}
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Modification plage horaire
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Voulez-vous vraiment éfféctuer la modification?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Annuler
                    </Button>
                    <Button onClick={modifierplagehoraire}>Enregistrer</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AjouterModifPlagehoraire;
