import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField/TextField";
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import {Alert} from "../Authentication/index";
import SaveIcon from '@mui/icons-material/Save';
import FormControlLabel from '@mui/material/FormControlLabel';
import {helpers} from "../../services/api/helpers";
import ls from "local-storage";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import {ThemeProvider} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import LoadingOverlay from "react-loading-overlay";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Checkbox from "@mui/material/Checkbox/Checkbox";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {  useNavigate    } from "react-router-dom"
let isAdmin = ls.get('xxxx');

const validateEmail = (email) => {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};


let AllGroupes_ = [
    {
        value: 'SUPERADMIN',
        label: 'SUPER ADMIN',
    }
];

let columns = [];
if(isAdmin === "AAAA" ) {
     columns = [
        {
            name: 'Entreprise',
            selector: row => row.entreprise,
            sortable: true,
            wrap: true,
            style: {fontSize: 16},
            cell: d => {
                if (d.type != 'SUPERADMIN')
                    return <span>{(d.site.nom)}</span>;
                else
                    return <span>{(d.type)}</span>
            },
        },
        {
            name: 'Nom & Prenoms',
            selector: row => row.nom + ' ' + row.prenom,
            cell: d => <span>{(d.nom != null ? d.nom : '') + ' ' + (d.prenom != null ? d.prenom : '')}</span>,
            wrap: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
        },
        {
            name: 'Téléphone',
            selector: row => row.telephone,
        },
        {
            name: 'Adresse',
            selector: row => row.adresse,
            sortable: true,

        },
    ];
}else{
    columns = [
        {
            name: 'Nom & Prenoms',
            selector: row => row.nom + ' ' + row.prenom,
            cell: d => <span>{(d.nom != null ? d.nom : '') + ' ' + (d.prenom != null ? d.prenom : '')}</span>,
            wrap: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
        },
        {
            name: 'Téléphone',
            selector: row => row.telephone,
        },
        {
            name: 'Adresse',
            selector: row => row.adresse,
            sortable: true,

        },
    ];
}

export const Centre = () => {
    const navigate = useNavigate();

    const [openModalSuppression, setopenModalSuppression] = useState(false);
    const [selected, setSelected] = useState({ allSelected:false, selectedCount:0, selectedRows:[] });
    const [isModifPassword, setIsModifPassword] = useState(false);
    const [isshowmodal, setshowmodal] = useState(false);
    const [isshowmodalModif, setshowmodalModif] = useState(false);
    const [modif, setItemModif] = useState({});
    const [isLoadingSave, setLoadingSave] = useState(false);
    const [openSnack, setOpenSnack] = useState({
        isOpenNomError:false,
        isOpenPrenomError:false,
        isOpenEmailError:false,
        isOpenTelephoneError:false,
        isOpenAdresseError:false,
        isOpenPasswordError:false,
        isOpenNewPasswordError:false,
        txt:''});
    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [AllGroupes, setAllGroupes] = React.useState(AllGroupes_);
    const [tables, setTables] = React.useState({columns:columns});
    const [isAdmin_, setAdmin] = React.useState('SUPERADMIN');
    const [isLoading, setLoading] = React.useState(false);
    const [searchField, setSearchField] = useState("");



    const suppression = async (e) => {
        e.preventDefault();
        if(selected.selectedCount==0)
            return;
        setLoading(true);
        helpers
            .supprAdmin(selected.selectedRows)
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    helpers
                        .getadmins()
                        .then(async r => {
                            setLoading(false);
                            if (r.status === 200) {
                                setTables({...tables, data: r.data});
                            }
                        });
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setopenModalSuppression(false);
                    if(r.rest){
                        await ls.remove('userToken');
                        await ls.remove('xxxx');
                        navigate("/", { replace: true });
                    }
                }else{
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setopenModalSuppression(false)
                }
            }).catch((r)=>{
                setOpenToast({
            isOpenToast: true,
            txt: r.message,
        });
            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
        })
    };

    function handleModifPassword(value){
        setIsModifPassword(value.target.checked)
    }

        useEffect(()=>{
        const filteredPersons = tables.data && tables.data.filter(
            item => {
                return (
                    item
                        .nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item
                        .type
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                );
            }
        );
        if(searchField == ''){
            getAdmins().catch(console.error);
        }
        setTables({...tables, data: filteredPersons});
    },[searchField]);

    function rechercheindb(){

        if(searchField != ''){
            return;
        }
        return;

    }



   async function newAdministration(e) {
        e.preventDefault();
        if(isAdmin !== "AAAA" ){
            const user_ = await JSON.parse(await ls.get('user'));
            setAdmin(user_?.siteId);
        }
        setshowmodal(true);
    }

    const getAdmins = async () => {
        helpers
            .getadmins()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setTables({...tables, data: r.data});
                }
            })
    };

    useEffect(()=>{
        setLoading(true);
        // setTimeout(()=>{getAdmins().catch(console.error)},3000);
        getAdmins().catch(console.error);
        //
        const getAllGroupes = async () => {
            helpers
                .getAllSites()
                .then(async r => {
                    setLoading(false);
                    if (r.status === 200) {
                        const AllGroupesici = [...AllGroupes];
                        for(let i = 0; i<r.data.length; i++){
                            AllGroupesici.push({value:r.data[i].id, label:r.data[i].nom})
                        }
                       await setAllGroupes(AllGroupesici);
                    }
                })
        };
        // setTimeout(()=>getAllGroupes().catch(console.error),3000);
        getAllGroupes().catch(console.error)
    },[]);


    const modificationAdministration =async (e)=> {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const nom = data.get('nom');
        const prenom = data.get('prenom');
        const email = data.get('email');
        const telephone = data.get('telephone');
        const adresse = data.get('adresse');
        const password = data.get('password');
        const newpassword = data.get('newpassword');

        setOpenSnack({...openSnack, isOpenPasswordError:false});
        setLoadingSave(true);
        let dataX = {
            id:modif.id,
            type: isAdmin_,
            nom: nom,
            prenom: prenom,
            email: email,
            telephone: telephone,
            adresse: adresse,
            password: password,
            newpassword: newpassword,
        };
        if(dataX.type != 'SUPERADMIN'){
            dataX.siteId = isAdmin_
        }
        if(isModifPassword){
        helpers
            .adminupdate(dataX)
            .then(async r => {
                setLoadingSave(false);
                if (r.status === 200) {
                    getAdmins().catch(console.error);
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setshowmodalModif(false);
                    setIsModifPassword(false);

                } else {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                }
            })
            .catch(e => {
                setLoadingSave(false);
            });
       } else {

            await delete dataX.password;
            await delete dataX.newpassword;

            helpers
                .adminupdateWithoutPassword(dataX)
                .then(async r => {
                    setLoadingSave(false);
                    if (r.status === 200) {
                        getAdmins().catch(console.error);
                        setOpenToast({
                            isOpenToast: true,
                            txt: r.message,
                        });
                        setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                        setshowmodalModif(false);
                        setIsModifPassword(false);

                    } else {
                        setOpenToast({
                            isOpenToast: true,
                            txt: r.message,
                        });
                        setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    }
                })
                .catch(e => {
                    setLoadingSave(false);
                });
        }

    };

    const savenewAdministration =(e)=> {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const nom = data.get('nom');
        const prenom = data.get('prenom');
        const email = data.get('email');
        const telephone = data.get('telephone');
        const adresse = data.get('adresse');
        const password = data.get('password');
            if(nom == ''){
                setOpenSnack({...openSnack, isOpenNomError:true,txt:'Veuillez insérer le nom de l\'administrateur'});
                return;
            }
           setOpenSnack({...openSnack, isOpenNomError:false});
        if(prenom == ''){
            setOpenSnack({...openSnack, isOpenPrenomError:true,txt:'Veuillez insérer le prénom de l\'administrateur'});
            return;
        }
        setOpenSnack({...openSnack, isOpenPrenomError:false});
        if(email == ''){
            setOpenSnack({...openSnack, isOpenEmailError:true,txt:'Veuillez insérer l\'email de l\'administrateur'});
            return;
        }
        setOpenSnack({...openSnack, isOpenEmailError:false});
        if (validateEmail(email) == 'null' || validateEmail(email) == false) {
            setOpenSnack({...openSnack, isOpenEmailError:true,txt:'Veuillez insérer un e-mail valide'});
        }
        setOpenSnack({...openSnack, isOpenEmailError:false});

        if(telephone == ''){
            setOpenSnack({...openSnack, isOpenTelephoneError:true,txt:'Veuillez insérer le téléphone de l\'administrateur'});
            return;
        }
        setOpenSnack({...openSnack, isOpenTelephoneError:false});
        if(password == ''){
            setOpenSnack({...openSnack, isOpenPasswordError:true,txt:'Veuillez insérer le mot de passe de l\'administrateur'});
            return;
        }
        setOpenSnack({...openSnack, isOpenPasswordError:false});

        setLoadingSave(true);
        let dataX = {
            type: isAdmin_,
            nom: nom,
            prenom: prenom,
            email: email,
            telephone: telephone,
            adresse: adresse,
            password: password
        };
        if(dataX.type != 'SUPERADMIN'){
            dataX.siteId = isAdmin_
        }
        helpers
            .adminsave(dataX)
            .then(async r => {
                setLoadingSave(false);
                if (r.status === 200) {
                    getAdmins().catch(console.error);
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setshowmodal(false);

                } else {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                }
            })
            .catch(e => {
                setLoadingSave(false);
            });
    };
    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };
    



  return (
      <LoadingOverlay
          active={isLoading}
          spinner
          text='Chargement...'
      >
      <div className="content-wrapper">
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0 text-dark">Administration</h1>
                    </div>{/* /.col */}
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item">
                                    Admin
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/dashboard"  className="link">
                                    Parc access
                                </Link>
                            </li>
                        </ol>
                    </div>{/* /.col */}
                </div>{/* /.row */}
                <button
                    onClick={(e)=>newAdministration(e)}
                    className={"btn btn-success px-4"}><i className="fas fa-plus-circle mr-2" />Ajout</button>
            </div>{/* /.container-fluid */}
        </div>

          {/*le datatable*/}
          <div className="card">
              {/*<div className="card-header">*/}
              {/*    <h3 className="card-title">DataTable with default features</h3>*/}
              {/*</div>*/}

              <div className="card-body">
                  <div className="d-flex flex-row justify-content-between">
                      <button
                          onClick={(e)=>setopenModalSuppression(true)}
                          className={"btn btn-danger px-4"}><i className="fas fa-trash mr-2" />Supprimer</button>
                  <Paper
                      component="form"
                      sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}
                  >
                      <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          placeholder="Rechercher"
                          inputProps={{ 'aria-label': 'Rechercher' }}
                          onChange={(e)=>{
                              e.preventDefault();
                              setSearchField(e.target.value);
                          }}
                          value={searchField}
                      />
                      <IconButton  onClick={() => {
                          rechercheindb()
                      }} type="button" sx={{ p: '5px' }} aria-label="search">
                          <SearchIcon />
                      </IconButton>
                  </Paper>
                  </div>

                  <DataTableExtensions
                      {...tables}
                      filter={false}
                      //filterHidden={false}
                     print={false}
                     export={false}
                     filterPlaceholder={'Rechercher'}
                  >
                  <DataTable
                      pagination
                     // selectableRowsComponent={Checkbox}
                      selectableRowsComponentProps={selectProps}
                     // sortIcon={sortIcon}
                      dense
                      //{...props}
                      //title="Administration"
                     // theme={'dark'}
                      // columns={columns}
                      // data={data}
                      selectableRows
                      striped
                      highlightOnHover
                      persistTableHead
                      fixedHeader={false}
                      progressPending={false}
                      noDataComponent={"Aucune données à afficher"}
                      onRowClicked={(row, event) => {
                          setItemModif(row);
                          if(row.type =='SUPERADMIN')
                              setAdmin('SUPERADMIN');
                          else
                          setAdmin(row.site.id);
                          if(modif.nom)
                              setshowmodalModif(true)
                              // setTimeout(()=>setshowmodalModif(true),1000);
                      }}
                      onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
                          const se = { allSelected:allSelected, selectedCount:selectedCount, selectedRows:selectedRows };
                            setSelected(se)
                      }}
                      // onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {console.log(' onChangePage')}}
                      // onChangePage={(page, totalRows)  => {console.log(' onChangePage')}}
                      // onRowMouseEnter={(row, event) => {console.log('row clicked')}}
                      // expandableRows={false}
                      // expandableRowsComponent={() => {console.log('row clicked')}}
                      paginationComponentOptions={{ rowsPerPageText: 'Lignes par page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                  />
                  </DataTableExtensions>
              </div>
              {/*fin body*/}
          </div>

          <Modal
              open={isshowmodal}
              onClose={()=>setshowmodal(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
          >
              <div className="modal-dialog ">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h4 className="modal-title">Nouveau admin</h4>
                          <button onClick={()=>setshowmodal(false)} type="button" className="close" data-dismiss="modal" aria-label="Fermer">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>
                      <Box component="form" onSubmit={savenewAdministration} sx={{ mt: 1 }}
                           Validate
                           autoComplete="on"   >

                      <div   className="modal-body">
                          { isAdmin === "AAAA" && <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="type"
                              select
                              label="Type"
                              value={isAdmin_}
                              onChange={(event) => {
                                  setAdmin(event.target.value);
                              }}
                              //helperText="S'il vous plait choisissez le type de l'admin"
                          >
                              {AllGroupes.map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                      {option.label}
                                  </MenuItem>
                              ))}
                          </TextField>}


                              <TextField
                                  margin="normal"
                                  required
                                  fullWidth
                                  id="nom"
                                  label="Nom"
                                  name="nom"
                                  autoComplete="nom"
                                  autoFocus
                              />
                              <Collapse in={openSnack.isOpenNomError}>
                                  <Alert severity="error" action={
                                      <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                              setOpenSnack({...openSnack, isOpenNomError:false});
                                          }}
                                      >
                                          <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                  }>{openSnack.txt}</Alert>
                              </Collapse>

                          <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="prenom"
                              label="Prénom"
                              name="prenom"
                              autoComplete="prenom"
                              autoFocus
                          />
                          <Collapse in={openSnack.isOpenPrenomError}>
                              <Alert severity="error" action={
                                  <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                          setOpenSnack({...openSnack, isOpenPrenomError:false});
                                      }}
                                  >
                                      <CloseIcon fontSize="inherit" />
                                  </IconButton>
                              }>{openSnack.txt}</Alert>
                          </Collapse>


                          <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="email"
                              label="Email"
                              name="email"
                              autoComplete="email"
                              autoFocus
                              //error
                              type={'email'}
                          />
                          <Collapse in={openSnack.isOpenEmailError}>
                              <Alert severity="error" action={
                                  <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                          setOpenSnack({...openSnack, isOpenEmailError:false});
                                      }}
                                  >
                                      <CloseIcon fontSize="inherit" />
                                  </IconButton>
                              }>{openSnack.txt}</Alert>
                          </Collapse>

                          <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="telephone"
                              label="Téléphone"
                              name="telephone"
                              autoComplete="telephone"
                              autoFocus
                              type={'tel'}

                          />
                          <Collapse in={openSnack.isOpenTelephoneError}>
                              <Alert severity="error" action={
                                  <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                          setOpenSnack({...openSnack, isOpenTelephoneError:false});
                                      }}
                                  >
                                      <CloseIcon fontSize="inherit" />
                                  </IconButton>
                              }>{openSnack.txt}</Alert>
                          </Collapse>

                          <TextField
                              margin="normal"
                            //  required
                              fullWidth
                              id="adresse"
                              label="Adresse"
                              name="adresse"
                              autoComplete="adresse"
                              autoFocus
                              multiline
                          />

                          <TextField
                              margin="normal"
                              required
                              fullWidth
                              id="password"
                              label="Mot de passe"
                              name="password"
                              autoComplete="password"
                              autoFocus
                          />
                          <Collapse in={openSnack.isOpenPasswordError}>
                              <Alert severity="error" action={
                                  <IconButton
                                      aria-label="close"
                                      color="inherit"
                                      size="small"
                                      onClick={() => {
                                          setOpenSnack({...openSnack, isOpenPasswordError:false});
                                      }}
                                  >
                                      <CloseIcon fontSize="inherit" />
                                  </IconButton>
                              }>{openSnack.txt}</Alert>
                          </Collapse>

                      </div>
                      <div className="modal-footer d-flex flex-row justify-content-between">
                          <button onClick={()=>setshowmodal(false)} type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                          <Button
                              onPress = {savenewAdministration}
                              type="submit"
                              // fullWidth
                              variant="contained"
                              // sx={{ mt: 3, mb: 2 }}
                              startIcon={
                                  isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                 : <SaveIcon fontSize="inherit" />
                              }
                          >
                              Enregistrer
                          </Button>
                      </div>
                      </Box>

                  </div>
              </div>


          </Modal>

          <Modal
              open={isshowmodalModif}
              onClose={()=>{setshowmodalModif(false);setIsModifPassword(false);}}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
          >
              <div className="modal-dialog ">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h4 className="modal-title">Modification admin</h4>
                          <button onClick={()=>{setshowmodalModif(false); setIsModifPassword(false);}} type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                          </button>
                      </div>
                      <Box component="form" onSubmit={modificationAdministration} sx={{ mt: 1 }}
                           Validate
                           autoComplete="on"   >
                          <div   className="modal-body">
                              {isAdmin === "AAAA" && <TextField
                                  margin="normal"
                                  required
                                  fullWidth
                                  id="type"
                                  select
                                  label="Type"
                                  value={isAdmin_}
                                  onChange={(event) => {
                                      setAdmin(event.target.value);
                                  }}
                                  //helperText="S'il vous plait choisissez le type de l'admin"
                              >
                                  {AllGroupes.map((option) => (
                                      <MenuItem key={option.value} value={option.value}>
                                          {option.label}
                                      </MenuItem>
                                  ))}
                              </TextField>}


                              <TextField
                                  margin="normal"
                                  required
                                  fullWidth
                                  id="nom"
                                  label="Nom"
                                  name="nom"
                                  autoComplete="nom"
                                  autoFocus
                                  value={modif.nom}
                                  onChange={(value)=>{
                                      setItemModif({...modif, nom: value.target.value})
                                  }}
                              />
                              <Collapse in={openSnack.isOpenNomError}>
                                  <Alert severity="error" action={
                                      <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                              setOpenSnack({...openSnack, isOpenNomError:false});
                                          }}
                                      >
                                          <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                  }>{openSnack.txt}</Alert>
                              </Collapse>

                              <TextField
                                  margin="normal"
                                  required
                                  fullWidth
                                  id="prenom"
                                  label="Prénom"
                                  name="prenom"
                                  autoComplete="prenom"
                                  value={modif.prenom}
                                  onChange={(value)=>{
                                      setItemModif({...modif, prenom: value.target.value})
                                  }}
                              />
                              <Collapse in={openSnack.isOpenPrenomError}>
                                  <Alert severity="error" action={
                                      <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                              setOpenSnack({...openSnack, isOpenPrenomError:false});
                                          }}
                                      >
                                          <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                  }>{openSnack.txt}</Alert>
                              </Collapse>


                              <TextField
                                  margin="normal"
                                  required
                                  fullWidth
                                  id="email"
                                  label="Email"
                                  name="email"
                                  autoComplete="email"
                                  value={modif.email}
                                  onChange={(value)=>{
                                      setItemModif({...modif, email:value.target.value})
                                  }}

                              />
                              <Collapse in={openSnack.isOpenEmailError}>
                                  <Alert severity="error" action={
                                      <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                              setOpenSnack({...openSnack, isOpenEmailError:false});
                                          }}
                                      >
                                          <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                  }>{openSnack.txt}</Alert>
                              </Collapse>

                              <TextField
                                  margin="normal"
                                  required
                                  fullWidth
                                  id="telephone"
                                  label="Téléphone"
                                  name="telephone"
                                  autoComplete="telephone"
                                  value={modif.telephone}
                                  onChange={(value)=>{
                                      setItemModif({...modif, telephone:value.target.value})
                                  }}

                              />
                              <Collapse in={openSnack.isOpenTelephoneError}>
                                  <Alert severity="error" action={
                                      <IconButton
                                          aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                              setOpenSnack({...openSnack, isOpenTelephoneError:false});
                                          }}
                                      >
                                          <CloseIcon fontSize="inherit" />
                                      </IconButton>
                                  }>{openSnack.txt}</Alert>
                              </Collapse>

                              <TextField
                                  margin="normal"
                                  //  required
                                  fullWidth
                                  id="adresse"
                                  label="Adresse"
                                  name="adresse"
                                  autoComplete="adresse"
                                  autoFocus
                                  mutliline
                                  value={modif.adresse}
								  onChange={(value)=>{
                                    setItemModif({...modif, adresse:value.target.value})
                                  }}

                              />

                              <FormControlLabel
                                  control={<Checkbox onChange={handleModifPassword} value="remember" color="primary" />}
                                  label="Modifier le mot de passe"
                              />
                              {isModifPassword && (
                                  <div>
                                  <TextField
                                  margin="normal"
                                  required
                                  fullWidth
                                  id="password"
                                  label="Ancien mot de passe"
                                  name="password"
                                  //type="password"
                                  autoFocus
                              />
                                      <Collapse in={openSnack.isOpenPasswordError}>
                                          <Alert severity="error" action={
                                              <IconButton
                                                  aria-label="close"
                                                  color="inherit"
                                                  size="small"
                                                  onClick={() => {
                                                      setOpenSnack({...openSnack, isOpenPasswordError:false});
                                                  }}
                                              >
                                                  <CloseIcon fontSize="inherit" />
                                              </IconButton>
                                          }>{openSnack.txt}</Alert>
                                      </Collapse>
                                      <TextField
                                          margin="normal"
                                          required
                                          fullWidth
                                          id="newpassword"
                                          label="Nouveau mot de passe"
                                          name="newpassword"
                                          type="password"
                                          //autoFocus

                                      />
                                      <Collapse in={openSnack.isOpenNewPasswordError}>
                                          <Alert severity="error" action={
                                              <IconButton
                                                  aria-label="close"
                                                  color="inherit"
                                                  size="small"
                                                  onClick={() => {
                                                      setOpenSnack({...openSnack, isOpenNewPasswordError:false});
                                                  }}
                                              >
                                                  <CloseIcon fontSize="inherit" />
                                              </IconButton>
                                          }>{openSnack.txt}</Alert>
                                      </Collapse>
                                  </div>
                              )}


                          </div>
                          <div className="modal-footer d-flex flex-row justify-content-between">
                              <button onClick={()=>{setshowmodalModif(false); setIsModifPassword(false);}} type="button" className="btn btn-default" data-dismiss="modal">Fermer</button>
                              <Button
                                  onPress = {modificationAdministration}
                                  type="submit"
                                  // fullWidth
                                  variant="contained"
                                  // sx={{ mt: 3, mb: 2 }}
                                  startIcon={
                                      isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                          : <SaveIcon fontSize="inherit" />
                                  }
                              >
                                  Enregistrer
                              </Button>
                          </div>
                      </Box>

                  </div>
              </div>
          </Modal>

          <Dialog
              open={openModalSuppression}
              keepMounted
              onClose={()=>setopenModalSuppression(false)}
              aria-describedby="alert-dialog-slide-description"
          >
              <DialogTitle>{"Voulez-vous vraiment supprimer?"}</DialogTitle>
              <DialogActions>
                  <Button onClick={()=>setopenModalSuppression(false)}>Annuler</Button>
                  <Button onClick={suppression}>Ok</Button>
              </DialogActions>
          </Dialog>
          <Snackbar
              open={openToast.isOpenToast}
              autoHideDuration={6000}
              //onClose={()=>setOpenToast({...openToast, isOpenToast:false})}
              message={openToast.txt}
              action={<React.Fragment>
                  <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={(event, reason) => {
                          if (reason === 'clickaway') {
                              return;
                          }
                          setOpenToast(false);
                      }}
                  >
                      <CloseIcon fontSize="small" />
                  </IconButton>
              </React.Fragment>}
          />
      </div>
      </LoadingOverlay>
  )
};
