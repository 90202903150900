import React, {useState, useEffect, useCallback} from "react";
import {Link} from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField/TextField";
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import {Alert} from "../Authentication/index";
import SaveIcon from '@mui/icons-material/Save';
import FormControlLabel from '@mui/material/FormControlLabel';
import {helpers} from "../../services/api/helpers";
import ls from "local-storage";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import {ThemeProvider} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import LoadingOverlay from "react-loading-overlay";
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import AjouterUsagers from "./AjouterUsagers";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from "@mui/material/Checkbox/Checkbox";


const columns = [
    // {
    //     name: 'Choisir',
    //     selector: row => row.selected,
    //     cell: d => <span><FormControlLabel
    //         control={<Checkbox onChange={handleCheckItem} value={d.id} color="primary" />}
    //         label=""
    //     /></span>,
    //     sortable:true,
    //     style:{fontSize:16}
    // },
    {
        name: 'Nom & Prenoms',
        selector: row => row.nom + ' ' +row.prenom,
        cell: d => <span>{(d.nom != null ? d.nom : '') + ' ' + (d.prenom != null ? d.prenom : '')}</span>,
        wrap:true,
        sortable:true,
        style:{fontSize:16}
    },
    {
        name: 'Ville',
        selector: row => row.ville,
        wrap:true,
        sortable:true,
    },
    {
        name: 'Email',
        selector: row => row.email,
        wrap:true,
        sortable:true,
    },
    {
        name: 'Téléphone',
        selector: row => row.telephone,
        wrap:true,
    }
];


export const handleCheckItem = (item) => {
console.log('iiiiteeemm',item)
console.log('iiiiteeemm',item.target.value)
};

export const Centre = () => {

    const [isshowmodal, setshowmodal] = useState(false);
    const [isLoadingSave, setLoadingSave] = useState(false);
    const [openSnack, setOpenSnack] = useState({
        isOpenNomError:false,
        txt:''});
    const [openToast, setOpenToast] = useState({isOpenToast:false, txt:''});
    const [tables, setTables] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);
    const [searchField, setSearchField] = useState("");
    const [switchUsagers, setSwitchUsagers] = React.useState('Afficher');
    const [types, setTypes] = React.useState([]);
    const [type, setType] = React.useState("");
    const [marques, setMarques] = React.useState([]);
    const [marque, setMarque] = React.useState("");
    const [immatriculation, setImmatriculation] = React.useState("");
    const [commentVehicle, setCommentVehicle] = React.useState("");
    const [MarqueOnChange, setMarqueOnChange] = React.useState(null);
    const [TypeOnChange, setTypeOnChange] = React.useState(null);

    const handleSwitchUsagers = (event) => {
        setSwitchUsagers(event.target.value);
    };
    const setByChildLoading = (loading) => {
        setLoading(loading);
    };
    const setByChildOpenToast = (toast) => {
        setOpenToast(toast);
    };


    // const handleSelectChange =  (val) => {
    //   // // //  setSelectChange(val);
    //   // // //  const upd_obj = tables.findIndex((obj => obj.id == val.id));
    //   // // //  tables[upd_obj].selected = "Harry";
    //      console.log('the val',val);
    //   //   let tables_ = [...tables];
    //   //   // for (let i = 0; i<tables_.length; i++) {
    //   //   //     console.log(tables_[i].id, '--', val.selectedRows[0].id , ' ---', tables_[i].selected);
    //   //   //     if (tables_[i].id == val.selectedRows[0].id) {
    //   //   //         tables_[i].selected = true;
    //   //   //     }else{
    //   //   //         tables_[i].selected = false;
    //   //   //     }
    //   //   //     console.log( tables_[i].selected);
    //   //   // }
    //   //
    //   // setTables([...tables]);
    //   // console.log('valeur', tables);
    //   // //-------------------------------
    // };


    const handleSelectChange = React.useCallback(state => {

        // console.log('state.selectedRows',state.selectedRows);
        // console.log('state.selectedRows1',tables);
        let tables_ = [...tables];

        for (const i of   tables) {
            if (i.id == state.selectedRows[0].id) {
                i.selected = true;
            }else{
                i.selected = false;
            }
        }

    }, []);

    const [selectedRowss, setSelectedRows] = React.useState(false);
    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
        //ok miala androany 03/02/2023
        // let tables_ = [...tables];
        //
        // if(selectedRowss.length>0){
        //         for (let i = 0; i<tables_.length; i++) {
        //             console.log(tables_[i].id, '--', selectedRowss[0].id , ' ---', tables_[i].selected);
        //             if (tables_[i].id == selectedRowss[0].id) {
        //                 tables_[i].selected = true;
        //             }else{
        //                 tables_[i].selected = false;
        //             }
        //             console.log( tables_[i].selected);
        //         }
        // }
        // setTables(tables_);
    };

    const toggledClearSelectedRows = (val) => {
        //setClearSelectedRows(val);
    };


    useEffect(()=>{
        const filteredTables = tables.data && tables.data.filter(
            item => {
                return (
                    item
                        .nom
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item
                        .adresse
                        .toLowerCase()
                        .includes(searchField.toLowerCase()) ||
                    item
                        .commentaire
                        .toLowerCase()
                        .includes(searchField.toLowerCase())
                );
            }
        );
        if(searchField == '')
            getUsagers().catch(console.error);
        setTables(filteredTables);
    },[searchField]);

    function rechercheindb(){
        if(searchField != ''){
            return;
        }
        return;

    }

    function newVehicle(e) {
        e.preventDefault();
        if(!selectedRowss || selectedRowss.length === 0){
            setOpenToast({
                isOpenToast: true,
                txt: 'Veuillez choisir un usager.'
            });
            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
            return;
        }
        if(immatriculation.trim() == ''){
            setOpenToast({
                isOpenToast: true,
                txt: 'Veuillez ajouter l\'immatriculation.'
            });
            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
            return;
        }
        if(marque.trim() == ''){
            setOpenToast({
                isOpenToast: true,
                txt: 'Veuillez choisir ou ajouter la marque du véhicule.'
            });
            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
            return;
        }
        if(type.trim() == ''){
            setOpenToast({
                isOpenToast: true,
                txt: 'Veuillez choisir ou ajouter le type de véhicule.'
            });
            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
            return;
        }
        if(commentVehicle.trim() == ''){
            setOpenToast({
                isOpenToast: true,
                txt: 'Veuillez ajouter un commentaire.'
            });
            setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
            return;
        }
        let body = {
            immatriculation:  immatriculation.trim(),
            marque:  marque.trim(),
            type:  type.trim(),
            commentVehicle:  commentVehicle.trim(),
            usagers: selectedRowss
        };
        if(MarqueOnChange != null && MarqueOnChange.label === marque)
            body.marqueid = MarqueOnChange.id;

        if(TypeOnChange != null && TypeOnChange.label === type)
            body.typeid = TypeOnChange.id;


        helpers
            .saveVehicleUsagers(body)
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setOpenToast({
                        isOpenToast: true,
                        txt: 'Enregistrement effectué avec succès.'
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                }
            })
    }

    const getUsagers = async () => {
        helpers
            .getAllUsagers()
            .then(async r => {
                console.log('resp', r);
                setLoading(false);
                if (r.status === 200) {
                    setTables( r.data);
                }
            })
    };

    const getType = async () => {
        helpers
            .getTypes()
            .then(async r => {
                console.log('resp type', r);
                setLoading(false);
                if (r.status === 200) {
                    setTypes( r.data);
                }
            })
    };

    const getMarque = async () => {
        helpers
            .getMarques()
            .then(async r => {
                setLoading(false);
                if (r.status === 200) {
                    setMarques( r.data);
                }
            })
    };

    useEffect(()=>{
        setLoading(true);
        getUsagers().catch(console.error);
        getType().catch(console.error);
        getMarque().catch(console.error);
    },[]);


    const savenewGroupe =(e)=> {
        const data = new FormData(e.currentTarget);
        e.preventDefault();
        const nom = data.get('nom');
        const adresse = data.get('adresse');
        const commentaire = data.get('commentaire');

        if(nom == ''){
            setOpenSnack({...openSnack, isOpenNomError:true,txt:'Veuillez insérer le nom du groupe'});
            return;
        }
        setOpenSnack({...openSnack, isOpenNomError:false});

        setLoadingSave(true);
        let dataX = {
            nom: nom,
            adresse: adresse,
            commentaire: commentaire
        };

        helpers
            .groupesave(dataX)
            .then(async r => {
                setLoadingSave(false);
                if (r.status === 200) {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setshowmodal(false);

                } else {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                }
            })
            .catch(e => {
                setLoadingSave(false);
            });
    };

    const modification =(e)=> {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const nom = data.get('nom');
        const adresse = data.get('adresse');
        const commentaire = data.get('commentaire');

        if(nom == ''){
            setOpenSnack({...openSnack, isOpenNomError:true,txt:'Le nom du groupe ne pourrait être pas vide.'});
            return;
        }
        setOpenSnack({...openSnack, isOpenNomError:false});

        setLoadingSave(true);
        let dataX = {
            nom: nom,
            adresse: adresse,
            commentaire: commentaire
        };

        helpers
            .groupemodification(dataX)
            .then(async r => {
                setLoadingSave(false);
                if (r.status === 200) {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                    setshowmodal(false);

                } else {
                    setOpenToast({
                        isOpenToast: true,
                        txt: r.message,
                    });
                    setTimeout(()=>{ setOpenToast({ isOpenToast: false  }); },6000);
                }
            })
            .catch(e => {
                setLoadingSave(false);
            });
    };
    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };


    return (
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Chargement...'
        >
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Ajout Vehicule & Ajout Usagers</h1>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        Groupe
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <Link to="/dashboard"  className="link">
                                        Parc access
                                        </Link>
                                    </li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>

                {/*le datatable*/}
                <div className="card">
                    <div className="card-body">

                        <div className="row">
                            <div className="col">
                                <Box component="form" onSubmit={savenewGroupe} sx={{ mt: 1 }}
                                     Validate
                                     autoComplete="on"   >
                                <div className="">
                                    <h4 className="m-0 text-secondary">Vehicule</h4>
                                </div>
                                <div className="row">
                                    <TextField
                                      //  size={'small'}
                                      //   inputProps={{
                                      //       style: {
                                      //           height: "600px",
                                      //       },
                                      //   }}
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="immatriculation"
                                        label="Immatriculation"
                                        name="immatriculation"
                                      //  autoComplete="nom"
                                        autoFocus
                                        onChange={(value)=>{setImmatriculation(value.target.value); }}
                                    />
                                </div>
                                <div style={{ justifyContent:'flex-end', marginTop:5}} className="row">
                                    {/*<Autocomplete*/}
                                    {/*    label={'Location'}*/}
                                    {/*    margin={'noraml'}*/}
                                    {/*    multiple={false}*/}
                                    {/*    name={'location'}*/}
                                    {/*    getOptionSelected={useCallback((option, value) => option.value === value.value)} // added*/}
                                    {/*    value={{label:marque}}*/}
                                    {/*    options={*/}
                                    {/*        marques.length > 0 ?*/}
                                    {/*            marques*/}
                                    {/*            :*/}
                                    {/*            [*/}
                                    {/*                { label: 'vide', id: 1994 },*/}
                                    {/*            ]*/}
                                    {/*    }*/}
                                    {/*    onChange={useCallback((e, v) => {setMarque(e.target.value); console.log('value',v)})}*/}
                                    {/*/>*/}
                                    <Autocomplete
                                        margin="normal"
                                        //disablePortal
                                        id="combo-box-demoa"
                                        //inputValue={marque}
                                        value={{label:marque}}
                                        //clearOnBlur={false}
                                        options={
                                        //     isOptionEqualToValue ={
                                            marques.length > 0 ?
                                                marques
                                                :
                                            [
                                           { label: 'vide', id: 1994 },
                                            ]
                                        }
                                       //autoComplete={false}
                                       //  autoSelect={true}
                                       // getOptionLabel={(option) => option.label || ""}

                                        // ity matonga az
                                        // onChange={(value, va)=>{console.log('value',value.target.value); console.log('value1', va) }}
                                        onChange={(value0, value1)=>{setMarque(value1.label); setMarqueOnChange(value1)}}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} key={params?.id}  value={marque} onChange={(value)=>{setMarque(value.target.value); }} label="Marque" />}
                                    />
                                </div>
                                <div style={{ justifyContent:'flex-end', marginTop:10}} className="row">
                                    <Autocomplete
                                        margin="normal"
                                        disablePortal
                                        id="combo-box-demo1"
                                        value={{label:type}}
                                        options={
                                            types.length > 0 ?
                                                types
                                                :
                                                [
                                                    //  { label: 'vide', id: 1994 },
                                                ]
                                        }
                                        onChange={(value0, value1)=>{setType(value1.label); setTypeOnChange(value1)}}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} value={type} onChange={(value)=>{setType(value.target.value); }} label="Type" />}
                                    />
                                </div>
                                <div className="row">
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="commentaire"
                                        label="Commentaire"
                                        name="commentaire"
                                        //  autoComplete="nom"
                                        multiline={true}
                                        rows={3}
                                        autoFocus
                                        onChange={(value)=>{setCommentVehicle(value.target.value); }}
                                    />
                                </div>
                                <div  style={{marginTop:15}}  className="row">
                                <button
                                    onClick={(e)=>newVehicle(e)}
                                    className={"btn btn-success px-4"}><i className="fas fa-plus-circle mr-2" />Enregistrer Vehicule</button>
                                </div>
                                </Box>
                            </div>
                            <div className="col">
                                <div >
                                    <h4  className="text-secondary text-right">Usagers</h4>
                                </div>
                                <div style={{ justifyContent:'flex-end', }} className=" d-flex flex-row ">
                                        <div className="row d-flex flex-row align-items-center justify-content-between">
                                        <FormControl>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                value={switchUsagers}
                                                onChange={handleSwitchUsagers}
                                            >
                                                <FormControlLabel value="Ajouter" control={<Radio />} label="Ajouter" />
                                                <FormControlLabel value="Afficher" control={<Radio />} label="Afficher" />
                                            </RadioGroup>
                                        </FormControl>
                                        <Paper
                                            component="form"
                                            sx={{ p: '2px 2px', display: 'flex', alignItems: 'center', width: 200 }}
                                        >
                                            <InputBase
                                                sx={{ ml: 1, flex: 1 }}
                                                placeholder="Rechercher"
                                                inputProps={{ 'aria-label': 'Rechercher' }}
                                                onChange={(e)=>{
                                                    e.preventDefault();
                                                    setSearchField(e.target.value);
                                                }}
                                                value={searchField}
                                            />
                                            <IconButton  onClick={() => {
                                                rechercheindb()
                                            }} type="button" sx={{ p: '5px' }} aria-label="search">
                                                <SearchIcon />
                                            </IconButton>
                                        </Paper>
                                    </div>
                                </div>
                                {switchUsagers === 'Afficher' && <DataTable
                                    pagination
                                    // selectableRowsComponent={Checkbox}
                                    selectableRowsComponentProps={selectProps}
                                    // selectableRowSelected={(row) => {
                                    //     console.log('row',row)
                                    //      return row.selected;
                                    // }}

                                    // sortIcon={sortIcon}
                                    dense
                                    //{...props}
                                    //title="Administration"
                                    // theme={'dark'}
                                    columns={columns}
                                    data={tables}
                                    selectableRows
                                    striped
                                    highlightOnHover
                                    persistTableHead
                                    fixedHeader={false}
                                    progressPending={false}
                                    noDataComponent={"Aucune données à afficher"}
                                    onRowClicked={(row, event) => {console.log('row clicked', row)}}
                                    onSelectedRowsChange={handleChange}
                                    // onSelectedRowsChange={handleSelectChange}
                                    // clearSelectedRows={toggledClearSelectedRows}
                                    // selectableRowSelected={(rowSelectCritera)=>{
                                    //     console.log('rowSelectCritera',rowSelectCritera);
                                    //     return rowSelectCritera.selected === true;
                                    // }}

                                    // onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {console.log(' onChangePage')}}
                                    // onChangePage={(page, totalRows)  => {console.log(' onChangePage')}}
                                    // onRowMouseEnter={(row, event) => {console.log('row clicked')}}
                                    // expandableRows={false}
                                    // expandableRowsComponent={() => {console.log('row clicked')}}
                                    paginationComponentOptions={{ rowsPerPageText: 'Lignes par page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                                />}
                                {switchUsagers === 'Ajouter' && <AjouterUsagers setSwitchUsagers={handleSwitchUsagers} getUsagers={getUsagers} setOpenToast={setByChildOpenToast} setParentLoading={setByChildLoading}/>}
                            </div>
                        </div>

                    </div>
                    {/*fin body*/}
                </div>

                {/*ts miasa aloha hatreto*/}
                <Modal
                    open={isshowmodal}
                    onClose={()=>setshowmodal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className="modal-dialog ">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Nouveau admin</h4>
                                <button onClick={()=>setshowmodal(false)} type="button" className="close" data-dismiss="modal" aria-label="Fermer">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <Box component="form" onSubmit={savenewGroupe} sx={{ mt: 1 }}
                                 Validate
                                 autoComplete="on"   >

                                <div   className="modal-body">

                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="nom"
                                        label="Nom"
                                        name="nom"
                                        autoComplete="nom"
                                        autoFocus
                                    />
                                    <Collapse in={openSnack.isOpenNomError}>
                                        <Alert severity="error" action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setOpenSnack({...openSnack, isOpenNomError:false});
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }>{openSnack.txt}</Alert>
                                    </Collapse>


                                    <TextField
                                        margin="normal"
                                        //    required
                                        fullWidth
                                        id="adresse"
                                        label="Adresse"
                                        name="adresse"
                                        autoComplete="adresse"
                                        autoFocus
                                    />

                                    <TextField
                                        margin="normal"
                                        //    required
                                        fullWidth
                                        id="commentaire"
                                        label="Commentaire"
                                        name="commentaire"
                                        autoComplete="commentaire"
                                        autoFocus
                                    />


                                </div>
                                <div className="modal-footer d-flex flex-row justify-content-between">
                                    <button onClick={()=>setshowmodal(false)} type="button" className="btn btn-default" data-dismiss="modal">Fermer</button>
                                    <Button
                                        onPress = {savenewGroupe}
                                        type="submit"
                                        // fullWidth
                                        variant="contained"
                                        // sx={{ mt: 3, mb: 2 }}
                                        startIcon={
                                            isLoadingSave ? <img width={15} src={require("../../assets/loading-load.gif").default} alt="wait..." />
                                                : <SaveIcon fontSize="inherit" />
                                        }
                                    >
                                        Enregistrer
                                    </Button>
                                </div>
                            </Box>
                        </div>
                    </div>


                </Modal>
                {/*<Button*/}
                {/*    onClick = {()=>console.log('selectedRows',selectedRowss)}*/}
                {/*    type="button"*/}
                {/*    // fullWidth*/}
                {/*    variant="contained"*/}
                {/*    // sx={{ mt: 3, mb: 2 }}*/}
                {/*>*/}
                {/*    testtttttttttttttttttttt*/}
                {/*</Button>*/}
                <Snackbar
                    open={openToast.isOpenToast}
                    autoHideDuration={6000}
                    //onClose={()=>setOpenToast({...openToast, isOpenToast:false})}
                    message={openToast.txt}
                    action={<React.Fragment>
                        <IconButton
                            size="small"
                            aria-label="fermer"
                            color="inherit"
                            onClick={(event, reason) => {
                                if (reason === 'clickaway') {
                                    return;
                                }
                                setOpenToast(false);
                            }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>}
                />
            </div>
        </LoadingOverlay>
    )
};
