import { baseUrl } from "../constantes";
import ls from "local-storage";
//import { useSelector } from "react-redux";

export const helpers = {
  login: async data => {
    const response = await (
      await fetch(baseUrl + "/admins/login", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
    ).json();
    return response;
  },
    recuperermdp: async data => {
        const response = await (
            await fetch(baseUrl + "/admins/recuperermdp", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    adminsave: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/admins/save", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    sendUsagerByFiles: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/usagers/savebyfiles", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    modifyVehicleListeBlanche: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/vehicule/listeblanche", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    supprGroupe: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/groupe/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    supprSite: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/site/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    supprVehicule: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/vehicule/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    supprUsager: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/usager/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    supprPlageHoraire: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/plagehoraire/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    adminupdate: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/admins/update", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    adminupdateWithoutPassword: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/admins/updateWithoutPassword", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    supprAdmin: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/admins/delete", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    groupesave: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/groupe/save", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    groupemodification: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/groupe/modification", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    rechercherpardate: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/entrersortie/rechercherpardate", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    getEntrerSortie: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/entrersortie/getbyfile", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    getEntrersortiedb: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/entrersortie/get", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    excel: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/entrersortie/excel", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    getadmins: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/admins/get", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    getAllGroupes: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/groupes/get", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    getAllGroupesToDropdown: async () => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/groupes/gettodropdown", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    getAllPlagehorairesToDropdown: async () => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/plagehoraire/gettodropdown", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    getAllSitesToDropdown: async () => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/site/gettodropdown", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    ajouterusager: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/usagers/save", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    saveVehicleUsagers: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/vehicleusager/save", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    saveVehicle: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/vehicle/save", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    getAllUsagers: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/usagers/get", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },

    getMarques: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/marques/get", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    getTypes: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/types/get", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    getAllVehicules: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/vehicules/get", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    getAllSites: async (data) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/site/getall", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    getAllSsoussites: async (idsite) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/soussite/getall/" + idsite, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    getAllSitesParGroupe: async (id) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/sitepargroupe/getall/"+id, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    sitesave: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/site/save", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    soussitesave: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/soussite/save", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    // sitemodification
    modificationsite: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/site/edit", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    modificationsoussite: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/soussite/edit", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    getVehiculesParSite: async (id) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/vehiculeparsite/getall/"+id, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },
    vehiculemodification: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/vehicule/edit", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    usagermodification: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/usager/edit", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    ajouterplagehoraire: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/plagehoraire/save", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    modifierplagehoraire: async data => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/plagehoraire/edit", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    getPlagehoraire: async (id) => {
        const userToken_ = await await ls.get('userToken');
        const response = await (
            await fetch(baseUrl + "/plagehoraire/getall", {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userToken_
                },
            })
        ).json();
        return response;
    },




    /////////////////////////////////////////////////////////
    contacteradmin: async (data, token) => {
        const response = await (
            await fetch(baseUrl + "/user/contacteradmin", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
    updateProfil: async (data, token) => {
        const response = await (
            await fetch(baseUrl + "/user/updateProfil", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                },
                body: JSON.stringify(data)
            })
        ).json();
        return response;
    },
  verifcompte: async (data, token) => {
    const response = await (
      await fetch(baseUrl + "/user/verifcompte", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        },
        body: JSON.stringify(data)
      })
    ).json();
    return response;
  },

};
